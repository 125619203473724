// Generated by purs version 0.14.5
"use strict";
var focusout = "focusout";
var focusin = "focusin";
var focus = "focus";
var blur = "blur";
module.exports = {
    blur: blur,
    focus: focus,
    focusin: focusin,
    focusout: focusout
};
