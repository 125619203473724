// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Record_Unsafe = require("../Record.Unsafe/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var showString = {
    show: $foreign.showStringImpl
};
var showRecordFieldsNil = {
    showRecordFields: function (v) {
        return function (v1) {
            return [  ];
        };
    }
};
var showRecordFields = function (dict) {
    return dict.showRecordFields;
};
var showRecord = function (dictRowToList) {
    return function (dictShowRecordFields) {
        return {
            show: function (record) {
                var v = showRecordFields(dictShowRecordFields)(Type_Proxy["Proxy"].value)(record);
                if (v.length === 0) {
                    return "{}";
                };
                return $foreign.join(" ")([ "{", $foreign.join(", ")(v), "}" ]);
            }
        };
    };
};
var showProxy3 = {
    show: function (v) {
        return "Proxy3";
    }
};
var showProxy2 = {
    show: function (v) {
        return "Proxy2";
    }
};
var showProxy = {
    show: function (v) {
        return "Proxy";
    }
};
var showNumber = {
    show: $foreign.showNumberImpl
};
var showInt = {
    show: $foreign.showIntImpl
};
var showChar = {
    show: $foreign.showCharImpl
};
var showBoolean = {
    show: function (v) {
        if (v) {
            return "true";
        };
        if (!v) {
            return "false";
        };
        throw new Error("Failed pattern match at Data.Show (line 20, column 1 - line 22, column 23): " + [ v.constructor.name ]);
    }
};
var show = function (dict) {
    return dict.show;
};
var showArray = function (dictShow) {
    return {
        show: $foreign.showArrayImpl(show(dictShow))
    };
};
var showRecordFieldsCons = function (dictIsSymbol) {
    return function (dictShowRecordFields) {
        return function (dictShow) {
            return {
                showRecordFields: function (v) {
                    return function (record) {
                        var tail = showRecordFields(dictShowRecordFields)(Type_Proxy["Proxy"].value)(record);
                        var key = Data_Symbol.reflectSymbol(dictIsSymbol)(Type_Proxy["Proxy"].value);
                        var focus = Record_Unsafe.unsafeGet(key)(record);
                        return $foreign.cons($foreign.join(": ")([ key, show(dictShow)(focus) ]))(tail);
                    };
                }
            };
        };
    };
};
module.exports = {
    show: show,
    showRecordFields: showRecordFields,
    showBoolean: showBoolean,
    showInt: showInt,
    showNumber: showNumber,
    showChar: showChar,
    showString: showString,
    showArray: showArray,
    showProxy: showProxy,
    showProxy2: showProxy2,
    showProxy3: showProxy3,
    showRecord: showRecord,
    showRecordFieldsNil: showRecordFieldsNil,
    showRecordFieldsCons: showRecordFieldsCons
};
