// Generated by purs version 0.14.5
"use strict";
var Control_Category = require("../Control.Category/index.js");
var Control_Monad_Cont_Trans = require("../Control.Monad.Cont.Trans/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Control_Monad_List_Trans = require("../Control.Monad.List.Trans/index.js");
var Control_Monad_Maybe_Trans = require("../Control.Monad.Maybe.Trans/index.js");
var Control_Monad_RWS_Trans = require("../Control.Monad.RWS.Trans/index.js");
var Control_Monad_Reader_Trans = require("../Control.Monad.Reader.Trans/index.js");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans/index.js");
var Control_Monad_Trans_Class = require("../Control.Monad.Trans.Class/index.js");
var Control_Monad_Writer_Trans = require("../Control.Monad.Writer.Trans/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var monadAffAff = {
    liftAff: Control_Category.identity(Control_Category.categoryFn),
    MonadEffect0: function () {
        return Effect_Aff.monadEffectAff;
    }
};
var liftAff = function (dict) {
    return dict.liftAff;
};
var monadAffContT = function (dictMonadAff) {
    return {
        liftAff: (function () {
            var $11 = Control_Monad_Trans_Class.lift(Control_Monad_Cont_Trans.monadTransContT)((dictMonadAff.MonadEffect0()).Monad0());
            var $12 = liftAff(dictMonadAff);
            return function ($13) {
                return $11($12($13));
            };
        })(),
        MonadEffect0: function () {
            return Control_Monad_Cont_Trans.monadEffectContT(dictMonadAff.MonadEffect0());
        }
    };
};
var monadAffExceptT = function (dictMonadAff) {
    return {
        liftAff: (function () {
            var $14 = Control_Monad_Trans_Class.lift(Control_Monad_Except_Trans.monadTransExceptT)((dictMonadAff.MonadEffect0()).Monad0());
            var $15 = liftAff(dictMonadAff);
            return function ($16) {
                return $14($15($16));
            };
        })(),
        MonadEffect0: function () {
            return Control_Monad_Except_Trans.monadEffectExceptT(dictMonadAff.MonadEffect0());
        }
    };
};
var monadAffListT = function (dictMonadAff) {
    return {
        liftAff: (function () {
            var $17 = Control_Monad_Trans_Class.lift(Control_Monad_List_Trans.monadTransListT)((dictMonadAff.MonadEffect0()).Monad0());
            var $18 = liftAff(dictMonadAff);
            return function ($19) {
                return $17($18($19));
            };
        })(),
        MonadEffect0: function () {
            return Control_Monad_List_Trans.monadEffectListT(dictMonadAff.MonadEffect0());
        }
    };
};
var monadAffMaybe = function (dictMonadAff) {
    return {
        liftAff: (function () {
            var $20 = Control_Monad_Trans_Class.lift(Control_Monad_Maybe_Trans.monadTransMaybeT)((dictMonadAff.MonadEffect0()).Monad0());
            var $21 = liftAff(dictMonadAff);
            return function ($22) {
                return $20($21($22));
            };
        })(),
        MonadEffect0: function () {
            return Control_Monad_Maybe_Trans.monadEffectMaybe(dictMonadAff.MonadEffect0());
        }
    };
};
var monadAffRWS = function (dictMonadAff) {
    return function (dictMonoid) {
        return {
            liftAff: (function () {
                var $23 = Control_Monad_Trans_Class.lift(Control_Monad_RWS_Trans.monadTransRWST(dictMonoid))((dictMonadAff.MonadEffect0()).Monad0());
                var $24 = liftAff(dictMonadAff);
                return function ($25) {
                    return $23($24($25));
                };
            })(),
            MonadEffect0: function () {
                return Control_Monad_RWS_Trans.monadEffectRWS(dictMonoid)(dictMonadAff.MonadEffect0());
            }
        };
    };
};
var monadAffReader = function (dictMonadAff) {
    return {
        liftAff: (function () {
            var $26 = Control_Monad_Trans_Class.lift(Control_Monad_Reader_Trans.monadTransReaderT)((dictMonadAff.MonadEffect0()).Monad0());
            var $27 = liftAff(dictMonadAff);
            return function ($28) {
                return $26($27($28));
            };
        })(),
        MonadEffect0: function () {
            return Control_Monad_Reader_Trans.monadEffectReader(dictMonadAff.MonadEffect0());
        }
    };
};
var monadAffState = function (dictMonadAff) {
    return {
        liftAff: (function () {
            var $29 = Control_Monad_Trans_Class.lift(Control_Monad_State_Trans.monadTransStateT)((dictMonadAff.MonadEffect0()).Monad0());
            var $30 = liftAff(dictMonadAff);
            return function ($31) {
                return $29($30($31));
            };
        })(),
        MonadEffect0: function () {
            return Control_Monad_State_Trans.monadEffectState(dictMonadAff.MonadEffect0());
        }
    };
};
var monadAffWriter = function (dictMonadAff) {
    return function (dictMonoid) {
        return {
            liftAff: (function () {
                var $32 = Control_Monad_Trans_Class.lift(Control_Monad_Writer_Trans.monadTransWriterT(dictMonoid))((dictMonadAff.MonadEffect0()).Monad0());
                var $33 = liftAff(dictMonadAff);
                return function ($34) {
                    return $32($33($34));
                };
            })(),
            MonadEffect0: function () {
                return Control_Monad_Writer_Trans.monadEffectWriter(dictMonoid)(dictMonadAff.MonadEffect0());
            }
        };
    };
};
module.exports = {
    liftAff: liftAff,
    monadAffAff: monadAffAff,
    monadAffContT: monadAffContT,
    monadAffExceptT: monadAffExceptT,
    monadAffListT: monadAffListT,
    monadAffMaybe: monadAffMaybe,
    monadAffReader: monadAffReader,
    monadAffRWS: monadAffRWS,
    monadAffState: monadAffState,
    monadAffWriter: monadAffWriter
};
