// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_List = require("../Data.List/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Free = function (x) {
    return x;
};
var showFree = function (dictShow) {
    return {
        show: function (v) {
            return "(Free " + (Data_Show.show(Data_List_Types.showList(Data_List_Types.showList(dictShow)))(v) + ")");
        }
    };
};
var semiringFree = {
    add: function (v) {
        return function (v1) {
            return Data_Semigroup.append(Data_List_Types.semigroupList)(v)(v1);
        };
    },
    zero: Data_List_Types.Nil.value,
    mul: function (v) {
        return function (v1) {
            return Control_Bind.bind(Data_List_Types.bindList)(v)(function (xs) {
                return Control_Bind.bind(Data_List_Types.bindList)(v1)(function (ys) {
                    return Control_Applicative.pure(Data_List_Types.applicativeList)(Data_Semigroup.append(Data_List_Types.semigroupList)(xs)(ys));
                });
            });
        };
    },
    one: Data_List.singleton(Data_List_Types.Nil.value)
};
var newtypeFree = {
    Coercible0: function () {
        return undefined;
    }
};
var liftFree = function (dictSemiring) {
    return function (f) {
        return function (v) {
            return Data_Foldable.sum(Data_List_Types.foldableList)(dictSemiring)(Data_Functor.map(Data_List_Types.functorList)((function () {
                var $49 = Data_Foldable.product(Data_List_Types.foldableList)(dictSemiring);
                var $50 = Data_Functor.map(Data_List_Types.functorList)(f);
                return function ($51) {
                    return $49($50($51));
                };
            })())(v));
        };
    };
};
var functorFree = {
    map: function (fn) {
        return function (v) {
            return Free(Data_Functor.map(Data_List_Types.functorList)(Data_Functor.map(Data_List_Types.functorList)(fn))(v));
        };
    }
};
var free = function (a) {
    return Data_List.singleton(Data_List.singleton(a));
};
var lowerFree = function (dictSemiring) {
    return function (f) {
        return function (a) {
            return f(free(a));
        };
    };
};
var foldableFree = {
    foldl: function (fn) {
        return function (accum) {
            return function (v) {
                return Data_Foldable.foldl(Data_List_Types.foldableList)(Data_Foldable.foldl(Data_List_Types.foldableList)(fn))(accum)(v);
            };
        };
    },
    foldr: function (fn) {
        return function (accum) {
            return function (v) {
                return Data_Foldable.foldr(Data_List_Types.foldableList)(Data_Function.flip(Data_Foldable.foldr(Data_List_Types.foldableList)(fn)))(accum)(v);
            };
        };
    },
    foldMap: function (dictMonoid) {
        return function (fn) {
            return function (v) {
                return Data_Foldable.fold(Data_List_Types.foldableList)(dictMonoid)(Data_Foldable.foldMap(Data_List_Types.foldableList)(Data_List_Types.monoidList)(Data_Functor.map(Data_List_Types.functorList)(fn))(v));
            };
        };
    }
};
var traversableFree = {
    sequence: function (dictApplicative) {
        return function (v) {
            return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Free)(Data_Traversable.sequence(Data_List_Types.traversableList)(dictApplicative)(Data_Functor.map(Data_List_Types.functorList)(Data_Traversable.sequence(Data_List_Types.traversableList)(dictApplicative))(v)));
        };
    },
    traverse: function (dictApplicative) {
        return function (fn) {
            return function (freeA) {
                return Data_Traversable.sequence(traversableFree)(dictApplicative)(Data_Functor.map(functorFree)(fn)(freeA));
            };
        };
    },
    Functor0: function () {
        return functorFree;
    },
    Foldable1: function () {
        return foldableFree;
    }
};
var eqFree = function (dictEq) {
    return {
        eq: function (v) {
            return function (v1) {
                return Data_Eq.eq(Data_List_Types.eqList(Data_List_Types.eqList(dictEq)))(v)(v1);
            };
        }
    };
};
var ordFree = function (dictOrd) {
    return {
        compare: function (v) {
            return function (v1) {
                return Data_Ord.compare(Data_List_Types.ordList(Data_List_Types.ordList(dictOrd)))(v)(v1);
            };
        },
        Eq0: function () {
            return eqFree(dictOrd.Eq0());
        }
    };
};
var applyFree = {
    apply: function (v) {
        return function (v1) {
            return Free(Control_Bind.bind(Data_List_Types.bindList)(v)(function (fns) {
                return Control_Bind.bind(Data_List_Types.bindList)(v1)(function (xs) {
                    return Control_Applicative.pure(Data_List_Types.applicativeList)(Control_Apply.apply(Data_List_Types.applyList)(fns)(xs));
                });
            }));
        };
    },
    Functor0: function () {
        return functorFree;
    }
};
var applicativeFree = {
    pure: free,
    Apply0: function () {
        return applyFree;
    }
};
module.exports = {
    Free: Free,
    free: free,
    liftFree: liftFree,
    lowerFree: lowerFree,
    newtypeFree: newtypeFree,
    showFree: showFree,
    eqFree: eqFree,
    ordFree: ordFree,
    semiringFree: semiringFree,
    functorFree: functorFree,
    applyFree: applyFree,
    applicativeFree: applicativeFree,
    foldableFree: foldableFree,
    traversableFree: traversableFree
};
