// Generated by purs version 0.14.5
"use strict";
var paste = "paste";
var cut = "cut";
var copy = "copy";
var clipboardchange = "clipboardchange";
module.exports = {
    clipboardchange: clipboardchange,
    copy: copy,
    cut: cut,
    paste: paste
};
