// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Data_Bifunctor = require("../Data.Bifunctor/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Show = require("../Data.Show/index.js");
var V = function (x) {
    return x;
};
var validation = function (v) {
    return function (v1) {
        return function (v2) {
            if (v2 instanceof Data_Either.Left) {
                return v(v2.value0);
            };
            if (v2 instanceof Data_Either.Right) {
                return v1(v2.value0);
            };
            throw new Error("Failed pattern match at Data.Validation.Semiring (line 51, column 1 - line 51, column 84): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
        };
    };
};
var unV = function (dictWarn) {
    return validation;
};
var toEither = function (v) {
    return v;
};
var showV = function (dictShow) {
    return function (dictShow1) {
        return {
            show: function (v) {
                if (v instanceof Data_Either.Left) {
                    return "invalid (" + (Data_Show.show(dictShow)(v.value0) + ")");
                };
                if (v instanceof Data_Either.Right) {
                    return "pure (" + (Data_Show.show(dictShow1)(v.value0) + ")");
                };
                throw new Error("Failed pattern match at Data.Validation.Semiring (line 88, column 10 - line 90, column 55): " + [ v.constructor.name ]);
            }
        };
    };
};
var newtypeV = {
    Coercible0: function () {
        return undefined;
    }
};
var isValid = function (v) {
    if (v instanceof Data_Either.Right) {
        return true;
    };
    return false;
};
var invalid = function ($69) {
    return V(Data_Either.Left.create($69));
};
var functorV = Data_Either.functorEither;
var foldableV = {
    foldMap: function (dictMonoid) {
        return validation(Data_Function["const"](Data_Monoid.mempty(dictMonoid)));
    },
    foldr: function (f) {
        return function (b) {
            return validation(Data_Function["const"](b))(Data_Function.flip(f)(b));
        };
    },
    foldl: function (f) {
        return function (b) {
            return validation(Data_Function["const"](b))(f(b));
        };
    }
};
var traversableV = {
    sequence: function (dictApplicative) {
        return validation((function () {
            var $70 = Control_Applicative.pure(dictApplicative);
            return function ($71) {
                return $70(V(Data_Either.Left.create($71)));
            };
        })())(Data_Functor.map((dictApplicative.Apply0()).Functor0())(function ($72) {
            return V(Data_Either.Right.create($72));
        }));
    },
    traverse: function (dictApplicative) {
        return function (f) {
            return validation((function () {
                var $73 = Control_Applicative.pure(dictApplicative);
                return function ($74) {
                    return $73(V(Data_Either.Left.create($74)));
                };
            })())((function () {
                var $75 = Data_Functor.map((dictApplicative.Apply0()).Functor0())(function ($77) {
                    return V(Data_Either.Right.create($77));
                });
                return function ($76) {
                    return $75(f($76));
                };
            })());
        };
    },
    Functor0: function () {
        return functorV;
    },
    Foldable1: function () {
        return foldableV;
    }
};
var eqV = function (dictEq) {
    return function (dictEq1) {
        return {
            eq: function (x) {
                return function (y) {
                    return Data_Eq.eq(Data_Either.eqEither(dictEq)(dictEq1))(x)(y);
                };
            }
        };
    };
};
var ordV = function (dictOrd) {
    return function (dictOrd1) {
        return {
            compare: function (x) {
                return function (y) {
                    return Data_Ord.compare(Data_Either.ordEither(dictOrd)(dictOrd1))(x)(y);
                };
            },
            Eq0: function () {
                return eqV(dictOrd.Eq0())(dictOrd1.Eq0());
            }
        };
    };
};
var eq1V = function (dictEq) {
    return {
        eq1: function (dictEq1) {
            return Data_Eq.eq(eqV(dictEq)(dictEq1));
        }
    };
};
var ord1V = function (dictOrd) {
    return {
        compare1: function (dictOrd1) {
            return Data_Ord.compare(ordV(dictOrd)(dictOrd1));
        },
        Eq10: function () {
            return eq1V(dictOrd.Eq0());
        }
    };
};
var bifunctorV = Data_Bifunctor.bifunctorEither;
var applyV = function (dictSemiring) {
    return {
        apply: function (v) {
            return function (v1) {
                if (v instanceof Data_Either.Left && v1 instanceof Data_Either.Left) {
                    return new Data_Either.Left(Data_Semiring.mul(dictSemiring)(v.value0)(v1.value0));
                };
                if (v instanceof Data_Either.Left) {
                    return new Data_Either.Left(v.value0);
                };
                if (v1 instanceof Data_Either.Left) {
                    return new Data_Either.Left(v1.value0);
                };
                if (v instanceof Data_Either.Right && v1 instanceof Data_Either.Right) {
                    return new Data_Either.Right(v.value0(v1.value0));
                };
                throw new Error("Failed pattern match at Data.Validation.Semiring (line 96, column 1 - line 100, column 54): " + [ v.constructor.name, v1.constructor.name ]);
            };
        },
        Functor0: function () {
            return functorV;
        }
    };
};
var semigroupV = function (dictSemiring) {
    return function (dictSemigroup) {
        return {
            append: Control_Apply.lift2(applyV(dictSemiring))(Data_Semigroup.append(dictSemigroup))
        };
    };
};
var applicativeV = function (dictSemiring) {
    return {
        pure: function ($78) {
            return V(Data_Either.Right.create($78));
        },
        Apply0: function () {
            return applyV(dictSemiring);
        }
    };
};
var monoidV = function (dictSemiring) {
    return function (dictMonoid) {
        return {
            mempty: Control_Applicative.pure(applicativeV(dictSemiring))(Data_Monoid.mempty(dictMonoid)),
            Semigroup0: function () {
                return semigroupV(dictSemiring)(dictMonoid.Semigroup0());
            }
        };
    };
};
var andThen = function (v1) {
    return function (f) {
        return validation(invalid)(f)(v1);
    };
};
var altV = function (dictSemiring) {
    return {
        alt: function (v) {
            return function (v1) {
                if (v instanceof Data_Either.Left && v1 instanceof Data_Either.Left) {
                    return new Data_Either.Left(Data_Semiring.add(dictSemiring)(v.value0)(v1.value0));
                };
                if (v instanceof Data_Either.Left) {
                    return v1;
                };
                if (v instanceof Data_Either.Right) {
                    return new Data_Either.Right(v.value0);
                };
                throw new Error("Failed pattern match at Data.Validation.Semiring (line 111, column 1 - line 114, column 36): " + [ v.constructor.name, v1.constructor.name ]);
            };
        },
        Functor0: function () {
            return functorV;
        }
    };
};
var plusV = function (dictSemiring) {
    return {
        empty: new Data_Either.Left(Data_Semiring.zero(dictSemiring)),
        Alt0: function () {
            return altV(dictSemiring);
        }
    };
};
module.exports = {
    V: V,
    validation: validation,
    unV: unV,
    invalid: invalid,
    isValid: isValid,
    toEither: toEither,
    andThen: andThen,
    newtypeV: newtypeV,
    eqV: eqV,
    eq1V: eq1V,
    ordV: ordV,
    ord1V: ord1V,
    showV: showV,
    functorV: functorV,
    bifunctorV: bifunctorV,
    applyV: applyV,
    applicativeV: applicativeV,
    semigroupV: semigroupV,
    monoidV: monoidV,
    altV: altV,
    plusV: plusV,
    foldableV: foldableV,
    traversableV: traversableV
};
