// Generated by purs version 0.14.5
"use strict";
var Data_Argonaut_Core = require("../Data.Argonaut.Core/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_List = require("../Data.List/index.js");
var Data_List_NonEmpty = require("../Data.List.NonEmpty/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_String_NonEmpty_Internal = require("../Data.String.NonEmpty.Internal/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unfoldable = require("../Data.Unfoldable/index.js");
var Data_Void = require("../Data.Void/index.js");
var Foreign_Object = require("../Foreign.Object/index.js");
var extend = function (encoder) {
    return function (v) {
        var $32 = Data_Argonaut_Core.caseJsonObject(Data_Argonaut_Core.jsonSingletonObject(v.value0)(v.value1))((function () {
            var $34 = Foreign_Object.insert(v.value0)(v.value1);
            return function ($35) {
                return Data_Argonaut_Core.fromObject($34($35));
            };
        })());
        return function ($33) {
            return $32(encoder($33));
        };
    };
};
var extendOptional = function (encoder) {
    return function (v) {
        if (v instanceof Data_Maybe.Just) {
            return extend(encoder)(v.value0);
        };
        if (v instanceof Data_Maybe.Nothing) {
            return encoder;
        };
        throw new Error("Failed pattern match at Data.Argonaut.Encode.Encoders (line 121, column 26 - line 123, column 21): " + [ v.constructor.name ]);
    };
};
var encodeVoid = Data_Void.absurd;
var encodeUnit = Data_Function["const"](Data_Argonaut_Core.jsonNull);
var encodeTuple = function (encoderA) {
    return function (encoderB) {
        return function (v) {
            return Data_Argonaut_Core.fromArray([ encoderA(v.value0), encoderB(v.value1) ]);
        };
    };
};
var encodeString = Data_Argonaut_Core.fromString;
var encodeNumber = Data_Argonaut_Core.fromNumber;
var encodeNonEmptyString = function ($36) {
    return Data_Argonaut_Core.fromString(Data_String_NonEmpty_Internal.toString($36));
};
var encodeMaybe = function (encoder) {
    return function (v) {
        if (v instanceof Data_Maybe.Nothing) {
            return Data_Argonaut_Core.jsonNull;
        };
        if (v instanceof Data_Maybe.Just) {
            return encoder(v.value0);
        };
        throw new Error("Failed pattern match at Data.Argonaut.Encode.Encoders (line 31, column 23 - line 33, column 22): " + [ v.constructor.name ]);
    };
};
var encodeList = function (encoder) {
    var $37 = Data_Functor.map(Data_Functor.functorArray)(encoder);
    var $38 = Data_List.toUnfoldable(Data_Unfoldable.unfoldableArray);
    return function ($39) {
        return Data_Argonaut_Core.fromArray($37($38($39)));
    };
};
var encodeMap = function (dictOrd) {
    return function (encoderA) {
        return function (encoderB) {
            var $40 = encodeList(encodeTuple(encoderA)(encoderB));
            var $41 = Data_Map_Internal.toUnfoldable(Data_List_Types.unfoldableList);
            return function ($42) {
                return $40($41($42));
            };
        };
    };
};
var encodeNonEmptyList = function (encoder) {
    var $43 = encodeList(encoder);
    return function ($44) {
        return $43(Data_List_NonEmpty.toList($44));
    };
};
var encodeNonEmpty_List = function (encoder) {
    return function (v) {
        return encodeList(encoder)(new Data_List_Types.Cons(v.value0, v.value1));
    };
};
var encodeSet = function (dictOrd) {
    return function (encoder) {
        var $45 = encodeList(encoder);
        var $46 = Data_Set.toUnfoldable(Data_List_Types.unfoldableList);
        return function ($47) {
            return $45($46($47));
        };
    };
};
var encodeInt = function ($48) {
    return Data_Argonaut_Core.fromNumber(Data_Int.toNumber($48));
};
var encodeIdentity = function (encoder) {
    return function (v) {
        return encoder(v);
    };
};
var encodeForeignObject = function (encoder) {
    var $49 = Data_Functor.map(Foreign_Object.functorObject)(encoder);
    return function ($50) {
        return Data_Argonaut_Core.fromObject($49($50));
    };
};
var encodeEither = function (encoderA) {
    return function (encoderB) {
        var obj = function (encoder) {
            return function (tag) {
                return function (x) {
                    return Data_Argonaut_Core.fromObject(Foreign_Object.fromFoldable(Data_List_Types.foldableList)(new Data_List_Types.Cons(new Data_Tuple.Tuple("tag", Data_Argonaut_Core.fromString(tag)), new Data_List_Types.Cons(new Data_Tuple.Tuple("value", encoder(x)), Data_List_Types.Nil.value))));
                };
            };
        };
        return Data_Either.either(obj(encoderA)("Left"))(obj(encoderB)("Right"));
    };
};
var encodeCodePoint = function ($51) {
    return encodeString(Data_String_CodePoints.singleton($51));
};
var encodeChar = function ($52) {
    return encodeString(Data_String_CodeUnits.singleton($52));
};
var encodeBoolean = Data_Argonaut_Core.fromBoolean;
var encodeArray = function (encoder) {
    var $53 = Data_Functor.map(Data_Functor.functorArray)(encoder);
    return function ($54) {
        return Data_Argonaut_Core.fromArray($53($54));
    };
};
var encodeNonEmptyArray = function (encoder) {
    var $55 = encodeArray(encoder);
    return function ($56) {
        return $55(Data_Array_NonEmpty.toArray($56));
    };
};
var encodeNonEmpty_Array = function (encoder) {
    return function (v) {
        return encodeArray(encoder)(Data_Array.cons(v.value0)(v.value1));
    };
};
var assocOptional = function (encoder) {
    return function (k) {
        return Data_Functor.map(Data_Maybe.functorMaybe)((function () {
            var $57 = Data_Tuple.Tuple.create(k);
            return function ($58) {
                return $57(encoder($58));
            };
        })());
    };
};
var assoc = function (encoder) {
    return function (k) {
        var $59 = Data_Tuple.Tuple.create(k);
        return function ($60) {
            return $59(encoder($60));
        };
    };
};
module.exports = {
    encodeIdentity: encodeIdentity,
    encodeMaybe: encodeMaybe,
    encodeTuple: encodeTuple,
    encodeEither: encodeEither,
    encodeUnit: encodeUnit,
    encodeBoolean: encodeBoolean,
    encodeNumber: encodeNumber,
    encodeInt: encodeInt,
    encodeString: encodeString,
    encodeCodePoint: encodeCodePoint,
    encodeNonEmptyString: encodeNonEmptyString,
    encodeNonEmpty_Array: encodeNonEmpty_Array,
    encodeNonEmptyArray: encodeNonEmptyArray,
    encodeNonEmpty_List: encodeNonEmpty_List,
    encodeNonEmptyList: encodeNonEmptyList,
    encodeChar: encodeChar,
    encodeArray: encodeArray,
    encodeList: encodeList,
    encodeForeignObject: encodeForeignObject,
    encodeSet: encodeSet,
    encodeMap: encodeMap,
    encodeVoid: encodeVoid,
    assoc: assoc,
    assocOptional: assocOptional,
    extend: extend,
    extendOptional: extendOptional
};
