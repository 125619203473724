// Generated by purs version 0.14.5
"use strict";
var Data_Enum = require("../Data.Enum/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Slot1 = (function () {
    function Slot1() {

    };
    Slot1.value = new Slot1();
    return Slot1;
})();
var Slot2 = (function () {
    function Slot2() {

    };
    Slot2.value = new Slot2();
    return Slot2;
})();
var Slot3 = (function () {
    function Slot3() {

    };
    Slot3.value = new Slot3();
    return Slot3;
})();
var Slot4 = (function () {
    function Slot4() {

    };
    Slot4.value = new Slot4();
    return Slot4;
})();
var Slot5 = (function () {
    function Slot5() {

    };
    Slot5.value = new Slot5();
    return Slot5;
})();
var Slot6 = (function () {
    function Slot6() {

    };
    Slot6.value = new Slot6();
    return Slot6;
})();
var Slot7 = (function () {
    function Slot7() {

    };
    Slot7.value = new Slot7();
    return Slot7;
})();
var Slot8 = (function () {
    function Slot8() {

    };
    Slot8.value = new Slot8();
    return Slot8;
})();
var Slot9 = (function () {
    function Slot9() {

    };
    Slot9.value = new Slot9();
    return Slot9;
})();
var Slot10 = (function () {
    function Slot10() {

    };
    Slot10.value = new Slot10();
    return Slot10;
})();
var Slot11 = (function () {
    function Slot11() {

    };
    Slot11.value = new Slot11();
    return Slot11;
})();
var Slot12 = (function () {
    function Slot12() {

    };
    Slot12.value = new Slot12();
    return Slot12;
})();
var Slot13 = (function () {
    function Slot13() {

    };
    Slot13.value = new Slot13();
    return Slot13;
})();
var Slot14 = (function () {
    function Slot14() {

    };
    Slot14.value = new Slot14();
    return Slot14;
})();
var Slot15 = (function () {
    function Slot15() {

    };
    Slot15.value = new Slot15();
    return Slot15;
})();
var Slot16 = (function () {
    function Slot16() {

    };
    Slot16.value = new Slot16();
    return Slot16;
})();
var Slot17 = (function () {
    function Slot17() {

    };
    Slot17.value = new Slot17();
    return Slot17;
})();
var Slot18 = (function () {
    function Slot18() {

    };
    Slot18.value = new Slot18();
    return Slot18;
})();
var Slot19 = (function () {
    function Slot19() {

    };
    Slot19.value = new Slot19();
    return Slot19;
})();
var Slot20 = (function () {
    function Slot20() {

    };
    Slot20.value = new Slot20();
    return Slot20;
})();
var Slot21 = (function () {
    function Slot21() {

    };
    Slot21.value = new Slot21();
    return Slot21;
})();
var Slot22 = (function () {
    function Slot22() {

    };
    Slot22.value = new Slot22();
    return Slot22;
})();
var Slot23 = (function () {
    function Slot23() {

    };
    Slot23.value = new Slot23();
    return Slot23;
})();
var Slot24 = (function () {
    function Slot24() {

    };
    Slot24.value = new Slot24();
    return Slot24;
})();
var Slot25 = (function () {
    function Slot25() {

    };
    Slot25.value = new Slot25();
    return Slot25;
})();
var Slot26 = (function () {
    function Slot26() {

    };
    Slot26.value = new Slot26();
    return Slot26;
})();
var Slot27 = (function () {
    function Slot27() {

    };
    Slot27.value = new Slot27();
    return Slot27;
})();
var Slot28 = (function () {
    function Slot28() {

    };
    Slot28.value = new Slot28();
    return Slot28;
})();
var eqInventorySlot = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Slot1 && y instanceof Slot1) {
                return true;
            };
            if (x instanceof Slot2 && y instanceof Slot2) {
                return true;
            };
            if (x instanceof Slot3 && y instanceof Slot3) {
                return true;
            };
            if (x instanceof Slot4 && y instanceof Slot4) {
                return true;
            };
            if (x instanceof Slot5 && y instanceof Slot5) {
                return true;
            };
            if (x instanceof Slot6 && y instanceof Slot6) {
                return true;
            };
            if (x instanceof Slot7 && y instanceof Slot7) {
                return true;
            };
            if (x instanceof Slot8 && y instanceof Slot8) {
                return true;
            };
            if (x instanceof Slot9 && y instanceof Slot9) {
                return true;
            };
            if (x instanceof Slot10 && y instanceof Slot10) {
                return true;
            };
            if (x instanceof Slot11 && y instanceof Slot11) {
                return true;
            };
            if (x instanceof Slot12 && y instanceof Slot12) {
                return true;
            };
            if (x instanceof Slot13 && y instanceof Slot13) {
                return true;
            };
            if (x instanceof Slot14 && y instanceof Slot14) {
                return true;
            };
            if (x instanceof Slot15 && y instanceof Slot15) {
                return true;
            };
            if (x instanceof Slot16 && y instanceof Slot16) {
                return true;
            };
            if (x instanceof Slot17 && y instanceof Slot17) {
                return true;
            };
            if (x instanceof Slot18 && y instanceof Slot18) {
                return true;
            };
            if (x instanceof Slot19 && y instanceof Slot19) {
                return true;
            };
            if (x instanceof Slot20 && y instanceof Slot20) {
                return true;
            };
            if (x instanceof Slot21 && y instanceof Slot21) {
                return true;
            };
            if (x instanceof Slot22 && y instanceof Slot22) {
                return true;
            };
            if (x instanceof Slot23 && y instanceof Slot23) {
                return true;
            };
            if (x instanceof Slot24 && y instanceof Slot24) {
                return true;
            };
            if (x instanceof Slot25 && y instanceof Slot25) {
                return true;
            };
            if (x instanceof Slot26 && y instanceof Slot26) {
                return true;
            };
            if (x instanceof Slot27 && y instanceof Slot27) {
                return true;
            };
            if (x instanceof Slot28 && y instanceof Slot28) {
                return true;
            };
            return false;
        };
    }
};
var ordInventorySlot = {
    compare: function (x) {
        return function (y) {
            if (x instanceof Slot1 && y instanceof Slot1) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Slot1) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Slot1) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Slot2 && y instanceof Slot2) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Slot2) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Slot2) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Slot3 && y instanceof Slot3) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Slot3) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Slot3) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Slot4 && y instanceof Slot4) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Slot4) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Slot4) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Slot5 && y instanceof Slot5) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Slot5) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Slot5) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Slot6 && y instanceof Slot6) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Slot6) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Slot6) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Slot7 && y instanceof Slot7) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Slot7) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Slot7) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Slot8 && y instanceof Slot8) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Slot8) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Slot8) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Slot9 && y instanceof Slot9) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Slot9) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Slot9) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Slot10 && y instanceof Slot10) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Slot10) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Slot10) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Slot11 && y instanceof Slot11) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Slot11) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Slot11) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Slot12 && y instanceof Slot12) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Slot12) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Slot12) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Slot13 && y instanceof Slot13) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Slot13) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Slot13) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Slot14 && y instanceof Slot14) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Slot14) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Slot14) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Slot15 && y instanceof Slot15) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Slot15) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Slot15) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Slot16 && y instanceof Slot16) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Slot16) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Slot16) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Slot17 && y instanceof Slot17) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Slot17) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Slot17) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Slot18 && y instanceof Slot18) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Slot18) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Slot18) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Slot19 && y instanceof Slot19) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Slot19) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Slot19) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Slot20 && y instanceof Slot20) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Slot20) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Slot20) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Slot21 && y instanceof Slot21) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Slot21) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Slot21) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Slot22 && y instanceof Slot22) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Slot22) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Slot22) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Slot23 && y instanceof Slot23) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Slot23) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Slot23) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Slot24 && y instanceof Slot24) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Slot24) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Slot24) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Slot25 && y instanceof Slot25) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Slot25) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Slot25) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Slot26 && y instanceof Slot26) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Slot26) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Slot26) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Slot27 && y instanceof Slot27) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Slot27) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Slot27) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Slot28 && y instanceof Slot28) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at SixtySixItems.Domain.InventorySlot (line 39, column 1 - line 39, column 34): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqInventorySlot;
    }
};
var enumInventorySlot = {
    succ: function (v) {
        if (v instanceof Slot1) {
            return new Data_Maybe.Just(Slot2.value);
        };
        if (v instanceof Slot2) {
            return new Data_Maybe.Just(Slot3.value);
        };
        if (v instanceof Slot3) {
            return new Data_Maybe.Just(Slot4.value);
        };
        if (v instanceof Slot4) {
            return new Data_Maybe.Just(Slot5.value);
        };
        if (v instanceof Slot5) {
            return new Data_Maybe.Just(Slot6.value);
        };
        if (v instanceof Slot6) {
            return new Data_Maybe.Just(Slot7.value);
        };
        if (v instanceof Slot7) {
            return new Data_Maybe.Just(Slot8.value);
        };
        if (v instanceof Slot8) {
            return new Data_Maybe.Just(Slot9.value);
        };
        if (v instanceof Slot9) {
            return new Data_Maybe.Just(Slot10.value);
        };
        if (v instanceof Slot10) {
            return new Data_Maybe.Just(Slot11.value);
        };
        if (v instanceof Slot11) {
            return new Data_Maybe.Just(Slot12.value);
        };
        if (v instanceof Slot12) {
            return new Data_Maybe.Just(Slot13.value);
        };
        if (v instanceof Slot13) {
            return new Data_Maybe.Just(Slot14.value);
        };
        if (v instanceof Slot14) {
            return new Data_Maybe.Just(Slot15.value);
        };
        if (v instanceof Slot15) {
            return new Data_Maybe.Just(Slot16.value);
        };
        if (v instanceof Slot16) {
            return new Data_Maybe.Just(Slot17.value);
        };
        if (v instanceof Slot17) {
            return new Data_Maybe.Just(Slot18.value);
        };
        if (v instanceof Slot18) {
            return new Data_Maybe.Just(Slot19.value);
        };
        if (v instanceof Slot19) {
            return new Data_Maybe.Just(Slot20.value);
        };
        if (v instanceof Slot20) {
            return new Data_Maybe.Just(Slot21.value);
        };
        if (v instanceof Slot21) {
            return new Data_Maybe.Just(Slot22.value);
        };
        if (v instanceof Slot22) {
            return new Data_Maybe.Just(Slot23.value);
        };
        if (v instanceof Slot23) {
            return new Data_Maybe.Just(Slot24.value);
        };
        if (v instanceof Slot24) {
            return new Data_Maybe.Just(Slot25.value);
        };
        if (v instanceof Slot25) {
            return new Data_Maybe.Just(Slot26.value);
        };
        if (v instanceof Slot26) {
            return new Data_Maybe.Just(Slot27.value);
        };
        if (v instanceof Slot27) {
            return new Data_Maybe.Just(Slot28.value);
        };
        if (v instanceof Slot28) {
            return Data_Maybe.Nothing.value;
        };
        throw new Error("Failed pattern match at SixtySixItems.Domain.InventorySlot (line 42, column 10 - line 70, column 22): " + [ v.constructor.name ]);
    },
    pred: function (v) {
        if (v instanceof Slot1) {
            return Data_Maybe.Nothing.value;
        };
        if (v instanceof Slot2) {
            return new Data_Maybe.Just(Slot1.value);
        };
        if (v instanceof Slot3) {
            return new Data_Maybe.Just(Slot2.value);
        };
        if (v instanceof Slot4) {
            return new Data_Maybe.Just(Slot3.value);
        };
        if (v instanceof Slot5) {
            return new Data_Maybe.Just(Slot4.value);
        };
        if (v instanceof Slot6) {
            return new Data_Maybe.Just(Slot5.value);
        };
        if (v instanceof Slot7) {
            return new Data_Maybe.Just(Slot6.value);
        };
        if (v instanceof Slot8) {
            return new Data_Maybe.Just(Slot7.value);
        };
        if (v instanceof Slot9) {
            return new Data_Maybe.Just(Slot8.value);
        };
        if (v instanceof Slot10) {
            return new Data_Maybe.Just(Slot9.value);
        };
        if (v instanceof Slot11) {
            return new Data_Maybe.Just(Slot10.value);
        };
        if (v instanceof Slot12) {
            return new Data_Maybe.Just(Slot11.value);
        };
        if (v instanceof Slot13) {
            return new Data_Maybe.Just(Slot12.value);
        };
        if (v instanceof Slot14) {
            return new Data_Maybe.Just(Slot13.value);
        };
        if (v instanceof Slot15) {
            return new Data_Maybe.Just(Slot14.value);
        };
        if (v instanceof Slot16) {
            return new Data_Maybe.Just(Slot15.value);
        };
        if (v instanceof Slot17) {
            return new Data_Maybe.Just(Slot16.value);
        };
        if (v instanceof Slot18) {
            return new Data_Maybe.Just(Slot17.value);
        };
        if (v instanceof Slot19) {
            return new Data_Maybe.Just(Slot18.value);
        };
        if (v instanceof Slot20) {
            return new Data_Maybe.Just(Slot19.value);
        };
        if (v instanceof Slot21) {
            return new Data_Maybe.Just(Slot20.value);
        };
        if (v instanceof Slot22) {
            return new Data_Maybe.Just(Slot21.value);
        };
        if (v instanceof Slot23) {
            return new Data_Maybe.Just(Slot22.value);
        };
        if (v instanceof Slot24) {
            return new Data_Maybe.Just(Slot23.value);
        };
        if (v instanceof Slot25) {
            return new Data_Maybe.Just(Slot24.value);
        };
        if (v instanceof Slot26) {
            return new Data_Maybe.Just(Slot25.value);
        };
        if (v instanceof Slot27) {
            return new Data_Maybe.Just(Slot26.value);
        };
        if (v instanceof Slot28) {
            return new Data_Maybe.Just(Slot27.value);
        };
        throw new Error("Failed pattern match at SixtySixItems.Domain.InventorySlot (line 72, column 10 - line 100, column 26): " + [ v.constructor.name ]);
    },
    Ord0: function () {
        return ordInventorySlot;
    }
};
var boundedInventorySlot = {
    bottom: Slot1.value,
    top: Slot28.value,
    Ord0: function () {
        return ordInventorySlot;
    }
};
var boundedEnumInventorySlot = {
    cardinality: Data_Enum.defaultCardinality(boundedInventorySlot)(enumInventorySlot),
    toEnum: Data_Enum.defaultToEnum(boundedInventorySlot)(enumInventorySlot),
    fromEnum: Data_Enum.defaultFromEnum(enumInventorySlot),
    Bounded0: function () {
        return boundedInventorySlot;
    },
    Enum1: function () {
        return enumInventorySlot;
    }
};
module.exports = {
    Slot1: Slot1,
    Slot2: Slot2,
    Slot3: Slot3,
    Slot4: Slot4,
    Slot5: Slot5,
    Slot6: Slot6,
    Slot7: Slot7,
    Slot8: Slot8,
    Slot9: Slot9,
    Slot10: Slot10,
    Slot11: Slot11,
    Slot12: Slot12,
    Slot13: Slot13,
    Slot14: Slot14,
    Slot15: Slot15,
    Slot16: Slot16,
    Slot17: Slot17,
    Slot18: Slot18,
    Slot19: Slot19,
    Slot20: Slot20,
    Slot21: Slot21,
    Slot22: Slot22,
    Slot23: Slot23,
    Slot24: Slot24,
    Slot25: Slot25,
    Slot26: Slot26,
    Slot27: Slot27,
    Slot28: Slot28,
    eqInventorySlot: eqInventorySlot,
    ordInventorySlot: ordInventorySlot,
    enumInventorySlot: enumInventorySlot,
    boundedInventorySlot: boundedInventorySlot,
    boundedEnumInventorySlot: boundedEnumInventorySlot
};
