// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Data_Bifunctor = require("../Data.Bifunctor/index.js");
var Data_Const = require("../Data.Const/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Functor_App = require("../Data.Functor.App/index.js");
var Data_Functor_Compose = require("../Data.Functor.Compose/index.js");
var Data_Functor_Coproduct = require("../Data.Functor.Coproduct/index.js");
var Data_Functor_Product = require("../Data.Functor.Product/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Maybe_First = require("../Data.Maybe.First/index.js");
var Data_Maybe_Last = require("../Data.Maybe.Last/index.js");
var Data_Monoid_Additive = require("../Data.Monoid.Additive/index.js");
var Data_Monoid_Conj = require("../Data.Monoid.Conj/index.js");
var Data_Monoid_Disj = require("../Data.Monoid.Disj/index.js");
var Data_Monoid_Dual = require("../Data.Monoid.Dual/index.js");
var Data_Monoid_Multiplicative = require("../Data.Monoid.Multiplicative/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var mapWithIndex = function (dict) {
    return dict.mapWithIndex;
};
var mapDefault = function (dictFunctorWithIndex) {
    return function (f) {
        return mapWithIndex(dictFunctorWithIndex)(Data_Function["const"](f));
    };
};
var functorWithIndexTuple = {
    mapWithIndex: function (f) {
        return Data_Functor.map(Data_Tuple.functorTuple)(f(Data_Unit.unit));
    },
    Functor0: function () {
        return Data_Tuple.functorTuple;
    }
};
var functorWithIndexProduct = function (dictFunctorWithIndex) {
    return function (dictFunctorWithIndex1) {
        return {
            mapWithIndex: function (f) {
                return function (v) {
                    return Data_Bifunctor.bimap(Data_Bifunctor.bifunctorTuple)(mapWithIndex(dictFunctorWithIndex)(function ($28) {
                        return f(Data_Either.Left.create($28));
                    }))(mapWithIndex(dictFunctorWithIndex1)(function ($29) {
                        return f(Data_Either.Right.create($29));
                    }))(v);
                };
            },
            Functor0: function () {
                return Data_Functor_Product.functorProduct(dictFunctorWithIndex.Functor0())(dictFunctorWithIndex1.Functor0());
            }
        };
    };
};
var functorWithIndexMultiplicative = {
    mapWithIndex: function (f) {
        return Data_Functor.map(Data_Monoid_Multiplicative.functorMultiplicative)(f(Data_Unit.unit));
    },
    Functor0: function () {
        return Data_Monoid_Multiplicative.functorMultiplicative;
    }
};
var functorWithIndexMaybe = {
    mapWithIndex: function (f) {
        return Data_Functor.map(Data_Maybe.functorMaybe)(f(Data_Unit.unit));
    },
    Functor0: function () {
        return Data_Maybe.functorMaybe;
    }
};
var functorWithIndexLast = {
    mapWithIndex: function (f) {
        return Data_Functor.map(Data_Maybe_Last.functorLast)(f(Data_Unit.unit));
    },
    Functor0: function () {
        return Data_Maybe_Last.functorLast;
    }
};
var functorWithIndexIdentity = {
    mapWithIndex: function (f) {
        return function (v) {
            return f(Data_Unit.unit)(v);
        };
    },
    Functor0: function () {
        return Data_Identity.functorIdentity;
    }
};
var functorWithIndexFirst = {
    mapWithIndex: function (f) {
        return Data_Functor.map(Data_Maybe_First.functorFirst)(f(Data_Unit.unit));
    },
    Functor0: function () {
        return Data_Maybe_First.functorFirst;
    }
};
var functorWithIndexEither = {
    mapWithIndex: function (f) {
        return Data_Functor.map(Data_Either.functorEither)(f(Data_Unit.unit));
    },
    Functor0: function () {
        return Data_Either.functorEither;
    }
};
var functorWithIndexDual = {
    mapWithIndex: function (f) {
        return Data_Functor.map(Data_Monoid_Dual.functorDual)(f(Data_Unit.unit));
    },
    Functor0: function () {
        return Data_Monoid_Dual.functorDual;
    }
};
var functorWithIndexDisj = {
    mapWithIndex: function (f) {
        return Data_Functor.map(Data_Monoid_Disj.functorDisj)(f(Data_Unit.unit));
    },
    Functor0: function () {
        return Data_Monoid_Disj.functorDisj;
    }
};
var functorWithIndexCoproduct = function (dictFunctorWithIndex) {
    return function (dictFunctorWithIndex1) {
        return {
            mapWithIndex: function (f) {
                return function (v) {
                    return Data_Bifunctor.bimap(Data_Bifunctor.bifunctorEither)(mapWithIndex(dictFunctorWithIndex)(function ($30) {
                        return f(Data_Either.Left.create($30));
                    }))(mapWithIndex(dictFunctorWithIndex1)(function ($31) {
                        return f(Data_Either.Right.create($31));
                    }))(v);
                };
            },
            Functor0: function () {
                return Data_Functor_Coproduct.functorCoproduct(dictFunctorWithIndex.Functor0())(dictFunctorWithIndex1.Functor0());
            }
        };
    };
};
var functorWithIndexConst = {
    mapWithIndex: function (v) {
        return function (v1) {
            return v1;
        };
    },
    Functor0: function () {
        return Data_Const.functorConst;
    }
};
var functorWithIndexConj = {
    mapWithIndex: function (f) {
        return Data_Functor.map(Data_Monoid_Conj.functorConj)(f(Data_Unit.unit));
    },
    Functor0: function () {
        return Data_Monoid_Conj.functorConj;
    }
};
var functorWithIndexCompose = function (dictFunctorWithIndex) {
    return function (dictFunctorWithIndex1) {
        return {
            mapWithIndex: function (f) {
                return function (v) {
                    return Data_Functor_Compose.Compose(mapWithIndex(dictFunctorWithIndex)((function () {
                        var $32 = mapWithIndex(dictFunctorWithIndex1);
                        var $33 = Data_Tuple.curry(f);
                        return function ($34) {
                            return $32($33($34));
                        };
                    })())(v));
                };
            },
            Functor0: function () {
                return Data_Functor_Compose.functorCompose(dictFunctorWithIndex.Functor0())(dictFunctorWithIndex1.Functor0());
            }
        };
    };
};
var functorWithIndexArray = {
    mapWithIndex: $foreign.mapWithIndexArray,
    Functor0: function () {
        return Data_Functor.functorArray;
    }
};
var functorWithIndexApp = function (dictFunctorWithIndex) {
    return {
        mapWithIndex: function (f) {
            return function (v) {
                return Data_Functor_App.App(mapWithIndex(dictFunctorWithIndex)(f)(v));
            };
        },
        Functor0: function () {
            return Data_Functor_App.functorApp(dictFunctorWithIndex.Functor0());
        }
    };
};
var functorWithIndexAdditive = {
    mapWithIndex: function (f) {
        return Data_Functor.map(Data_Monoid_Additive.functorAdditive)(f(Data_Unit.unit));
    },
    Functor0: function () {
        return Data_Monoid_Additive.functorAdditive;
    }
};
module.exports = {
    mapWithIndex: mapWithIndex,
    mapDefault: mapDefault,
    functorWithIndexArray: functorWithIndexArray,
    functorWithIndexMaybe: functorWithIndexMaybe,
    functorWithIndexFirst: functorWithIndexFirst,
    functorWithIndexLast: functorWithIndexLast,
    functorWithIndexAdditive: functorWithIndexAdditive,
    functorWithIndexDual: functorWithIndexDual,
    functorWithIndexConj: functorWithIndexConj,
    functorWithIndexDisj: functorWithIndexDisj,
    functorWithIndexMultiplicative: functorWithIndexMultiplicative,
    functorWithIndexEither: functorWithIndexEither,
    functorWithIndexTuple: functorWithIndexTuple,
    functorWithIndexIdentity: functorWithIndexIdentity,
    functorWithIndexConst: functorWithIndexConst,
    functorWithIndexProduct: functorWithIndexProduct,
    functorWithIndexCoproduct: functorWithIndexCoproduct,
    functorWithIndexCompose: functorWithIndexCompose,
    functorWithIndexApp: functorWithIndexApp
};
