// Generated by purs version 0.14.5
"use strict";
var Halogen_VDom_DOM = require("../Halogen.VDom.DOM/index.js");
var Halogen_VDom_Machine = require("../Halogen.VDom.Machine/index.js");
var Halogen_VDom_Types = require("../Halogen.VDom.Types/index.js");
module.exports = {
    VDomSpec: Halogen_VDom_DOM.VDomSpec,
    buildVDom: Halogen_VDom_DOM.buildVDom,
    Step: Halogen_VDom_Machine.Step,
    extract: Halogen_VDom_Machine.extract,
    halt: Halogen_VDom_Machine.halt,
    mkStep: Halogen_VDom_Machine.mkStep,
    step: Halogen_VDom_Machine.step,
    unStep: Halogen_VDom_Machine.unStep,
    ElemName: Halogen_VDom_Types.ElemName,
    Namespace: Halogen_VDom_Types.Namespace,
    Elem: Halogen_VDom_Types.Elem,
    Grafted: Halogen_VDom_Types.Grafted,
    Keyed: Halogen_VDom_Types.Keyed,
    Text: Halogen_VDom_Types.Text,
    Widget: Halogen_VDom_Types.Widget,
    runGraft: Halogen_VDom_Types.runGraft
};
