// Generated by purs version 0.14.5
"use strict";
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Tagged = function (x) {
    return x;
};
var taggedProfunctor = {
    dimap: function (v) {
        return function (g) {
            return function (v1) {
                return g(v1);
            };
        };
    }
};
var taggedCostrong = {
    unfirst: function (v) {
        return v.value0;
    },
    unsecond: function (v) {
        return v.value1;
    },
    Profunctor0: function () {
        return taggedProfunctor;
    }
};
var taggedClosed = {
    closed: function (v) {
        return Data_Function["const"](v);
    },
    Profunctor0: function () {
        return taggedProfunctor;
    }
};
var taggedChoice = {
    left: function (v) {
        return new Data_Either.Left(v);
    },
    right: function (v) {
        return new Data_Either.Right(v);
    },
    Profunctor0: function () {
        return taggedProfunctor;
    }
};
var newtypeTagged = {
    Coercible0: function () {
        return undefined;
    }
};
var functorTagged = {
    map: function (f) {
        return function (m) {
            return f(m);
        };
    }
};
var foldableTagged = {
    foldMap: function (dictMonoid) {
        return function (f) {
            return function (v) {
                return f(v);
            };
        };
    },
    foldr: function (f) {
        return function (b) {
            return function (v) {
                return f(v)(b);
            };
        };
    },
    foldl: function (f) {
        return function (b) {
            return function (v) {
                return f(b)(v);
            };
        };
    }
};
var traversableTagged = {
    sequence: function (dictApplicative) {
        return function (v) {
            return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Tagged)(v);
        };
    },
    traverse: function (dictApplicative) {
        return function (f) {
            return function (v) {
                return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Tagged)(f(v));
            };
        };
    },
    Functor0: function () {
        return functorTagged;
    },
    Foldable1: function () {
        return foldableTagged;
    }
};
var eqTagged = function (dictEq) {
    return {
        eq: function (x) {
            return function (y) {
                return Data_Eq.eq(dictEq)(x)(y);
            };
        }
    };
};
var ordTagged = function (dictOrd) {
    return {
        compare: function (x) {
            return function (y) {
                return Data_Ord.compare(dictOrd)(x)(y);
            };
        },
        Eq0: function () {
            return eqTagged(dictOrd.Eq0());
        }
    };
};
var eq1Tagged = {
    eq1: function (dictEq) {
        return Data_Eq.eq(eqTagged(dictEq));
    }
};
var ord1Tagged = {
    compare1: function (dictOrd) {
        return Data_Ord.compare(ordTagged(dictOrd));
    },
    Eq10: function () {
        return eq1Tagged;
    }
};
module.exports = {
    Tagged: Tagged,
    newtypeTagged: newtypeTagged,
    eqTagged: eqTagged,
    eq1Tagged: eq1Tagged,
    ordTagged: ordTagged,
    ord1Tagged: ord1Tagged,
    functorTagged: functorTagged,
    taggedProfunctor: taggedProfunctor,
    taggedChoice: taggedChoice,
    taggedCostrong: taggedCostrong,
    taggedClosed: taggedClosed,
    foldableTagged: foldableTagged,
    traversableTagged: traversableTagged
};
