// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Show = require("../Data.Show/index.js");
var Utf8 = (function () {
    function Utf8() {

    };
    Utf8.value = new Utf8();
    return Utf8;
})();
var Ibm866 = (function () {
    function Ibm866() {

    };
    Ibm866.value = new Ibm866();
    return Ibm866;
})();
var Iso_8859_2 = (function () {
    function Iso_8859_2() {

    };
    Iso_8859_2.value = new Iso_8859_2();
    return Iso_8859_2;
})();
var Iso_8859_3 = (function () {
    function Iso_8859_3() {

    };
    Iso_8859_3.value = new Iso_8859_3();
    return Iso_8859_3;
})();
var Iso_8859_4 = (function () {
    function Iso_8859_4() {

    };
    Iso_8859_4.value = new Iso_8859_4();
    return Iso_8859_4;
})();
var Iso_8859_5 = (function () {
    function Iso_8859_5() {

    };
    Iso_8859_5.value = new Iso_8859_5();
    return Iso_8859_5;
})();
var Iso_8859_6 = (function () {
    function Iso_8859_6() {

    };
    Iso_8859_6.value = new Iso_8859_6();
    return Iso_8859_6;
})();
var Iso_8859_7 = (function () {
    function Iso_8859_7() {

    };
    Iso_8859_7.value = new Iso_8859_7();
    return Iso_8859_7;
})();
var Iso_8859_8 = (function () {
    function Iso_8859_8() {

    };
    Iso_8859_8.value = new Iso_8859_8();
    return Iso_8859_8;
})();
var Iso_8859_8_I = (function () {
    function Iso_8859_8_I() {

    };
    Iso_8859_8_I.value = new Iso_8859_8_I();
    return Iso_8859_8_I;
})();
var Iso_8859_10 = (function () {
    function Iso_8859_10() {

    };
    Iso_8859_10.value = new Iso_8859_10();
    return Iso_8859_10;
})();
var Iso_8859_13 = (function () {
    function Iso_8859_13() {

    };
    Iso_8859_13.value = new Iso_8859_13();
    return Iso_8859_13;
})();
var Iso_8859_14 = (function () {
    function Iso_8859_14() {

    };
    Iso_8859_14.value = new Iso_8859_14();
    return Iso_8859_14;
})();
var Iso_8859_15 = (function () {
    function Iso_8859_15() {

    };
    Iso_8859_15.value = new Iso_8859_15();
    return Iso_8859_15;
})();
var Iso_8859_16 = (function () {
    function Iso_8859_16() {

    };
    Iso_8859_16.value = new Iso_8859_16();
    return Iso_8859_16;
})();
var Koi8_R = (function () {
    function Koi8_R() {

    };
    Koi8_R.value = new Koi8_R();
    return Koi8_R;
})();
var Koi8_U = (function () {
    function Koi8_U() {

    };
    Koi8_U.value = new Koi8_U();
    return Koi8_U;
})();
var Macintosh = (function () {
    function Macintosh() {

    };
    Macintosh.value = new Macintosh();
    return Macintosh;
})();
var Windows_874 = (function () {
    function Windows_874() {

    };
    Windows_874.value = new Windows_874();
    return Windows_874;
})();
var Windows_1250 = (function () {
    function Windows_1250() {

    };
    Windows_1250.value = new Windows_1250();
    return Windows_1250;
})();
var Windows_1251 = (function () {
    function Windows_1251() {

    };
    Windows_1251.value = new Windows_1251();
    return Windows_1251;
})();
var Windows_1252 = (function () {
    function Windows_1252() {

    };
    Windows_1252.value = new Windows_1252();
    return Windows_1252;
})();
var Windows_1253 = (function () {
    function Windows_1253() {

    };
    Windows_1253.value = new Windows_1253();
    return Windows_1253;
})();
var Windows_1254 = (function () {
    function Windows_1254() {

    };
    Windows_1254.value = new Windows_1254();
    return Windows_1254;
})();
var Windows_1255 = (function () {
    function Windows_1255() {

    };
    Windows_1255.value = new Windows_1255();
    return Windows_1255;
})();
var Windows_1256 = (function () {
    function Windows_1256() {

    };
    Windows_1256.value = new Windows_1256();
    return Windows_1256;
})();
var Windows_1257 = (function () {
    function Windows_1257() {

    };
    Windows_1257.value = new Windows_1257();
    return Windows_1257;
})();
var Windows_1258 = (function () {
    function Windows_1258() {

    };
    Windows_1258.value = new Windows_1258();
    return Windows_1258;
})();
var X_Mac_Cyrillic = (function () {
    function X_Mac_Cyrillic() {

    };
    X_Mac_Cyrillic.value = new X_Mac_Cyrillic();
    return X_Mac_Cyrillic;
})();
var Gbk = (function () {
    function Gbk() {

    };
    Gbk.value = new Gbk();
    return Gbk;
})();
var Gb18030 = (function () {
    function Gb18030() {

    };
    Gb18030.value = new Gb18030();
    return Gb18030;
})();
var Big5 = (function () {
    function Big5() {

    };
    Big5.value = new Big5();
    return Big5;
})();
var Euc_Jp = (function () {
    function Euc_Jp() {

    };
    Euc_Jp.value = new Euc_Jp();
    return Euc_Jp;
})();
var Iso_2022_Jp = (function () {
    function Iso_2022_Jp() {

    };
    Iso_2022_Jp.value = new Iso_2022_Jp();
    return Iso_2022_Jp;
})();
var Shift_Jis = (function () {
    function Shift_Jis() {

    };
    Shift_Jis.value = new Shift_Jis();
    return Shift_Jis;
})();
var Euc_Kr = (function () {
    function Euc_Kr() {

    };
    Euc_Kr.value = new Euc_Kr();
    return Euc_Kr;
})();
var Replacement = (function () {
    function Replacement() {

    };
    Replacement.value = new Replacement();
    return Replacement;
})();
var Utf_16Be = (function () {
    function Utf_16Be() {

    };
    Utf_16Be.value = new Utf_16Be();
    return Utf_16Be;
})();
var Utf_16Le = (function () {
    function Utf_16Le() {

    };
    Utf_16Le.value = new Utf_16Le();
    return Utf_16Le;
})();
var X_User_Defined = (function () {
    function X_User_Defined() {

    };
    X_User_Defined.value = new X_User_Defined();
    return X_User_Defined;
})();
var showEncoding = {
    show: function (v) {
        if (v instanceof Utf8) {
            return "utf-8";
        };
        if (v instanceof Ibm866) {
            return "ibm866";
        };
        if (v instanceof Iso_8859_2) {
            return "iso-8859-2";
        };
        if (v instanceof Iso_8859_3) {
            return "iso-8859-3";
        };
        if (v instanceof Iso_8859_4) {
            return "iso-8859-4";
        };
        if (v instanceof Iso_8859_5) {
            return "iso-8859-5";
        };
        if (v instanceof Iso_8859_6) {
            return "iso-8859-6";
        };
        if (v instanceof Iso_8859_7) {
            return "iso-8859-7";
        };
        if (v instanceof Iso_8859_8) {
            return "iso-8859-8";
        };
        if (v instanceof Iso_8859_8_I) {
            return "iso-8859-8-i";
        };
        if (v instanceof Iso_8859_10) {
            return "iso-8859-10";
        };
        if (v instanceof Iso_8859_13) {
            return "iso-8859-13";
        };
        if (v instanceof Iso_8859_14) {
            return "iso-8859-14";
        };
        if (v instanceof Iso_8859_15) {
            return "iso-8859-15";
        };
        if (v instanceof Iso_8859_16) {
            return "iso-8859-16";
        };
        if (v instanceof Koi8_R) {
            return "koi8-r";
        };
        if (v instanceof Koi8_U) {
            return "koi8-u";
        };
        if (v instanceof Macintosh) {
            return "macintosh";
        };
        if (v instanceof Windows_874) {
            return "windows-874";
        };
        if (v instanceof Windows_1250) {
            return "windows-1250";
        };
        if (v instanceof Windows_1251) {
            return "windows-1251";
        };
        if (v instanceof Windows_1252) {
            return "windows-1252";
        };
        if (v instanceof Windows_1253) {
            return "windows-1253";
        };
        if (v instanceof Windows_1254) {
            return "windows-1254";
        };
        if (v instanceof Windows_1255) {
            return "windows-1255";
        };
        if (v instanceof Windows_1256) {
            return "windows-1256";
        };
        if (v instanceof Windows_1257) {
            return "windows-1257";
        };
        if (v instanceof Windows_1258) {
            return "windows-1258";
        };
        if (v instanceof X_Mac_Cyrillic) {
            return "x-max-cyrillic";
        };
        if (v instanceof Gbk) {
            return "gbk";
        };
        if (v instanceof Gb18030) {
            return "gb18030";
        };
        if (v instanceof Big5) {
            return "big5";
        };
        if (v instanceof Euc_Jp) {
            return "euc-jp";
        };
        if (v instanceof Iso_2022_Jp) {
            return "iso-2022-jp";
        };
        if (v instanceof Shift_Jis) {
            return "shift-jis";
        };
        if (v instanceof Euc_Kr) {
            return "euc-kr";
        };
        if (v instanceof Replacement) {
            return "iso-2022-kr";
        };
        if (v instanceof Utf_16Be) {
            return "utf-16be";
        };
        if (v instanceof Utf_16Le) {
            return "utf-16le";
        };
        if (v instanceof X_User_Defined) {
            return "x-user-defined";
        };
        throw new Error("Failed pattern match at Data.TextDecoder (line 87, column 1 - line 127, column 41): " + [ v.constructor.name ]);
    }
};
var decode = function (encoding) {
    return function (buffer) {
        return $foreign.decodeImpl(Data_Either.Left.create, Data_Either.Right.create, Data_Show.show(showEncoding)(encoding), buffer);
    };
};
var decodeUtf8 = decode(Utf8.value);
module.exports = {
    Utf8: Utf8,
    Ibm866: Ibm866,
    Iso_8859_2: Iso_8859_2,
    Iso_8859_3: Iso_8859_3,
    Iso_8859_4: Iso_8859_4,
    Iso_8859_5: Iso_8859_5,
    Iso_8859_6: Iso_8859_6,
    Iso_8859_7: Iso_8859_7,
    Iso_8859_8: Iso_8859_8,
    Iso_8859_8_I: Iso_8859_8_I,
    Iso_8859_10: Iso_8859_10,
    Iso_8859_13: Iso_8859_13,
    Iso_8859_14: Iso_8859_14,
    Iso_8859_15: Iso_8859_15,
    Iso_8859_16: Iso_8859_16,
    Koi8_R: Koi8_R,
    Koi8_U: Koi8_U,
    Macintosh: Macintosh,
    Windows_874: Windows_874,
    Windows_1250: Windows_1250,
    Windows_1251: Windows_1251,
    Windows_1252: Windows_1252,
    Windows_1253: Windows_1253,
    Windows_1254: Windows_1254,
    Windows_1255: Windows_1255,
    Windows_1256: Windows_1256,
    Windows_1257: Windows_1257,
    Windows_1258: Windows_1258,
    X_Mac_Cyrillic: X_Mac_Cyrillic,
    Gbk: Gbk,
    Gb18030: Gb18030,
    Big5: Big5,
    Euc_Jp: Euc_Jp,
    Iso_2022_Jp: Iso_2022_Jp,
    Shift_Jis: Shift_Jis,
    Euc_Kr: Euc_Kr,
    Replacement: Replacement,
    Utf_16Be: Utf_16Be,
    Utf_16Le: Utf_16Le,
    X_User_Defined: X_User_Defined,
    decode: decode,
    decodeUtf8: decodeUtf8,
    showEncoding: showEncoding
};
