// Generated by purs version 0.14.5
"use strict";
var Data_Bifunctor = require("../Data.Bifunctor/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Stall = (function () {
    function Stall(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Stall.create = function (value0) {
        return function (value1) {
            return new Stall(value0, value1);
        };
    };
    return Stall;
})();
var profunctorStall = {
    dimap: function (f) {
        return function (g) {
            return function (v) {
                return new Stall((function () {
                    var $61 = Data_Functor.map(Data_Functor.functorFn)(g);
                    return function ($62) {
                        return $61(v.value0(f($62)));
                    };
                })(), (function () {
                    var $63 = Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(g);
                    return function ($64) {
                        return $63(v.value1(f($64)));
                    };
                })());
            };
        };
    }
};
var strongStall = {
    first: function (v) {
        return new Stall(function (v1) {
            return function (b) {
                return new Data_Tuple.Tuple(v.value0(v1.value0)(b), v1.value1);
            };
        }, function (v1) {
            return Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(function (t) {
                return new Data_Tuple.Tuple(t, v1.value1);
            })(v.value1(v1.value0));
        });
    },
    second: function (v) {
        return new Stall(function (v1) {
            return function (b) {
                return new Data_Tuple.Tuple(v1.value0, v.value0(v1.value1)(b));
            };
        }, function (v1) {
            return Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(Data_Tuple.Tuple.create(v1.value0))(v.value1(v1.value1));
        });
    },
    Profunctor0: function () {
        return profunctorStall;
    }
};
var functorStall = {
    map: function (f) {
        return function (v) {
            return new Stall((function () {
                var $65 = Data_Functor.map(Data_Functor.functorFn)(f);
                return function ($66) {
                    return $65(v.value0($66));
                };
            })(), (function () {
                var $67 = Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(f);
                return function ($68) {
                    return $67(v.value1($68));
                };
            })());
        };
    }
};
var choiceStall = {
    left: function (v) {
        return new Stall(function (v1) {
            if (v1 instanceof Data_Either.Left) {
                return function (b) {
                    return new Data_Either.Left(v.value0(v1.value0)(b));
                };
            };
            if (v1 instanceof Data_Either.Right) {
                return function (v2) {
                    return new Data_Either.Right(v1.value0);
                };
            };
            throw new Error("Failed pattern match at Data.Lens.Internal.Stall (line 36, column 8 - line 38, column 33): " + [ v1.constructor.name ]);
        }, function (v1) {
            if (v1 instanceof Data_Either.Left) {
                return Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(Data_Either.Left.create)(v.value1(v1.value0));
            };
            if (v1 instanceof Data_Either.Right) {
                return new Data_Either.Left(new Data_Either.Right(v1.value0));
            };
            throw new Error("Failed pattern match at Data.Lens.Internal.Stall (line 39, column 8 - line 41, column 34): " + [ v1.constructor.name ]);
        });
    },
    right: function (v) {
        return new Stall(function (v1) {
            if (v1 instanceof Data_Either.Left) {
                return function (v2) {
                    return new Data_Either.Left(v1.value0);
                };
            };
            if (v1 instanceof Data_Either.Right) {
                return function (b) {
                    return new Data_Either.Right(v.value0(v1.value0)(b));
                };
            };
            throw new Error("Failed pattern match at Data.Lens.Internal.Stall (line 45, column 8 - line 47, column 39): " + [ v1.constructor.name ]);
        }, function (v1) {
            if (v1 instanceof Data_Either.Left) {
                return new Data_Either.Left(new Data_Either.Left(v1.value0));
            };
            if (v1 instanceof Data_Either.Right) {
                return Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(Data_Either.Right.create)(v.value1(v1.value0));
            };
            throw new Error("Failed pattern match at Data.Lens.Internal.Stall (line 48, column 8 - line 50, column 36): " + [ v1.constructor.name ]);
        });
    },
    Profunctor0: function () {
        return profunctorStall;
    }
};
module.exports = {
    Stall: Stall,
    functorStall: functorStall,
    profunctorStall: profunctorStall,
    strongStall: strongStall,
    choiceStall: choiceStall
};
