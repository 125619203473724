// Generated by purs version 0.14.5
"use strict";
var Data_Coyoneda = require("../Data.Coyoneda/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Initialize = (function () {
    function Initialize(value0) {
        this.value0 = value0;
    };
    Initialize.create = function (value0) {
        return new Initialize(value0);
    };
    return Initialize;
})();
var Finalize = (function () {
    function Finalize(value0) {
        this.value0 = value0;
    };
    Finalize.create = function (value0) {
        return new Finalize(value0);
    };
    return Finalize;
})();
var Receive = (function () {
    function Receive(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Receive.create = function (value0) {
        return function (value1) {
            return new Receive(value0, value1);
        };
    };
    return Receive;
})();
var Action = (function () {
    function Action(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Action.create = function (value0) {
        return function (value1) {
            return new Action(value0, value1);
        };
    };
    return Action;
})();
var Query = (function () {
    function Query(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Query.create = function (value0) {
        return function (value1) {
            return new Query(value0, value1);
        };
    };
    return Query;
})();
var functorHalogenQ = {
    map: function (f) {
        return function (m) {
            if (m instanceof Initialize) {
                return new Initialize(f(m.value0));
            };
            if (m instanceof Finalize) {
                return new Finalize(f(m.value0));
            };
            if (m instanceof Receive) {
                return new Receive(m.value0, f(m.value1));
            };
            if (m instanceof Action) {
                return new Action(m.value0, f(m.value1));
            };
            if (m instanceof Query) {
                return new Query(Data_Functor.map(Data_Coyoneda.functorCoyoneda)(f)(m.value0), Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
            };
            throw new Error("Failed pattern match at Halogen.Query.HalogenQ (line 23, column 1 - line 23, column 73): " + [ m.constructor.name ]);
        };
    }
};
var bifunctorHalogenQ = {
    bimap: function (f) {
        return function (g) {
            return function (v) {
                if (v instanceof Initialize) {
                    return new Initialize(g(v.value0));
                };
                if (v instanceof Finalize) {
                    return new Finalize(g(v.value0));
                };
                if (v instanceof Receive) {
                    return new Receive(f(v.value0), g(v.value1));
                };
                if (v instanceof Action) {
                    return new Action(v.value0, g(v.value1));
                };
                if (v instanceof Query) {
                    return new Query(Data_Functor.map(Data_Coyoneda.functorCoyoneda)(g)(v.value0), Data_Functor.map(Data_Functor.functorFn)(g)(v.value1));
                };
                throw new Error("Failed pattern match at Halogen.Query.HalogenQ (line 16, column 15 - line 21, column 45): " + [ v.constructor.name ]);
            };
        };
    }
};
module.exports = {
    Initialize: Initialize,
    Finalize: Finalize,
    Receive: Receive,
    Action: Action,
    Query: Query,
    bifunctorHalogenQ: bifunctorHalogenQ,
    functorHalogenQ: functorHalogenQ
};
