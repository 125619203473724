// Generated by purs version 0.14.5
"use strict";
var Data_Function_Uncurried = require("../Data.Function.Uncurried/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Halogen_VDom_DOM = require("../Halogen.VDom.DOM/index.js");
var Halogen_VDom_Machine = require("../Halogen.VDom.Machine/index.js");
var Halogen_VDom_Util = require("../Halogen.VDom.Util/index.js");
var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");
var Thunk = (function () {
    function Thunk(value0, value1, value2, value3) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
        this.value3 = value3;
    };
    Thunk.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return function (value3) {
                    return new Thunk(value0, value1, value2, value3);
                };
            };
        };
    };
    return Thunk;
})();
var unsafeThunkId = Unsafe_Coerce.unsafeCoerce;
var unsafeEqThunk = function (v, v1) {
    return Halogen_VDom_Util.refEq(v.value0, v1.value0) && (Halogen_VDom_Util.refEq(v.value1, v1.value1) && v.value1(v.value3, v1.value3));
};
var thunk = function (tid, eqFn, f, a) {
    return new Thunk(tid, eqFn, f, a);
};
var thunk1 = function (f, a) {
    return thunk(unsafeThunkId(f), Halogen_VDom_Util.refEq, f, a);
};
var thunk2 = (function () {
    var eqFn = function (a, b) {
        return Halogen_VDom_Util.refEq(a["_1"], b["_1"]) && Halogen_VDom_Util.refEq(a["_2"], b["_2"]);
    };
    return function (f, a, b) {
        return thunk(unsafeThunkId(f), eqFn, function (v) {
            return f(v["_1"])(v["_2"]);
        }, {
            "_1": a,
            "_2": b
        });
    };
})();
var thunk3 = (function () {
    var eqFn = function (a, b) {
        return Halogen_VDom_Util.refEq(a["_1"], b["_1"]) && (Halogen_VDom_Util.refEq(a["_2"], b["_2"]) && Halogen_VDom_Util.refEq(a["_3"], b["_3"]));
    };
    return function (f, a, b, c) {
        return thunk(unsafeThunkId(f), eqFn, function (v) {
            return f(v["_1"])(v["_2"])(v["_3"]);
        }, {
            "_1": a,
            "_2": b,
            "_3": c
        });
    };
})();
var thunked = function (eqFn) {
    return function (f) {
        var tid = unsafeThunkId({
            f: f
        });
        var eqFn$prime = Data_Function_Uncurried.mkFn2(eqFn);
        return function (a) {
            return thunk(tid, eqFn$prime, f, a);
        };
    };
};
var runThunk = function (v) {
    return v.value2(v.value3);
};
var mapThunk = function (k) {
    return function (v) {
        return new Thunk(v.value0, v.value1, function ($46) {
            return k(v.value2($46));
        }, v.value3);
    };
};
var hoist = mapThunk;
var functorThunk = function (dictFunctor) {
    return {
        map: function (f) {
            return function (v) {
                return new Thunk(v.value0, v.value1, (function () {
                    var $47 = Data_Functor.map(dictFunctor)(f);
                    return function ($48) {
                        return $47(v.value2($48));
                    };
                })(), v.value3);
            };
        }
    };
};
var buildThunk = function (toVDom) {
    var haltThunk = function (state) {
        return Halogen_VDom_Machine.halt(state.vdom);
    };
    var patchThunk = function (state, t2) {
        var $43 = unsafeEqThunk(state.thunk, t2);
        if ($43) {
            return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Halogen_VDom_Machine.extract(state.vdom), state, patchThunk, haltThunk));
        };
        var vdom = Halogen_VDom_Machine.step(state.vdom, toVDom(runThunk(t2)));
        return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Halogen_VDom_Machine.extract(vdom), {
            vdom: vdom,
            thunk: t2
        }, patchThunk, haltThunk));
    };
    var renderThunk = function (spec) {
        return function (t) {
            var vdom = Halogen_VDom_DOM.buildVDom(spec)(toVDom(runThunk(t)));
            return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Halogen_VDom_Machine.extract(vdom), {
                thunk: t,
                vdom: vdom
            }, patchThunk, haltThunk));
        };
    };
    return renderThunk;
};
module.exports = {
    buildThunk: buildThunk,
    runThunk: runThunk,
    hoist: hoist,
    mapThunk: mapThunk,
    thunked: thunked,
    thunk1: thunk1,
    thunk2: thunk2,
    thunk3: thunk3,
    functorThunk: functorThunk
};
