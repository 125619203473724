// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Ring = require("../Data.Ring/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Record_Unsafe = require("../Record.Unsafe/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var ordVoid = {
    compare: function (v) {
        return function (v1) {
            return Data_Ordering.EQ.value;
        };
    },
    Eq0: function () {
        return Data_Eq.eqVoid;
    }
};
var ordUnit = {
    compare: function (v) {
        return function (v1) {
            return Data_Ordering.EQ.value;
        };
    },
    Eq0: function () {
        return Data_Eq.eqUnit;
    }
};
var ordString = {
    compare: $foreign.ordStringImpl(Data_Ordering.LT.value)(Data_Ordering.EQ.value)(Data_Ordering.GT.value),
    Eq0: function () {
        return Data_Eq.eqString;
    }
};
var ordRecordNil = {
    compareRecord: function (v) {
        return function (v1) {
            return function (v2) {
                return Data_Ordering.EQ.value;
            };
        };
    },
    EqRecord0: function () {
        return Data_Eq.eqRowNil;
    }
};
var ordProxy3 = {
    compare: function (v) {
        return function (v1) {
            return Data_Ordering.EQ.value;
        };
    },
    Eq0: function () {
        return Data_Eq.eqProxy3;
    }
};
var ordProxy2 = {
    compare: function (v) {
        return function (v1) {
            return Data_Ordering.EQ.value;
        };
    },
    Eq0: function () {
        return Data_Eq.eqProxy2;
    }
};
var ordProxy = {
    compare: function (v) {
        return function (v1) {
            return Data_Ordering.EQ.value;
        };
    },
    Eq0: function () {
        return Data_Eq.eqProxy;
    }
};
var ordOrdering = {
    compare: function (v) {
        return function (v1) {
            if (v instanceof Data_Ordering.LT && v1 instanceof Data_Ordering.LT) {
                return Data_Ordering.EQ.value;
            };
            if (v instanceof Data_Ordering.EQ && v1 instanceof Data_Ordering.EQ) {
                return Data_Ordering.EQ.value;
            };
            if (v instanceof Data_Ordering.GT && v1 instanceof Data_Ordering.GT) {
                return Data_Ordering.EQ.value;
            };
            if (v instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if (v instanceof Data_Ordering.EQ && v1 instanceof Data_Ordering.LT) {
                return Data_Ordering.GT.value;
            };
            if (v instanceof Data_Ordering.EQ && v1 instanceof Data_Ordering.GT) {
                return Data_Ordering.LT.value;
            };
            if (v instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            throw new Error("Failed pattern match at Data.Ord (line 121, column 1 - line 128, column 21): " + [ v.constructor.name, v1.constructor.name ]);
        };
    },
    Eq0: function () {
        return Data_Ordering.eqOrdering;
    }
};
var ordNumber = {
    compare: $foreign.ordNumberImpl(Data_Ordering.LT.value)(Data_Ordering.EQ.value)(Data_Ordering.GT.value),
    Eq0: function () {
        return Data_Eq.eqNumber;
    }
};
var ordInt = {
    compare: $foreign.ordIntImpl(Data_Ordering.LT.value)(Data_Ordering.EQ.value)(Data_Ordering.GT.value),
    Eq0: function () {
        return Data_Eq.eqInt;
    }
};
var ordChar = {
    compare: $foreign.ordCharImpl(Data_Ordering.LT.value)(Data_Ordering.EQ.value)(Data_Ordering.GT.value),
    Eq0: function () {
        return Data_Eq.eqChar;
    }
};
var ordBoolean = {
    compare: $foreign.ordBooleanImpl(Data_Ordering.LT.value)(Data_Ordering.EQ.value)(Data_Ordering.GT.value),
    Eq0: function () {
        return Data_Eq.eqBoolean;
    }
};
var compareRecord = function (dict) {
    return dict.compareRecord;
};
var ordRecord = function (dictRowToList) {
    return function (dictOrdRecord) {
        return {
            compare: compareRecord(dictOrdRecord)(Type_Proxy["Proxy"].value),
            Eq0: function () {
                return Data_Eq.eqRec()(dictOrdRecord.EqRecord0());
            }
        };
    };
};
var compare1 = function (dict) {
    return dict.compare1;
};
var compare = function (dict) {
    return dict.compare;
};
var comparing = function (dictOrd) {
    return function (f) {
        return function (x) {
            return function (y) {
                return compare(dictOrd)(f(x))(f(y));
            };
        };
    };
};
var greaterThan = function (dictOrd) {
    return function (a1) {
        return function (a2) {
            var v = compare(dictOrd)(a1)(a2);
            if (v instanceof Data_Ordering.GT) {
                return true;
            };
            return false;
        };
    };
};
var greaterThanOrEq = function (dictOrd) {
    return function (a1) {
        return function (a2) {
            var v = compare(dictOrd)(a1)(a2);
            if (v instanceof Data_Ordering.LT) {
                return false;
            };
            return true;
        };
    };
};
var signum = function (dictOrd) {
    return function (dictRing) {
        return function (x) {
            var $52 = greaterThanOrEq(dictOrd)(x)(Data_Semiring.zero(dictRing.Semiring0()));
            if ($52) {
                return Data_Semiring.one(dictRing.Semiring0());
            };
            return Data_Ring.negate(dictRing)(Data_Semiring.one(dictRing.Semiring0()));
        };
    };
};
var lessThan = function (dictOrd) {
    return function (a1) {
        return function (a2) {
            var v = compare(dictOrd)(a1)(a2);
            if (v instanceof Data_Ordering.LT) {
                return true;
            };
            return false;
        };
    };
};
var lessThanOrEq = function (dictOrd) {
    return function (a1) {
        return function (a2) {
            var v = compare(dictOrd)(a1)(a2);
            if (v instanceof Data_Ordering.GT) {
                return false;
            };
            return true;
        };
    };
};
var max = function (dictOrd) {
    return function (x) {
        return function (y) {
            var v = compare(dictOrd)(x)(y);
            if (v instanceof Data_Ordering.LT) {
                return y;
            };
            if (v instanceof Data_Ordering.EQ) {
                return x;
            };
            if (v instanceof Data_Ordering.GT) {
                return x;
            };
            throw new Error("Failed pattern match at Data.Ord (line 176, column 3 - line 179, column 12): " + [ v.constructor.name ]);
        };
    };
};
var min = function (dictOrd) {
    return function (x) {
        return function (y) {
            var v = compare(dictOrd)(x)(y);
            if (v instanceof Data_Ordering.LT) {
                return x;
            };
            if (v instanceof Data_Ordering.EQ) {
                return x;
            };
            if (v instanceof Data_Ordering.GT) {
                return y;
            };
            throw new Error("Failed pattern match at Data.Ord (line 167, column 3 - line 170, column 12): " + [ v.constructor.name ]);
        };
    };
};
var ordArray = function (dictOrd) {
    return {
        compare: (function () {
            var toDelta = function (x) {
                return function (y) {
                    var v = compare(dictOrd)(x)(y);
                    if (v instanceof Data_Ordering.EQ) {
                        return 0;
                    };
                    if (v instanceof Data_Ordering.LT) {
                        return 1;
                    };
                    if (v instanceof Data_Ordering.GT) {
                        return -1 | 0;
                    };
                    throw new Error("Failed pattern match at Data.Ord (line 74, column 7 - line 77, column 17): " + [ v.constructor.name ]);
                };
            };
            return function (xs) {
                return function (ys) {
                    return compare(ordInt)(0)($foreign.ordArrayImpl(toDelta)(xs)(ys));
                };
            };
        })(),
        Eq0: function () {
            return Data_Eq.eqArray(dictOrd.Eq0());
        }
    };
};
var ord1Array = {
    compare1: function (dictOrd) {
        return compare(ordArray(dictOrd));
    },
    Eq10: function () {
        return Data_Eq.eq1Array;
    }
};
var ordRecordCons = function (dictOrdRecord) {
    return function (dictCons) {
        return function (dictIsSymbol) {
            return function (dictOrd) {
                return {
                    compareRecord: function (v) {
                        return function (ra) {
                            return function (rb) {
                                var key = Data_Symbol.reflectSymbol(dictIsSymbol)(Type_Proxy["Proxy"].value);
                                var left = compare(dictOrd)(Record_Unsafe.unsafeGet(key)(ra))(Record_Unsafe.unsafeGet(key)(rb));
                                var $58 = Data_Eq.notEq(Data_Ordering.eqOrdering)(left)(Data_Ordering.EQ.value);
                                if ($58) {
                                    return left;
                                };
                                return compareRecord(dictOrdRecord)(Type_Proxy["Proxy"].value)(ra)(rb);
                            };
                        };
                    },
                    EqRecord0: function () {
                        return Data_Eq.eqRowCons(dictOrdRecord.EqRecord0())()(dictIsSymbol)(dictOrd.Eq0());
                    }
                };
            };
        };
    };
};
var clamp = function (dictOrd) {
    return function (low) {
        return function (hi) {
            return function (x) {
                return min(dictOrd)(hi)(max(dictOrd)(low)(x));
            };
        };
    };
};
var between = function (dictOrd) {
    return function (low) {
        return function (hi) {
            return function (x) {
                if (lessThan(dictOrd)(x)(low)) {
                    return false;
                };
                if (greaterThan(dictOrd)(x)(hi)) {
                    return false;
                };
                return true;
            };
        };
    };
};
var abs = function (dictOrd) {
    return function (dictRing) {
        return function (x) {
            var $62 = greaterThanOrEq(dictOrd)(x)(Data_Semiring.zero(dictRing.Semiring0()));
            if ($62) {
                return x;
            };
            return Data_Ring.negate(dictRing)(x);
        };
    };
};
module.exports = {
    compare: compare,
    compare1: compare1,
    lessThan: lessThan,
    lessThanOrEq: lessThanOrEq,
    greaterThan: greaterThan,
    greaterThanOrEq: greaterThanOrEq,
    comparing: comparing,
    min: min,
    max: max,
    clamp: clamp,
    between: between,
    abs: abs,
    signum: signum,
    compareRecord: compareRecord,
    ordBoolean: ordBoolean,
    ordInt: ordInt,
    ordNumber: ordNumber,
    ordString: ordString,
    ordChar: ordChar,
    ordUnit: ordUnit,
    ordVoid: ordVoid,
    ordProxy: ordProxy,
    ordProxy2: ordProxy2,
    ordProxy3: ordProxy3,
    ordArray: ordArray,
    ordOrdering: ordOrdering,
    ord1Array: ord1Array,
    ordRecordNil: ordRecordNil,
    ordRecordCons: ordRecordCons,
    ordRecord: ordRecord,
    EQ: Data_Ordering.EQ,
    GT: Data_Ordering.GT,
    LT: Data_Ordering.LT
};
