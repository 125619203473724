// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad = require("../Control.Monad/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Control_Monad_Rec_Class = require("../Control.Monad.Rec.Class/index.js");
var Control_Parallel = require("../Control.Parallel/index.js");
var Control_Parallel_Class = require("../Control.Parallel.Class/index.js");
var Control_Plus = require("../Control.Plus/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Time_Duration = require("../Data.Time.Duration/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var Partial_Unsafe = require("../Partial.Unsafe/index.js");
var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");
var Fiber = function (x) {
    return x;
};
var FFIUtil = function (x) {
    return x;
};
var Canceler = function (x) {
    return x;
};
var suspendAff = $foreign["_fork"](false);
var newtypeCanceler = {
    Coercible0: function () {
        return undefined;
    }
};
var functorParAff = {
    map: $foreign["_parAffMap"]
};
var functorAff = {
    map: $foreign["_map"]
};
var forkAff = $foreign["_fork"](true);
var ffiUtil = (function () {
    var unsafeFromRight = function (v) {
        if (v instanceof Data_Either.Right) {
            return v.value0;
        };
        if (v instanceof Data_Either.Left) {
            return Partial_Unsafe.unsafeCrashWith("unsafeFromRight: Left");
        };
        throw new Error("Failed pattern match at Effect.Aff (line 404, column 21 - line 406, column 54): " + [ v.constructor.name ]);
    };
    var unsafeFromLeft = function (v) {
        if (v instanceof Data_Either.Left) {
            return v.value0;
        };
        if (v instanceof Data_Either.Right) {
            return Partial_Unsafe.unsafeCrashWith("unsafeFromLeft: Right");
        };
        throw new Error("Failed pattern match at Effect.Aff (line 399, column 20 - line 401, column 54): " + [ v.constructor.name ]);
    };
    var isLeft = function (v) {
        if (v instanceof Data_Either.Left) {
            return true;
        };
        if (v instanceof Data_Either.Right) {
            return false;
        };
        throw new Error("Failed pattern match at Effect.Aff (line 394, column 12 - line 396, column 20): " + [ v.constructor.name ]);
    };
    return {
        isLeft: isLeft,
        fromLeft: unsafeFromLeft,
        fromRight: unsafeFromRight,
        left: Data_Either.Left.create,
        right: Data_Either.Right.create
    };
})();
var makeFiber = function (aff) {
    return $foreign["_makeFiber"](ffiUtil, aff);
};
var launchAff = function (aff) {
    return function __do() {
        var fiber = makeFiber(aff)();
        fiber.run();
        return fiber;
    };
};
var launchAff_ = (function () {
    var $40 = Data_Functor["void"](Effect.functorEffect);
    return function ($41) {
        return $40(launchAff($41));
    };
})();
var launchSuspendedAff = makeFiber;
var delay = function (v) {
    return $foreign["_delay"](Data_Either.Right.create, v);
};
var bracket = function (acquire) {
    return function (completed) {
        return $foreign.generalBracket(acquire)({
            killed: Data_Function["const"](completed),
            failed: Data_Function["const"](completed),
            completed: Data_Function["const"](completed)
        });
    };
};
var applyParAff = {
    apply: $foreign["_parAffApply"],
    Functor0: function () {
        return functorParAff;
    }
};
var semigroupParAff = function (dictSemigroup) {
    return {
        append: Control_Apply.lift2(applyParAff)(Data_Semigroup.append(dictSemigroup))
    };
};
var monadAff = {
    Applicative0: function () {
        return applicativeAff;
    },
    Bind1: function () {
        return bindAff;
    }
};
var bindAff = {
    bind: $foreign["_bind"],
    Apply0: function () {
        return applyAff;
    }
};
var applyAff = {
    apply: Control_Monad.ap(monadAff),
    Functor0: function () {
        return functorAff;
    }
};
var applicativeAff = {
    pure: $foreign["_pure"],
    Apply0: function () {
        return applyAff;
    }
};
var cancelWith = function (aff) {
    return function (v) {
        return $foreign.generalBracket(Control_Applicative.pure(applicativeAff)(Data_Unit.unit))({
            killed: function (e) {
                return function (v1) {
                    return v(e);
                };
            },
            failed: Data_Function["const"](Control_Applicative.pure(applicativeAff)),
            completed: Data_Function["const"](Control_Applicative.pure(applicativeAff))
        })(Data_Function["const"](aff));
    };
};
var $$finally = function (fin) {
    return function (a) {
        return bracket(Control_Applicative.pure(applicativeAff)(Data_Unit.unit))(Data_Function["const"](fin))(Data_Function["const"](a));
    };
};
var invincible = function (a) {
    return bracket(a)(Data_Function["const"](Control_Applicative.pure(applicativeAff)(Data_Unit.unit)))(Control_Applicative.pure(applicativeAff));
};
var lazyAff = {
    defer: function (f) {
        return Control_Bind.bind(bindAff)(Control_Applicative.pure(applicativeAff)(Data_Unit.unit))(f);
    }
};
var semigroupAff = function (dictSemigroup) {
    return {
        append: Control_Apply.lift2(applyAff)(Data_Semigroup.append(dictSemigroup))
    };
};
var monadEffectAff = {
    liftEffect: $foreign["_liftEffect"],
    Monad0: function () {
        return monadAff;
    }
};
var effectCanceler = (function () {
    var $42 = Effect_Class.liftEffect(monadEffectAff);
    return function ($43) {
        return Canceler(Data_Function["const"]($42($43)));
    };
})();
var joinFiber = function (v) {
    return $foreign.makeAff(function (k) {
        return Data_Functor.map(Effect.functorEffect)(effectCanceler)(v.join(k));
    });
};
var functorFiber = {
    map: function (f) {
        return function (t) {
            return Effect_Unsafe.unsafePerformEffect(makeFiber(Data_Functor.map(functorAff)(f)(joinFiber(t))));
        };
    }
};
var applyFiber = {
    apply: function (t1) {
        return function (t2) {
            return Effect_Unsafe.unsafePerformEffect(makeFiber(Control_Apply.apply(applyAff)(joinFiber(t1))(joinFiber(t2))));
        };
    },
    Functor0: function () {
        return functorFiber;
    }
};
var applicativeFiber = {
    pure: function (a) {
        return Effect_Unsafe.unsafePerformEffect(makeFiber(Control_Applicative.pure(applicativeAff)(a)));
    },
    Apply0: function () {
        return applyFiber;
    }
};
var killFiber = function (e) {
    return function (v) {
        return Control_Bind.bind(bindAff)(Effect_Class.liftEffect(monadEffectAff)(v.isSuspended))(function (v1) {
            if (v1) {
                return Effect_Class.liftEffect(monadEffectAff)(Data_Functor["void"](Effect.functorEffect)(v.kill(e, Data_Function["const"](Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)))));
            };
            return $foreign.makeAff(function (k) {
                return Data_Functor.map(Effect.functorEffect)(effectCanceler)(v.kill(e, k));
            });
        });
    };
};
var fiberCanceler = (function () {
    var $44 = Data_Function.flip(killFiber);
    return function ($45) {
        return Canceler($44($45));
    };
})();
var monadThrowAff = {
    throwError: $foreign["_throwError"],
    Monad0: function () {
        return monadAff;
    }
};
var monadErrorAff = {
    catchError: $foreign["_catchError"],
    MonadThrow0: function () {
        return monadThrowAff;
    }
};
var attempt = Control_Monad_Error_Class["try"](monadErrorAff);
var runAff = function (k) {
    return function (aff) {
        return launchAff(Control_Bind.bindFlipped(bindAff)((function () {
            var $46 = Effect_Class.liftEffect(monadEffectAff);
            return function ($47) {
                return $46(k($47));
            };
        })())(Control_Monad_Error_Class["try"](monadErrorAff)(aff)));
    };
};
var runAff_ = function (k) {
    return function (aff) {
        return Data_Functor["void"](Effect.functorEffect)(runAff(k)(aff));
    };
};
var runSuspendedAff = function (k) {
    return function (aff) {
        return launchSuspendedAff(Control_Bind.bindFlipped(bindAff)((function () {
            var $48 = Effect_Class.liftEffect(monadEffectAff);
            return function ($49) {
                return $48(k($49));
            };
        })())(Control_Monad_Error_Class["try"](monadErrorAff)(aff)));
    };
};
var parallelAff = {
    parallel: Unsafe_Coerce.unsafeCoerce,
    sequential: $foreign["_sequential"],
    Monad0: function () {
        return monadAff;
    },
    Applicative1: function () {
        return applicativeParAff;
    }
};
var applicativeParAff = {
    pure: (function () {
        var $50 = Control_Parallel_Class.parallel(parallelAff);
        var $51 = Control_Applicative.pure(applicativeAff);
        return function ($52) {
            return $50($51($52));
        };
    })(),
    Apply0: function () {
        return applyParAff;
    }
};
var monoidParAff = function (dictMonoid) {
    return {
        mempty: Control_Applicative.pure(applicativeParAff)(Data_Monoid.mempty(dictMonoid)),
        Semigroup0: function () {
            return semigroupParAff(dictMonoid.Semigroup0());
        }
    };
};
var semigroupCanceler = {
    append: function (v) {
        return function (v1) {
            return function (err) {
                return Control_Parallel.parSequence_(parallelAff)(Data_Foldable.foldableArray)([ v(err), v1(err) ]);
            };
        };
    }
};
var supervise = function (aff) {
    var killError = Effect_Exception.error("[Aff] Child fiber outlived parent");
    var killAll = function (err) {
        return function (sup) {
            return $foreign.makeAff(function (k) {
                return $foreign["_killAll"](err, sup.supervisor, k(Control_Applicative.pure(Data_Either.applicativeEither)(Data_Unit.unit)));
            });
        };
    };
    var acquire = function __do() {
        var sup = $foreign["_makeSupervisedFiber"](ffiUtil, aff)();
        sup.fiber.run();
        return sup;
    };
    return $foreign.generalBracket(Effect_Class.liftEffect(monadEffectAff)(acquire))({
        killed: function (err) {
            return function (sup) {
                return Control_Parallel.parSequence_(parallelAff)(Data_Foldable.foldableArray)([ killFiber(err)(sup.fiber), killAll(err)(sup) ]);
            };
        },
        failed: Data_Function["const"](killAll(killError)),
        completed: Data_Function["const"](killAll(killError))
    })(function ($53) {
        return joinFiber((function (v) {
            return v.fiber;
        })($53));
    });
};
var monadRecAff = {
    tailRecM: function (k) {
        var go = function (a) {
            return Control_Bind.bind(bindAff)(k(a))(function (res) {
                if (res instanceof Control_Monad_Rec_Class.Done) {
                    return Control_Applicative.pure(applicativeAff)(res.value0);
                };
                if (res instanceof Control_Monad_Rec_Class.Loop) {
                    return go(res.value0);
                };
                throw new Error("Failed pattern match at Effect.Aff (line 102, column 7 - line 104, column 22): " + [ res.constructor.name ]);
            });
        };
        return go;
    },
    Monad0: function () {
        return monadAff;
    }
};
var monoidAff = function (dictMonoid) {
    return {
        mempty: Control_Applicative.pure(applicativeAff)(Data_Monoid.mempty(dictMonoid)),
        Semigroup0: function () {
            return semigroupAff(dictMonoid.Semigroup0());
        }
    };
};
var nonCanceler = Data_Function["const"](Control_Applicative.pure(applicativeAff)(Data_Unit.unit));
var monoidCanceler = {
    mempty: nonCanceler,
    Semigroup0: function () {
        return semigroupCanceler;
    }
};
var never = $foreign.makeAff(function (v) {
    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.mempty(monoidCanceler));
});
var apathize = (function () {
    var $54 = Data_Functor.map(functorAff)(Data_Function["const"](Data_Unit.unit));
    return function ($55) {
        return $54(attempt($55));
    };
})();
var altParAff = {
    alt: $foreign["_parAffAlt"],
    Functor0: function () {
        return functorParAff;
    }
};
var altAff = {
    alt: function (a1) {
        return function (a2) {
            return Control_Monad_Error_Class.catchError(monadErrorAff)(a1)(Data_Function["const"](a2));
        };
    },
    Functor0: function () {
        return functorAff;
    }
};
var plusAff = {
    empty: Control_Monad_Error_Class.throwError(monadThrowAff)(Effect_Exception.error("Always fails")),
    Alt0: function () {
        return altAff;
    }
};
var plusParAff = {
    empty: Control_Parallel_Class.parallel(parallelAff)(Control_Plus.empty(plusAff)),
    Alt0: function () {
        return altParAff;
    }
};
var alternativeParAff = {
    Applicative0: function () {
        return applicativeParAff;
    },
    Plus1: function () {
        return plusParAff;
    }
};
module.exports = {
    Canceler: Canceler,
    launchAff: launchAff,
    launchAff_: launchAff_,
    launchSuspendedAff: launchSuspendedAff,
    runAff: runAff,
    runAff_: runAff_,
    runSuspendedAff: runSuspendedAff,
    forkAff: forkAff,
    suspendAff: suspendAff,
    supervise: supervise,
    attempt: attempt,
    apathize: apathize,
    delay: delay,
    never: never,
    "finally": $$finally,
    invincible: invincible,
    killFiber: killFiber,
    joinFiber: joinFiber,
    cancelWith: cancelWith,
    bracket: bracket,
    nonCanceler: nonCanceler,
    effectCanceler: effectCanceler,
    fiberCanceler: fiberCanceler,
    functorAff: functorAff,
    applyAff: applyAff,
    applicativeAff: applicativeAff,
    bindAff: bindAff,
    monadAff: monadAff,
    semigroupAff: semigroupAff,
    monoidAff: monoidAff,
    altAff: altAff,
    plusAff: plusAff,
    monadRecAff: monadRecAff,
    monadThrowAff: monadThrowAff,
    monadErrorAff: monadErrorAff,
    monadEffectAff: monadEffectAff,
    lazyAff: lazyAff,
    functorParAff: functorParAff,
    applyParAff: applyParAff,
    applicativeParAff: applicativeParAff,
    semigroupParAff: semigroupParAff,
    monoidParAff: monoidParAff,
    altParAff: altParAff,
    plusParAff: plusParAff,
    alternativeParAff: alternativeParAff,
    parallelAff: parallelAff,
    functorFiber: functorFiber,
    applyFiber: applyFiber,
    applicativeFiber: applicativeFiber,
    newtypeCanceler: newtypeCanceler,
    semigroupCanceler: semigroupCanceler,
    monoidCanceler: monoidCanceler,
    makeAff: $foreign.makeAff,
    generalBracket: $foreign.generalBracket,
    catchError: Control_Monad_Error_Class.catchError,
    throwError: Control_Monad_Error_Class.throwError,
    "try": Control_Monad_Error_Class["try"],
    parallel: Control_Parallel_Class.parallel,
    sequential: Control_Parallel_Class.sequential,
    Milliseconds: Data_Time_Duration.Milliseconds,
    error: Effect_Exception.error,
    message: Effect_Exception.message
};
