// Generated by purs version 0.14.5
"use strict";
var unsecond = function (dict) {
    return dict.unsecond;
};
var unfirst = function (dict) {
    return dict.unfirst;
};
module.exports = {
    unfirst: unfirst,
    unsecond: unsecond
};
