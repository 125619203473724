// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Bounded = require("../Data.Bounded/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Number = require("../Data.Number/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var $$Math = require("../Math/index.js");
var Radix = function (x) {
    return x;
};
var Even = (function () {
    function Even() {

    };
    Even.value = new Even();
    return Even;
})();
var Odd = (function () {
    function Odd() {

    };
    Odd.value = new Odd();
    return Odd;
})();
var showParity = {
    show: function (v) {
        if (v instanceof Even) {
            return "Even";
        };
        if (v instanceof Odd) {
            return "Odd";
        };
        throw new Error("Failed pattern match at Data.Int (line 111, column 1 - line 113, column 19): " + [ v.constructor.name ]);
    }
};
var radix = function (n) {
    if (n >= 2 && n <= 36) {
        return new Data_Maybe.Just(n);
    };
    if (Data_Boolean.otherwise) {
        return Data_Maybe.Nothing.value;
    };
    throw new Error("Failed pattern match at Data.Int (line 192, column 1 - line 192, column 28): " + [ n.constructor.name ]);
};
var odd = function (x) {
    return (x & 1) !== 0;
};
var octal = 8;
var hexadecimal = 16;
var fromStringAs = $foreign.fromStringAsImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
var fromString = fromStringAs(10);
var fromNumber = $foreign.fromNumberImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
var unsafeClamp = function (x) {
    if (!Data_Number["isFinite"](x)) {
        return 0;
    };
    if (x >= $foreign.toNumber(Data_Bounded.top(Data_Bounded.boundedInt))) {
        return Data_Bounded.top(Data_Bounded.boundedInt);
    };
    if (x <= $foreign.toNumber(Data_Bounded.bottom(Data_Bounded.boundedInt))) {
        return Data_Bounded.bottom(Data_Bounded.boundedInt);
    };
    if (Data_Boolean.otherwise) {
        return Data_Maybe.fromMaybe(0)(fromNumber(x));
    };
    throw new Error("Failed pattern match at Data.Int (line 66, column 1 - line 66, column 29): " + [ x.constructor.name ]);
};
var round = function ($23) {
    return unsafeClamp($$Math.round($23));
};
var floor = function ($24) {
    return unsafeClamp($$Math.floor($24));
};
var even = function (x) {
    return (x & 1) === 0;
};
var parity = function (n) {
    var $14 = even(n);
    if ($14) {
        return Even.value;
    };
    return Odd.value;
};
var eqParity = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Even && y instanceof Even) {
                return true;
            };
            if (x instanceof Odd && y instanceof Odd) {
                return true;
            };
            return false;
        };
    }
};
var ordParity = {
    compare: function (x) {
        return function (y) {
            if (x instanceof Even && y instanceof Even) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Even) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Even) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Odd && y instanceof Odd) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at Data.Int (line 109, column 1 - line 109, column 40): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqParity;
    }
};
var semiringParity = {
    zero: Even.value,
    add: function (x) {
        return function (y) {
            var $19 = Data_Eq.eq(eqParity)(x)(y);
            if ($19) {
                return Even.value;
            };
            return Odd.value;
        };
    },
    one: Odd.value,
    mul: function (v) {
        return function (v1) {
            if (v instanceof Odd && v1 instanceof Odd) {
                return Odd.value;
            };
            return Even.value;
        };
    }
};
var ringParity = {
    sub: Data_Semiring.add(semiringParity),
    Semiring0: function () {
        return semiringParity;
    }
};
var divisionRingParity = {
    recip: Control_Category.identity(Control_Category.categoryFn),
    Ring0: function () {
        return ringParity;
    }
};
var decimal = 10;
var commutativeRingParity = {
    Ring0: function () {
        return ringParity;
    }
};
var euclideanRingParity = {
    degree: function (v) {
        if (v instanceof Even) {
            return 0;
        };
        if (v instanceof Odd) {
            return 1;
        };
        throw new Error("Failed pattern match at Data.Int (line 131, column 1 - line 135, column 17): " + [ v.constructor.name ]);
    },
    div: function (x) {
        return function (v) {
            return x;
        };
    },
    mod: function (v) {
        return function (v1) {
            return Even.value;
        };
    },
    CommutativeRing0: function () {
        return commutativeRingParity;
    }
};
var ceil = function ($25) {
    return unsafeClamp($$Math.ceil($25));
};
var boundedParity = {
    bottom: Even.value,
    top: Odd.value,
    Ord0: function () {
        return ordParity;
    }
};
var binary = 2;
var base36 = 36;
module.exports = {
    fromNumber: fromNumber,
    ceil: ceil,
    floor: floor,
    round: round,
    fromString: fromString,
    radix: radix,
    binary: binary,
    octal: octal,
    decimal: decimal,
    hexadecimal: hexadecimal,
    base36: base36,
    fromStringAs: fromStringAs,
    Even: Even,
    Odd: Odd,
    parity: parity,
    even: even,
    odd: odd,
    eqParity: eqParity,
    ordParity: ordParity,
    showParity: showParity,
    boundedParity: boundedParity,
    semiringParity: semiringParity,
    ringParity: ringParity,
    commutativeRingParity: commutativeRingParity,
    euclideanRingParity: euclideanRingParity,
    divisionRingParity: divisionRingParity,
    toNumber: $foreign.toNumber,
    toStringAs: $foreign.toStringAs,
    quot: $foreign.quot,
    rem: $foreign.rem,
    pow: $foreign.pow
};
