// Generated by purs version 0.14.5
"use strict";
var Data_Functor = require("../Data.Functor/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var AcceptMediaType = (function () {
    function AcceptMediaType(value0) {
        this.value0 = value0;
    };
    AcceptMediaType.create = function (value0) {
        return new AcceptMediaType(value0);
    };
    return AcceptMediaType;
})();
var AcceptFileExtension = (function () {
    function AcceptFileExtension(value0) {
        this.value0 = value0;
    };
    AcceptFileExtension.create = function (value0) {
        return new AcceptFileExtension(value0);
    };
    return AcceptFileExtension;
})();
var InputAcceptType = function (x) {
    return x;
};
var semigroupInputAcceptType = {
    append: function (v) {
        return function (v1) {
            return Data_Semigroup.append(Data_Semigroup.semigroupArray)(v)(v1);
        };
    }
};
var renderInputAcceptTypeAtom = function (v) {
    if (v instanceof AcceptMediaType) {
        return v.value0;
    };
    if (v instanceof AcceptFileExtension) {
        return v.value0;
    };
    throw new Error("Failed pattern match at DOM.HTML.Indexed.InputAcceptType (line 28, column 29 - line 30, column 33): " + [ v.constructor.name ]);
};
var renderInputAcceptType = function (v) {
    return Data_String_Common.joinWith(",")(Data_Functor.map(Data_Functor.functorArray)(renderInputAcceptTypeAtom)(v));
};
var mediaType = function (mt) {
    return [ new AcceptMediaType(mt) ];
};
var extension = function (ext) {
    return [ new AcceptFileExtension(ext) ];
};
module.exports = {
    InputAcceptType: InputAcceptType,
    mediaType: mediaType,
    extension: extension,
    AcceptMediaType: AcceptMediaType,
    AcceptFileExtension: AcceptFileExtension,
    renderInputAcceptType: renderInputAcceptType,
    renderInputAcceptTypeAtom: renderInputAcceptTypeAtom,
    semigroupInputAcceptType: semigroupInputAcceptType
};
