// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
module.exports = {
    hash: $foreign.hash,
    setHash: $foreign.setHash,
    host: $foreign.host,
    setHost: $foreign.setHost,
    hostname: $foreign.hostname,
    setHostname: $foreign.setHostname,
    href: $foreign.href,
    setHref: $foreign.setHref,
    origin: $foreign.origin,
    setOrigin: $foreign.setOrigin,
    pathname: $foreign.pathname,
    setPathname: $foreign.setPathname,
    port: $foreign.port,
    setPort: $foreign.setPort,
    protocol: $foreign.protocol,
    setProtocol: $foreign.setProtocol,
    search: $foreign.search,
    setSearch: $foreign.setSearch,
    assign: $foreign.assign,
    replace: $foreign.replace,
    reload: $foreign.reload
};
