// Generated by purs version 0.14.5
"use strict";
var Control_Biapply = require("../Control.Biapply/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var bipure = function (dict) {
    return dict.bipure;
};
var biapplicativeTuple = {
    bipure: Data_Tuple.Tuple.create,
    Biapply0: function () {
        return Control_Biapply.biapplyTuple;
    }
};
module.exports = {
    bipure: bipure,
    biapplicativeTuple: biapplicativeTuple
};
