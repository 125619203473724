// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Record_Unsafe = require("../Record.Unsafe/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var eqVoid = {
    eq: function (v) {
        return function (v1) {
            return true;
        };
    }
};
var eqUnit = {
    eq: function (v) {
        return function (v1) {
            return true;
        };
    }
};
var eqString = {
    eq: $foreign.eqStringImpl
};
var eqRowNil = {
    eqRecord: function (v) {
        return function (v1) {
            return function (v2) {
                return true;
            };
        };
    }
};
var eqRecord = function (dict) {
    return dict.eqRecord;
};
var eqRec = function (dictRowToList) {
    return function (dictEqRecord) {
        return {
            eq: eqRecord(dictEqRecord)(Type_Proxy["Proxy"].value)
        };
    };
};
var eqProxy3 = {
    eq: function (v) {
        return function (v1) {
            return true;
        };
    }
};
var eqProxy2 = {
    eq: function (v) {
        return function (v1) {
            return true;
        };
    }
};
var eqProxy = {
    eq: function (v) {
        return function (v1) {
            return true;
        };
    }
};
var eqNumber = {
    eq: $foreign.eqNumberImpl
};
var eqInt = {
    eq: $foreign.eqIntImpl
};
var eqChar = {
    eq: $foreign.eqCharImpl
};
var eqBoolean = {
    eq: $foreign.eqBooleanImpl
};
var eq1 = function (dict) {
    return dict.eq1;
};
var eq = function (dict) {
    return dict.eq;
};
var eqArray = function (dictEq) {
    return {
        eq: $foreign.eqArrayImpl(eq(dictEq))
    };
};
var eq1Array = {
    eq1: function (dictEq) {
        return eq(eqArray(dictEq));
    }
};
var eqRowCons = function (dictEqRecord) {
    return function (dictCons) {
        return function (dictIsSymbol) {
            return function (dictEq) {
                return {
                    eqRecord: function (v) {
                        return function (ra) {
                            return function (rb) {
                                var tail = eqRecord(dictEqRecord)(Type_Proxy["Proxy"].value)(ra)(rb);
                                var key = Data_Symbol.reflectSymbol(dictIsSymbol)(Type_Proxy["Proxy"].value);
                                var get = Record_Unsafe.unsafeGet(key);
                                return eq(dictEq)(get(ra))(get(rb)) && tail;
                            };
                        };
                    }
                };
            };
        };
    };
};
var notEq = function (dictEq) {
    return function (x) {
        return function (y) {
            return eq(eqBoolean)(eq(dictEq)(x)(y))(false);
        };
    };
};
var notEq1 = function (dictEq1) {
    return function (dictEq) {
        return function (x) {
            return function (y) {
                return eq(eqBoolean)(eq1(dictEq1)(dictEq)(x)(y))(false);
            };
        };
    };
};
module.exports = {
    eq: eq,
    notEq: notEq,
    eq1: eq1,
    notEq1: notEq1,
    eqRecord: eqRecord,
    eqBoolean: eqBoolean,
    eqInt: eqInt,
    eqNumber: eqNumber,
    eqChar: eqChar,
    eqString: eqString,
    eqUnit: eqUnit,
    eqVoid: eqVoid,
    eqArray: eqArray,
    eqRec: eqRec,
    eqProxy: eqProxy,
    eqProxy2: eqProxy2,
    eqProxy3: eqProxy3,
    eq1Array: eq1Array,
    eqRowNil: eqRowNil,
    eqRowCons: eqRowCons
};
