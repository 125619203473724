// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Effect = require("../Effect/index.js");
var Effect_Ref = require("../Effect.Ref/index.js");
var Routing = require("../Routing/index.js");
var Web_Event_EventTarget = require("../Web.Event.EventTarget/index.js");
var Web_HTML = require("../Web.HTML/index.js");
var Web_HTML_Event_HashChangeEvent_EventTypes = require("../Web.HTML.Event.HashChangeEvent.EventTypes/index.js");
var Web_HTML_Location = require("../Web.HTML.Location/index.js");
var Web_HTML_Window = require("../Web.HTML.Window/index.js");
var setHash = function (h) {
    return Control_Bind.bind(Effect.bindEffect)(Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.location))(Web_HTML_Location.setHash(h));
};
var getHash = Control_Bind.bind(Effect.bindEffect)(Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.location))((function () {
    var $2 = Data_Functor.map(Effect.functorEffect)((function () {
        var $4 = Data_Maybe.fromMaybe("");
        var $5 = Data_String_CodeUnits.stripPrefix("#");
        return function ($6) {
            return $4($5($6));
        };
    })());
    return function ($3) {
        return $2(Web_HTML_Location.hash($3));
    };
})());
var modifyHash = function (fn) {
    return Control_Bind.bind(Effect.bindEffect)(Data_Functor.map(Effect.functorEffect)(fn)(getHash))(setHash);
};
var foldHashes = function (cb) {
    return function (init) {
        return function __do() {
            var ref = Control_Bind.bindFlipped(Effect.bindEffect)(Effect_Ref["new"])(Control_Bind.bindFlipped(Effect.bindEffect)(init)(getHash))();
            var win = Data_Functor.map(Effect.functorEffect)(Web_HTML_Window.toEventTarget)(Web_HTML.window)();
            var listener = Web_Event_EventTarget.eventListener(function (v) {
                return Control_Bind.bindFlipped(Effect.bindEffect)(Data_Function.flip(Effect_Ref.write)(ref))(Control_Bind.join(Effect.bindEffect)(Control_Apply.apply(Effect.applyEffect)(Data_Functor.map(Effect.functorEffect)(cb)(Effect_Ref.read(ref)))(getHash)));
            })();
            Web_Event_EventTarget.addEventListener(Web_HTML_Event_HashChangeEvent_EventTypes.hashchange)(listener)(false)(win)();
            return Web_Event_EventTarget.removeEventListener(Web_HTML_Event_HashChangeEvent_EventTypes.hashchange)(listener)(false)(win);
        };
    };
};
var matchesWith = function (dictFoldable) {
    return function (parser) {
        return function (cb) {
            var go = function (a) {
                var $7 = Data_Maybe.maybe(Control_Applicative.pure(Effect.applicativeEffect)(a))(function (b) {
                    return Data_Functor.voidRight(Effect.functorEffect)(new Data_Maybe.Just(b))(cb(a)(b));
                });
                var $8 = Data_Foldable.indexl(dictFoldable)(0);
                return function ($9) {
                    return $7($8(parser($9)));
                };
            };
            return foldHashes(go)(go(Data_Maybe.Nothing.value));
        };
    };
};
var hashes = matchesWith(Data_Foldable.foldableMaybe)(Data_Maybe.Just.create);
var matches = (function () {
    var $10 = matchesWith(Data_Foldable.foldableEither);
    return function ($11) {
        return $10(Routing.match($11));
    };
})();
module.exports = {
    getHash: getHash,
    setHash: setHash,
    modifyHash: modifyHash,
    foldHashes: foldHashes,
    hashes: hashes,
    matches: matches,
    matchesWith: matchesWith,
    match: Routing.match,
    matchWith: Routing.matchWith
};
