// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
module.exports = {
    language: $foreign.language,
    languages: $foreign.languages,
    onLine: $foreign.onLine,
    platform: $foreign.platform,
    userAgent: $foreign.userAgent
};
