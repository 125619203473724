// Generated by purs version 0.14.5
"use strict";
var Affjax = require("../Affjax/index.js");
var Affjax_ResponseFormat = require("../Affjax.ResponseFormat/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Data_Argonaut_Decode_Class = require("../Data.Argonaut.Decode.Class/index.js");
var Data_Argonaut_Decode_Error = require("../Data.Argonaut.Decode.Error/index.js");
var Data_Bifunctor = require("../Data.Bifunctor/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var itemsById = function (ids) {
    var go = function (acc) {
        return function (nextPageHref) {
            var url = "https://api.osrsbox.com/" + nextPageHref;
            return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff))(Control_Monad_Except_Trans.ExceptT(Data_Functor.map(Effect_Aff.functorAff)(Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(Affjax.printError))(Affjax.get(Affjax_ResponseFormat.json)(url))))(function (response) {
                return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff))(Control_Monad_Except_Trans.except(Effect_Aff.applicativeAff)(Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(Data_Argonaut_Decode_Error.printJsonDecodeError)(Data_Argonaut_Decode_Class.decodeJson(Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeArray(Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonBoolean))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldMaybe(Data_Argonaut_Decode_Class.decodeJsonInt))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldMaybe(Data_Argonaut_Decode_Class.decodeJsonInt))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonBoolean))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonBoolean))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonBoolean))(Data_Argonaut_Decode_Class.gDecodeJsonNil)({
                    reflectSymbol: function () {
                        return "stackable";
                    }
                })()())({
                    reflectSymbol: function () {
                        return "noted";
                    }
                })()())({
                    reflectSymbol: function () {
                        return "noteable";
                    }
                })()())({
                    reflectSymbol: function () {
                        return "name";
                    }
                })()())({
                    reflectSymbol: function () {
                        return "linked_id_noted";
                    }
                })()())({
                    reflectSymbol: function () {
                        return "linked_id_item";
                    }
                })()())({
                    reflectSymbol: function () {
                        return "id";
                    }
                })()())({
                    reflectSymbol: function () {
                        return "icon";
                    }
                })()())({
                    reflectSymbol: function () {
                        return "duplicate";
                    }
                })()())())))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldMaybe(Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString))(Data_Argonaut_Decode_Class.gDecodeJsonNil)({
                    reflectSymbol: function () {
                        return "title";
                    }
                })()())({
                    reflectSymbol: function () {
                        return "href";
                    }
                })()())()))(Data_Argonaut_Decode_Class.gDecodeJsonNil)({
                    reflectSymbol: function () {
                        return "next";
                    }
                })()())()))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonInt))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonInt))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonInt))(Data_Argonaut_Decode_Class.gDecodeJsonNil)({
                    reflectSymbol: function () {
                        return "total";
                    }
                })()())({
                    reflectSymbol: function () {
                        return "page";
                    }
                })()())({
                    reflectSymbol: function () {
                        return "max_results";
                    }
                })()())()))(Data_Argonaut_Decode_Class.gDecodeJsonNil)({
                    reflectSymbol: function () {
                        return "_meta";
                    }
                })()())({
                    reflectSymbol: function () {
                        return "_links";
                    }
                })()())({
                    reflectSymbol: function () {
                        return "_items";
                    }
                })()())())(response.body))))(function (itemsResponse) {
                    if (itemsResponse["_links"].next instanceof Data_Maybe.Nothing) {
                        return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Effect_Aff.monadAff))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(acc)(itemsResponse["_items"]));
                    };
                    if (itemsResponse["_links"].next instanceof Data_Maybe.Just) {
                        return go(Data_Semigroup.append(Data_Semigroup.semigroupArray)(acc)(itemsResponse["_items"]))(itemsResponse["_links"].next.value0.href);
                    };
                    throw new Error("Failed pattern match at OsrsBox.Api (line 66, column 5 - line 68, column 56): " + [ itemsResponse["_links"].next.constructor.name ]);
                });
            });
        };
    };
    return Control_Monad_Except_Trans.runExceptT((function () {
        var url = "https://api.osrsbox.com/items?where={\"id\":{\"$in\":" + (Data_Show.show(Data_Show.showArray(Data_Show.showString))(Data_Functor.map(Data_Functor.functorArray)(Data_Show.show(Data_Show.showInt))(ids)) + "}}");
        return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff))(Control_Monad_Except_Trans.ExceptT(Data_Functor.map(Effect_Aff.functorAff)(Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(Affjax.printError))(Affjax.get(Affjax_ResponseFormat.json)(url))))(function (response) {
            return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff))(Control_Monad_Except_Trans.except(Effect_Aff.applicativeAff)(Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(Data_Argonaut_Decode_Error.printJsonDecodeError)(Data_Argonaut_Decode_Class.decodeJson(Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeArray(Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonBoolean))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldMaybe(Data_Argonaut_Decode_Class.decodeJsonInt))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldMaybe(Data_Argonaut_Decode_Class.decodeJsonInt))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonBoolean))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonBoolean))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonBoolean))(Data_Argonaut_Decode_Class.gDecodeJsonNil)({
                reflectSymbol: function () {
                    return "stackable";
                }
            })()())({
                reflectSymbol: function () {
                    return "noted";
                }
            })()())({
                reflectSymbol: function () {
                    return "noteable";
                }
            })()())({
                reflectSymbol: function () {
                    return "name";
                }
            })()())({
                reflectSymbol: function () {
                    return "linked_id_noted";
                }
            })()())({
                reflectSymbol: function () {
                    return "linked_id_item";
                }
            })()())({
                reflectSymbol: function () {
                    return "id";
                }
            })()())({
                reflectSymbol: function () {
                    return "icon";
                }
            })()())({
                reflectSymbol: function () {
                    return "duplicate";
                }
            })()())())))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldMaybe(Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString))(Data_Argonaut_Decode_Class.gDecodeJsonNil)({
                reflectSymbol: function () {
                    return "title";
                }
            })()())({
                reflectSymbol: function () {
                    return "href";
                }
            })()())()))(Data_Argonaut_Decode_Class.gDecodeJsonNil)({
                reflectSymbol: function () {
                    return "next";
                }
            })()())()))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonInt))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonInt))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonInt))(Data_Argonaut_Decode_Class.gDecodeJsonNil)({
                reflectSymbol: function () {
                    return "total";
                }
            })()())({
                reflectSymbol: function () {
                    return "page";
                }
            })()())({
                reflectSymbol: function () {
                    return "max_results";
                }
            })()())()))(Data_Argonaut_Decode_Class.gDecodeJsonNil)({
                reflectSymbol: function () {
                    return "_meta";
                }
            })()())({
                reflectSymbol: function () {
                    return "_links";
                }
            })()())({
                reflectSymbol: function () {
                    return "_items";
                }
            })()())())(response.body))))(function (itemsResponse) {
                if (itemsResponse["_links"].next instanceof Data_Maybe.Nothing) {
                    return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Effect_Aff.monadAff))(itemsResponse["_items"]);
                };
                if (itemsResponse["_links"].next instanceof Data_Maybe.Just) {
                    return go(itemsResponse["_items"])(itemsResponse["_links"].next.value0.href);
                };
                throw new Error("Failed pattern match at OsrsBox.Api (line 54, column 3 - line 56, column 45): " + [ itemsResponse["_links"].next.constructor.name ]);
            });
        });
    })());
};
var capitalize = (function () {
    var $19 = Data_String_CodePoints.splitAt(1);
    return function ($20) {
        return (function (v) {
            return Data_String_Common.toUpper(v.before) + Data_String_Common.toLower(v.after);
        })($19($20));
    };
})();
var items = function (v) {
    return Control_Monad_Except_Trans.runExceptT((function () {
        var url = "https://api.osrsbox.com/items?where={\"name\":" + (Data_Show.show(Data_Show.showString)(capitalize(v.name)) + "}");
        return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff))(Control_Monad_Except_Trans.ExceptT(Data_Functor.map(Effect_Aff.functorAff)(Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(Affjax.printError))(Affjax.get(Affjax_ResponseFormat.json)(url))))(function (response) {
            return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff))(Control_Monad_Except_Trans.except(Effect_Aff.applicativeAff)(Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(Data_Argonaut_Decode_Error.printJsonDecodeError)(Data_Argonaut_Decode_Class.decodeJson(Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeArray(Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonBoolean))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldMaybe(Data_Argonaut_Decode_Class.decodeJsonInt))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldMaybe(Data_Argonaut_Decode_Class.decodeJsonInt))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonBoolean))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonBoolean))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonBoolean))(Data_Argonaut_Decode_Class.gDecodeJsonNil)({
                reflectSymbol: function () {
                    return "stackable";
                }
            })()())({
                reflectSymbol: function () {
                    return "noted";
                }
            })()())({
                reflectSymbol: function () {
                    return "noteable";
                }
            })()())({
                reflectSymbol: function () {
                    return "name";
                }
            })()())({
                reflectSymbol: function () {
                    return "linked_id_noted";
                }
            })()())({
                reflectSymbol: function () {
                    return "linked_id_item";
                }
            })()())({
                reflectSymbol: function () {
                    return "id";
                }
            })()())({
                reflectSymbol: function () {
                    return "icon";
                }
            })()())({
                reflectSymbol: function () {
                    return "duplicate";
                }
            })()())())))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldMaybe(Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString))(Data_Argonaut_Decode_Class.gDecodeJsonNil)({
                reflectSymbol: function () {
                    return "title";
                }
            })()())({
                reflectSymbol: function () {
                    return "href";
                }
            })()())()))(Data_Argonaut_Decode_Class.gDecodeJsonNil)({
                reflectSymbol: function () {
                    return "next";
                }
            })()())()))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonInt))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonInt))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonInt))(Data_Argonaut_Decode_Class.gDecodeJsonNil)({
                reflectSymbol: function () {
                    return "total";
                }
            })()())({
                reflectSymbol: function () {
                    return "page";
                }
            })()())({
                reflectSymbol: function () {
                    return "max_results";
                }
            })()())()))(Data_Argonaut_Decode_Class.gDecodeJsonNil)({
                reflectSymbol: function () {
                    return "_meta";
                }
            })()())({
                reflectSymbol: function () {
                    return "_links";
                }
            })()())({
                reflectSymbol: function () {
                    return "_items";
                }
            })()())())(response.body))))(function (itemsResponse) {
                return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Effect_Aff.monadAff))(itemsResponse["_items"]);
            });
        });
    })());
};
module.exports = {
    capitalize: capitalize,
    items: items,
    itemsById: itemsById
};
