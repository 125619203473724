// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var DOM_HTML_Indexed_InputType = require("../DOM.HTML.Indexed.InputType/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Enum = require("../Data.Enum/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_EuclideanRing = require("../Data.EuclideanRing/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_List = require("../Data.List/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Aff_Class = require("../Effect.Aff.Class/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Class_Console = require("../Effect.Class.Console/index.js");
var Halogen_Aff_Util = require("../Halogen.Aff.Util/index.js");
var Halogen_Component = require("../Halogen.Component/index.js");
var Halogen_HTML_Core = require("../Halogen.HTML.Core/index.js");
var Halogen_HTML_Elements = require("../Halogen.HTML.Elements/index.js");
var Halogen_HTML_Events = require("../Halogen.HTML.Events/index.js");
var Halogen_HTML_Properties = require("../Halogen.HTML.Properties/index.js");
var Halogen_Query_HalogenM = require("../Halogen.Query.HalogenM/index.js");
var Halogen_VDom_Driver = require("../Halogen.VDom.Driver/index.js");
var Network_RemoteData = require("../Network.RemoteData/index.js");
var OsrsBox_Api = require("../OsrsBox.Api/index.js");
var SixtySixItems_Domain_Equipment = require("../SixtySixItems.Domain.Equipment/index.js");
var SixtySixItems_Domain_EquipmentSlot = require("../SixtySixItems.Domain.EquipmentSlot/index.js");
var SixtySixItems_Domain_Inventory = require("../SixtySixItems.Domain.Inventory/index.js");
var SixtySixItems_Domain_InventorySlot = require("../SixtySixItems.Domain.InventorySlot/index.js");
var SixtySixItems_Domain_Item = require("../SixtySixItems.Domain.Item/index.js");
var SixtySixItems_Domain_Storage = require("../SixtySixItems.Domain.Storage/index.js");
var SixtySixItems_SaveData = require("../SixtySixItems.SaveData/index.js");
var Tachyons = require("../Tachyons/index.js");
var Web_HTML_Common = require("../Web.HTML.Common/index.js");
var Initialize = (function () {
    function Initialize() {

    };
    Initialize.value = new Initialize();
    return Initialize;
})();
var SetSearch = (function () {
    function SetSearch(value0) {
        this.value0 = value0;
    };
    SetSearch.create = function (value0) {
        return new SetSearch(value0);
    };
    return SetSearch;
})();
var SetQuantity = (function () {
    function SetQuantity(value0) {
        this.value0 = value0;
    };
    SetQuantity.create = function (value0) {
        return new SetQuantity(value0);
    };
    return SetQuantity;
})();
var SetIsNoted = (function () {
    function SetIsNoted(value0) {
        this.value0 = value0;
    };
    SetIsNoted.create = function (value0) {
        return new SetIsNoted(value0);
    };
    return SetIsNoted;
})();
var LoadItems = (function () {
    function LoadItems() {

    };
    LoadItems.value = new LoadItems();
    return LoadItems;
})();
var LoadItemsById = (function () {
    function LoadItemsById(value0) {
        this.value0 = value0;
    };
    LoadItemsById.create = function (value0) {
        return new LoadItemsById(value0);
    };
    return LoadItemsById;
})();
var SelectSlot = (function () {
    function SelectSlot(value0) {
        this.value0 = value0;
    };
    SelectSlot.create = function (value0) {
        return new SelectSlot(value0);
    };
    return SelectSlot;
})();
var SelectItem = (function () {
    function SelectItem(value0) {
        this.value0 = value0;
    };
    SelectItem.create = function (value0) {
        return new SelectItem(value0);
    };
    return SelectItem;
})();
var Save = (function () {
    function Save() {

    };
    Save.value = new Save();
    return Save;
})();
var quantityIndicator = function (v) {
    var v1 = (function () {
        var $58 = v.quantity >= 10000000;
        if ($58) {
            var truncatedValue = Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(Data_Int.floor(Data_Int.toNumber(v.quantity)))(1000000);
            return {
                value: Data_Show.show(Data_Show.showInt)(truncatedValue) + "M",
                color: "stack-10m"
            };
        };
        var $59 = v.quantity >= 100000;
        if ($59) {
            var truncatedValue = Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(Data_Int.floor(Data_Int.toNumber(v.quantity)))(1000);
            return {
                value: Data_Show.show(Data_Show.showInt)(truncatedValue) + "K",
                color: "stack-100k"
            };
        };
        return {
            value: Data_Show.show(Data_Show.showInt)(v.quantity),
            color: "stack"
        };
    })();
    return Halogen_HTML_Elements.span([ Halogen_HTML_Properties.classes([ "f7", v1.color, v["class"] ]) ])([ Halogen_HTML_Core.text(v1.value) ]);
};
var openLootingBag = Data_Newtype.wrap()(22586);
var itemIcon = function (v) {
    var dataUri = "data:image/jpeg;base64," + v.icon;
    return Halogen_HTML_Elements.img([ Halogen_HTML_Properties.src(dataUri), Halogen_HTML_Properties.alt(v.name), Halogen_HTML_Properties.title(v.name) ]);
};
var itemStack = function (v) {
    return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("relative") ])([ (function () {
        var $69 = v.quantity > 1;
        if ($69) {
            return quantityIndicator({
                "class": "text-shadow-1 absolute top-0 left-0",
                quantity: v.quantity
            });
        };
        return Halogen_HTML_Core.text("");
    })(), itemIcon(v.item) ]);
};
var inventorySlot = function (v) {
    return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.classes([ Web_HTML_Common.ClassName((function () {
        if (v.selected) {
            return "bg-light-blue";
        };
        return "";
    })()) ]), Halogen_HTML_Properties.style("width: 36px; height: 32px;"), Halogen_HTML_Events.onClick(function (v1) {
        return v.onSelectSlot(v.slot);
    }) ])([ (function () {
        if (v.stack instanceof Data_Maybe.Just) {
            return itemStack(v.stack.value0);
        };
        if (v.stack instanceof Data_Maybe.Nothing) {
            return Halogen_HTML_Core.text("");
        };
        throw new Error("Failed pattern match at Main (line 532, column 7 - line 534, column 30): " + [ v.stack.constructor.name ]);
    })() ]);
};
var inventoryGrid = function (v) {
    return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.classes([ "flex" ]) ])([ Halogen_HTML_Elements.div([ Halogen_HTML_Properties.classes([ "inventory-grid bg-secondary shadow-2" ]) ])(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
        return (function (stack1) {
            return Halogen_HTML_Elements.div([  ])([ inventorySlot({
                slot: v1.slot,
                stack: stack1,
                selected: Data_Eq.eq(Data_Maybe.eqMaybe(SixtySixItems_Domain_InventorySlot.eqInventorySlot))(v.selectedSlot)(new Data_Maybe.Just(v1.slot)),
                onSelectSlot: v.onSelectSlot
            }) ]);
        })(Control_Bind.bind(Data_Maybe.bindMaybe)(v1.stack)(function (v2) {
            return Data_Functor.map(Data_Maybe.functorMaybe)(function (item) {
                return {
                    item: item,
                    quantity: v2.quantity
                };
            })(Data_Map_Internal.lookup(SixtySixItems_Domain_Item.ordItemId)(v2.item)(v.itemCache));
        }));
    })(SixtySixItems_Domain_Inventory.toArray(v.inventory))) ]);
};
var fromOsrsBoxItem = function (item) {
    return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Int.fromString(item.id))(function (id) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)({
            id: Data_Newtype.wrap()(id),
            name: item.name,
            icon: item.icon,
            nonNoted: Data_Functor.map(Data_Maybe.functorMaybe)(Data_Newtype.wrap())(item.linked_id_item),
            noted: Data_Functor.map(Data_Maybe.functorMaybe)(Data_Newtype.wrap())(item.linked_id_noted),
            isNoted: item.noted,
            stackable: item.stackable
        });
    });
};
var equipmentSlot = function (v) {
    return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.classes([ Web_HTML_Common.ClassName((function () {
        if (v.selected) {
            return "bg-light-blue";
        };
        return "";
    })()) ]), Halogen_HTML_Properties.style("width: 36px; height: 32px;"), Halogen_HTML_Events.onClick(function (v1) {
        return v.onSelectSlot(v.slot);
    }) ])([ (function () {
        if (v.stack instanceof Data_Maybe.Just) {
            return itemStack(v.stack.value0);
        };
        if (v.stack instanceof Data_Maybe.Nothing) {
            return Halogen_HTML_Core.text("");
        };
        throw new Error("Failed pattern match at Main (line 551, column 7 - line 553, column 30): " + [ v.stack.constructor.name ]);
    })() ]);
};
var equipmentList = function (v) {
    return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.classes([ "flex" ]) ])([ Halogen_HTML_Elements.div([ Halogen_HTML_Properties.classes([ "bg-secondary shadow-2" ]) ])(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
        return (function (stack1) {
            return Halogen_HTML_Elements.div([  ])([ Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("flex items-center justify-between pa1") ])([ Halogen_HTML_Elements.h3([ Halogen_HTML_Properties.classes([ "ma0 pv1 ph2 mr3 fw3" ]) ])([ Halogen_HTML_Core.text(SixtySixItems_Domain_EquipmentSlot.displayName(v1.slot)) ]), equipmentSlot({
                slot: v1.slot,
                stack: stack1,
                selected: Data_Eq.eq(Data_Maybe.eqMaybe(SixtySixItems_Domain_EquipmentSlot.eqEquipmentSlot))(v.selectedSlot)(new Data_Maybe.Just(v1.slot)),
                onSelectSlot: v.onSelectSlot
            }) ]) ]);
        })(Control_Bind.bind(Data_Maybe.bindMaybe)(v1.stack)(function (v2) {
            return Data_Functor.map(Data_Maybe.functorMaybe)(function (item) {
                return {
                    item: item,
                    quantity: v2.quantity
                };
            })(Data_Map_Internal.lookup(SixtySixItems_Domain_Item.ordItemId)(v2.item)(v.itemCache));
        }));
    })(SixtySixItems_Domain_Equipment.toArray(v.equipment))) ]);
};
var coinStackSizes = Data_Map_Internal.insert(Data_Ord.ordInt)(10000)(Data_Newtype.wrap()(1004))(Data_Map_Internal.insert(Data_Ord.ordInt)(1000)(Data_Newtype.wrap()(1003))(Data_Map_Internal.insert(Data_Ord.ordInt)(250)(Data_Newtype.wrap()(1002))(Data_Map_Internal.insert(Data_Ord.ordInt)(100)(Data_Newtype.wrap()(1001))(Data_Map_Internal.insert(Data_Ord.ordInt)(25)(Data_Newtype.wrap()(1000))(Data_Map_Internal.insert(Data_Ord.ordInt)(5)(Data_Newtype.wrap()(999))(Data_Map_Internal.insert(Data_Ord.ordInt)(4)(Data_Newtype.wrap()(998))(Data_Map_Internal.insert(Data_Ord.ordInt)(3)(Data_Newtype.wrap()(997))(Data_Map_Internal.insert(Data_Ord.ordInt)(2)(Data_Newtype.wrap()(996))(Data_Map_Internal.insert(Data_Ord.ordInt)(1)(Data_Newtype.wrap()(995))(Data_Map_Internal.empty))))))))));
var coinIds = Data_Set.fromFoldable(Data_List_Types.foldableList)(SixtySixItems_Domain_Item.ordItemId)(Data_Map_Internal.values(coinStackSizes));
var closedLootingBag = Data_Newtype.wrap()(11941);
var arrowShaftStackSizes = Data_Map_Internal.insert(Data_Ord.ordInt)(5)(Data_Newtype.wrap()(6443))(Data_Map_Internal.insert(Data_Ord.ordInt)(4)(Data_Newtype.wrap()(6442))(Data_Map_Internal.insert(Data_Ord.ordInt)(3)(Data_Newtype.wrap()(6441))(Data_Map_Internal.insert(Data_Ord.ordInt)(2)(Data_Newtype.wrap()(6440))(Data_Map_Internal.insert(Data_Ord.ordInt)(1)(Data_Newtype.wrap()(52))(Data_Map_Internal.empty)))));
var arrowShaftIds = Data_Set.fromFoldable(Data_List_Types.foldableList)(SixtySixItems_Domain_Item.ordItemId)(Data_Map_Internal.values(arrowShaftStackSizes));
var replaceItemStacks = function (itemId) {
    return function (quantity) {
        var replacement = (function () {
            var $110 = Data_Set.member(SixtySixItems_Domain_Item.ordItemId)(itemId)(coinIds);
            if ($110) {
                return Data_Map_Internal.lookupLE(Data_Ord.ordInt)(quantity)(coinStackSizes);
            };
            var $111 = Data_Set.member(SixtySixItems_Domain_Item.ordItemId)(itemId)(arrowShaftIds);
            if ($111) {
                return Data_Map_Internal.lookupLE(Data_Ord.ordInt)(quantity)(arrowShaftStackSizes);
            };
            return new Data_Maybe.Just({
                key: quantity,
                value: itemId
            });
        })();
        return Data_Maybe.fromMaybe(itemId)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
            return v.value;
        })(replacement));
    };
};
var component = function (dictMonadAff) {
    var render = function (state) {
        return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.classes([ "flex h-100" ]) ])([ Halogen_HTML_Elements.div([ Halogen_HTML_Properties.classes([ "flex flex-column w-third pa4 bg-black shadow-5" ]) ])([ Halogen_HTML_Elements.div_([ Halogen_HTML_Elements.h1([ Halogen_HTML_Properties.class_("ma0 mb4 f1") ])([ Halogen_HTML_Core.text("66 Items") ]) ]), Halogen_HTML_Elements.div_([ Halogen_HTML_Elements.div([ Halogen_HTML_Properties.classes([ "pa2 bg-secondary" ]) ])([ Halogen_HTML_Elements.span_([ (function () {
            if (state.selectedSlot instanceof Data_Maybe.Just && state.selectedSlot.value0 instanceof SixtySixItems_Domain_Storage.EquipmentSlot) {
                return Halogen_HTML_Core.text("Equipment: " + SixtySixItems_Domain_EquipmentSlot.displayName(state.selectedSlot.value0.value0));
            };
            if (state.selectedSlot instanceof Data_Maybe.Just && state.selectedSlot.value0 instanceof SixtySixItems_Domain_Storage.InventorySlot) {
                return Halogen_HTML_Core.text("Inventory: Slot " + Data_Show.show(Data_Show.showInt)(1 + Data_Enum.fromEnum(SixtySixItems_Domain_InventorySlot.boundedEnumInventorySlot)(state.selectedSlot.value0.value0) | 0));
            };
            if (state.selectedSlot instanceof Data_Maybe.Just && state.selectedSlot.value0 instanceof SixtySixItems_Domain_Storage.LootingBagSlot) {
                return Halogen_HTML_Core.text("Looting Bag: Slot " + Data_Show.show(Data_Show.showInt)(1 + Data_Enum.fromEnum(SixtySixItems_Domain_InventorySlot.boundedEnumInventorySlot)(state.selectedSlot.value0.value0) | 0));
            };
            if (state.selectedSlot instanceof Data_Maybe.Nothing) {
                return Halogen_HTML_Core.text("No slot selected");
            };
            throw new Error("Failed pattern match at Main (line 163, column 25 - line 167, column 64): " + [ state.selectedSlot.constructor.name ]);
        })() ]), Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("pa1") ])([  ]), (function () {
            var selectedStack = Control_Bind.bind(Data_Maybe.bindMaybe)(state.selectedSlot)(function (slot) {
                return SixtySixItems_Domain_Storage.item(slot)(state.storage);
            });
            var selectedItemStack = Control_Bind.bind(Data_Maybe.bindMaybe)(selectedStack)(function (v) {
                return Data_Functor.map(Data_Maybe.functorMaybe)(function (item) {
                    return {
                        item: item,
                        quantity: v.quantity
                    };
                })(Data_Map_Internal.lookup(SixtySixItems_Domain_Item.ordItemId)(v.item)(state.itemCache));
            });
            if (selectedItemStack instanceof Data_Maybe.Just) {
                return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.style("width: 36px; height: 32px;") ])([ itemStack(selectedItemStack.value0) ]);
            };
            if (selectedItemStack instanceof Data_Maybe.Nothing) {
                return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.style("width: 36px; height: 32px;") ])([ Halogen_HTML_Core.text("") ]);
            };
            throw new Error("Failed pattern match at Main (line 179, column 23 - line 188, column 41): " + [ selectedItemStack.constructor.name ]);
        })() ]), Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("pa2") ])([  ]), Halogen_HTML_Elements.label([ Halogen_HTML_Properties.class_("db f5") ])([ Halogen_HTML_Core.text("Stack size") ]), Halogen_HTML_Elements.input([ Halogen_HTML_Properties.classes([ "db w-100", Tachyons.pa(1) ]), Halogen_HTML_Properties.type_(Halogen_HTML_Core.isPropInputType)(DOM_HTML_Indexed_InputType.InputNumber.value), Halogen_HTML_Properties.min(1.0), Halogen_HTML_Properties.value(state.quantity), Halogen_HTML_Events.onValueInput(SetQuantity.create) ]), Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("pa2") ])([  ]), Halogen_HTML_Elements.label([ Halogen_HTML_Properties.class_("flex items-center f5") ])([ Halogen_HTML_Elements.input([ Halogen_HTML_Properties.class_("mr2"), Halogen_HTML_Properties.type_(Halogen_HTML_Core.isPropInputType)(DOM_HTML_Indexed_InputType.InputCheckbox.value), Halogen_HTML_Properties.checked(state.isNoted), Halogen_HTML_Events.onChecked(SetIsNoted.create) ]), Halogen_HTML_Core.text("Noted") ]), Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("pa2") ])([  ]), Halogen_HTML_Elements.label([ Halogen_HTML_Properties.class_("db f5") ])([ Halogen_HTML_Core.text("Item search") ]), Halogen_HTML_Elements.input([ Halogen_HTML_Properties.classes([ "db w-100", Tachyons.pa(1) ]), Halogen_HTML_Properties.type_(Halogen_HTML_Core.isPropInputType)(DOM_HTML_Indexed_InputType.InputText.value), Halogen_HTML_Properties.value(state.search), Halogen_HTML_Events.onValueInput(SetSearch.create) ]), Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("pa2") ])([  ]), Halogen_HTML_Elements.button([ Halogen_HTML_Properties.class_(Tachyons.pa(1)), Halogen_HTML_Events.onClick(function (v) {
            return LoadItems.value;
        }) ])([ Halogen_HTML_Core.text("Load Items") ]), (function () {
            if (state.items instanceof Network_RemoteData.NotAsked) {
                return Halogen_HTML_Core.text("");
            };
            if (state.items instanceof Network_RemoteData.Loading) {
                return Halogen_HTML_Core.text("Loading items...");
            };
            if (state.items instanceof Network_RemoteData.Failure) {
                return Halogen_HTML_Core.text("Failed to load items: " + state.items.value0);
            };
            if (state.items instanceof Network_RemoteData.Success) {
                return Halogen_HTML_Elements.div_(Data_Functor.map(Data_Functor.functorArray)(function (item) {
                    return Halogen_HTML_Elements.div([ Halogen_HTML_Events.onClick(function (v) {
                        return new SelectItem(item);
                    }) ])([ itemIcon(item) ]);
                })(state.items.value0));
            };
            throw new Error("Failed pattern match at Main (line 219, column 17 - line 223, column 128): " + [ state.items.constructor.name ]);
        })() ]) ]), Halogen_HTML_Elements.div([ Halogen_HTML_Properties.classes([ "flex w-two-thirds pa4" ]) ])([ Halogen_HTML_Elements.div_([ Halogen_HTML_Elements.h2([ Halogen_HTML_Properties.classes([ "ma0 mb2 f3 fw3" ]) ])([ Halogen_HTML_Core.text("Equipment") ]), equipmentList({
            itemCache: state.itemCache,
            equipment: state.storage.equipment,
            selectedSlot: Control_Bind.bind(Data_Maybe.bindMaybe)(state.selectedSlot)(function (v) {
                if (v instanceof SixtySixItems_Domain_Storage.EquipmentSlot) {
                    return new Data_Maybe.Just(v.value0);
                };
                if (v instanceof SixtySixItems_Domain_Storage.InventorySlot) {
                    return Data_Maybe.Nothing.value;
                };
                if (v instanceof SixtySixItems_Domain_Storage.LootingBagSlot) {
                    return Data_Maybe.Nothing.value;
                };
                throw new Error("Failed pattern match at Main (line 232, column 58 - line 235, column 50): " + [ v.constructor.name ]);
            }),
            onSelectSlot: function ($243) {
                return SelectSlot.create(SixtySixItems_Domain_Storage.EquipmentSlot.create($243));
            }
        }) ]), Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("pa3") ])([  ]), Halogen_HTML_Elements.div_([ Halogen_HTML_Elements.h2([ Halogen_HTML_Properties.classes([ "ma0 mb2 f3 fw3" ]) ])([ Halogen_HTML_Core.text("Inventory") ]), inventoryGrid({
            itemCache: state.itemCache,
            inventory: state.storage.inventory,
            selectedSlot: Control_Bind.bind(Data_Maybe.bindMaybe)(state.selectedSlot)(function (v) {
                if (v instanceof SixtySixItems_Domain_Storage.InventorySlot) {
                    return new Data_Maybe.Just(v.value0);
                };
                if (v instanceof SixtySixItems_Domain_Storage.EquipmentSlot) {
                    return Data_Maybe.Nothing.value;
                };
                if (v instanceof SixtySixItems_Domain_Storage.LootingBagSlot) {
                    return Data_Maybe.Nothing.value;
                };
                throw new Error("Failed pattern match at Main (line 245, column 58 - line 248, column 50): " + [ v.constructor.name ]);
            }),
            onSelectSlot: function ($244) {
                return SelectSlot.create(SixtySixItems_Domain_Storage.InventorySlot.create($244));
            }
        }) ]), Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("pa3") ])([  ]), Halogen_HTML_Elements.div_([ Halogen_HTML_Elements.h2([ Halogen_HTML_Properties.classes([ "ma0 mb2 f3 fw3" ]) ])([ Halogen_HTML_Core.text("Looting Bag") ]), (function () {
            var hasLootingBag = SixtySixItems_Domain_Inventory.contains(closedLootingBag)(state.storage.inventory) || SixtySixItems_Domain_Inventory.contains(openLootingBag)(state.storage.inventory);
            if (hasLootingBag) {
                return inventoryGrid({
                    itemCache: state.itemCache,
                    inventory: state.storage.lootingBag,
                    selectedSlot: Control_Bind.bind(Data_Maybe.bindMaybe)(state.selectedSlot)(function (v) {
                        if (v instanceof SixtySixItems_Domain_Storage.LootingBagSlot) {
                            return new Data_Maybe.Just(v.value0);
                        };
                        if (v instanceof SixtySixItems_Domain_Storage.EquipmentSlot) {
                            return Data_Maybe.Nothing.value;
                        };
                        if (v instanceof SixtySixItems_Domain_Storage.InventorySlot) {
                            return Data_Maybe.Nothing.value;
                        };
                        throw new Error("Failed pattern match at Main (line 265, column 64 - line 268, column 55): " + [ v.constructor.name ]);
                    }),
                    onSelectSlot: function ($245) {
                        return SelectSlot.create(SixtySixItems_Domain_Storage.LootingBagSlot.create($245));
                    }
                });
            };
            if (!hasLootingBag) {
                return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("pa3 mw5 lh-copy bg-secondary") ])([ Halogen_HTML_Core.text("You must have a looting bag in your inventory in order to store items in it.") ]);
            };
            throw new Error("Failed pattern match at Main (line 260, column 19 - line 273, column 24): " + [ hasLootingBag.constructor.name ]);
        })() ]) ]) ]);
    };
    var initialState = function (v) {
        return {
            search: "",
            quantity: "",
            isNoted: false,
            items: Network_RemoteData.NotAsked.value,
            itemCache: Data_Map_Internal.empty,
            storage: SixtySixItems_Domain_Storage.empty,
            selectedSlot: Data_Maybe.Nothing.value
        };
    };
    var handleAction = function (v) {
        if (v instanceof Initialize) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(SixtySixItems_SaveData.read))(function (saveData) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)((function () {
                    if (saveData instanceof Data_Either.Right && saveData.value0 instanceof Data_Maybe.Just) {
                        return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (currentState) {
                            var $145 = {};
                            for (var $146 in currentState) {
                                if ({}.hasOwnProperty.call(currentState, $146)) {
                                    $145[$146] = currentState[$146];
                                };
                            };
                            $145.storage = (function () {
                                var $142 = {};
                                for (var $143 in currentState.storage) {
                                    if ({}.hasOwnProperty.call(currentState.storage, $143)) {
                                        $142[$143] = currentState["storage"][$143];
                                    };
                                };
                                $142.equipment = saveData.value0.value0.equipment;
                                $142.inventory = saveData.value0.value0.inventory;
                                $142.lootingBag = saveData.value0.value0.lootingBag;
                                return $142;
                            })();
                            return $145;
                        });
                    };
                    if (saveData instanceof Data_Either.Right && saveData.value0 instanceof Data_Maybe.Nothing) {
                        return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                    };
                    if (saveData instanceof Data_Either.Left) {
                        return Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Effect_Class_Console.log(Effect_Class.monadEffectEffect)(saveData.value0));
                    };
                    throw new Error("Failed pattern match at Main (line 281, column 7 - line 293, column 41): " + [ saveData.constructor.name ]);
                })())(function () {
                    if (saveData instanceof Data_Either.Right && saveData.value0 instanceof Data_Maybe.Just) {
                        var extractIds = (function () {
                            var $246 = Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                                return v1.item;
                            });
                            var $247 = Data_Array.mapMaybe(function (v1) {
                                return v1.stack;
                            });
                            return function ($248) {
                                return $246($247(SixtySixItems_Domain_Inventory.toArray($248)));
                            };
                        })();
                        var extractEquipmentIds = (function () {
                            var $249 = Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                                return v1.item;
                            });
                            var $250 = Data_Array.mapMaybe(function (v1) {
                                return v1.stack;
                            });
                            return function ($251) {
                                return $249($250(SixtySixItems_Domain_Equipment.toArray($251)));
                            };
                        })();
                        var itemsToLoad = Data_Array.nub(SixtySixItems_Domain_Item.ordItemId)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(extractEquipmentIds(saveData.value0.value0.equipment))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(extractIds(saveData.value0.value0.inventory))(extractIds(saveData.value0.value0.lootingBag))));
                        var $153 = !Data_Array["null"](itemsToLoad);
                        if ($153) {
                            return Data_Functor["void"](Halogen_Query_HalogenM.functorHalogenM)(Halogen_Query_HalogenM.fork(handleAction(new LoadItemsById(itemsToLoad))));
                        };
                        return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                    };
                    if (saveData instanceof Data_Either.Right && saveData.value0 instanceof Data_Maybe.Nothing) {
                        return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                    };
                    if (saveData instanceof Data_Either.Left) {
                        return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                    };
                    throw new Error("Failed pattern match at Main (line 295, column 7 - line 317, column 28): " + [ saveData.constructor.name ]);
                });
            });
        };
        if (v instanceof SetSearch) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                var $158 = {};
                for (var $159 in v1) {
                    if ({}.hasOwnProperty.call(v1, $159)) {
                        $158[$159] = v1[$159];
                    };
                };
                $158.search = v.value0;
                return $158;
            });
        };
        if (v instanceof SetQuantity) {
            return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (currentState) {
                var $175 = {};
                for (var $176 in currentState) {
                    if ({}.hasOwnProperty.call(currentState, $176)) {
                        $175[$176] = currentState[$176];
                    };
                };
                $175.quantity = v.value0;
                $175.storage = Data_Maybe.fromMaybe(currentState.storage)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v2) {
                    return SixtySixItems_Domain_Storage.setItemStack(v2.slot)({
                        item: v2.item.id,
                        quantity: v2.quantity
                    })(currentState.storage);
                })(Control_Bind.bind(Data_Maybe.bindMaybe)(Control_Bind.bind(Data_Maybe.bindMaybe)(Control_Bind.bind(Data_Maybe.bindMaybe)(currentState.selectedSlot)(function (slot) {
                    return Data_Functor.map(Data_Maybe.functorMaybe)(function (v2) {
                        return {
                            slot: slot,
                            item: v2.item
                        };
                    })(SixtySixItems_Domain_Storage.item(slot)(currentState.storage));
                }))(function (v2) {
                    return Data_Functor.map(Data_Maybe.functorMaybe)(function (quantity) {
                        return {
                            slot: v2.slot,
                            item: replaceItemStacks(v2.item)(quantity),
                            quantity: quantity
                        };
                    })(Data_Int.fromString(v.value0));
                }))(function (v2) {
                    return Data_Functor.map(Data_Maybe.functorMaybe)(function (item) {
                        return {
                            slot: v2.slot,
                            item: item,
                            quantity: v2.quantity
                        };
                    })(Data_Map_Internal.lookup(SixtySixItems_Domain_Item.ordItemId)(v2.item)(currentState.itemCache));
                })));
                return $175;
            }))(function () {
                return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (v1) {
                    var stackVariantsNotInCache = Data_Set.filter(SixtySixItems_Domain_Item.ordItemId)(function (itemId) {
                        return !Data_Map_Internal.member(SixtySixItems_Domain_Item.ordItemId)(itemId)(v1.itemCache);
                    })(Data_Semigroup.append(Data_Set.semigroupSet(SixtySixItems_Domain_Item.ordItemId))(coinIds)(arrowShaftIds));
                    return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Data_Functor["void"](Halogen_Query_HalogenM.functorHalogenM)(Halogen_Query_HalogenM.fork(handleAction(LoadItemsById.create(Data_Array.fromFoldable(Data_Set.foldableSet)(stackVariantsNotInCache))))))(function () {
                        return Data_Functor["void"](Halogen_Query_HalogenM.functorHalogenM)(Halogen_Query_HalogenM.fork(handleAction(Save.value)));
                    });
                });
            });
        };
        if (v instanceof SetIsNoted) {
            return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (currentState) {
                var $195 = {};
                for (var $196 in currentState) {
                    if ({}.hasOwnProperty.call(currentState, $196)) {
                        $195[$196] = currentState[$196];
                    };
                };
                $195.isNoted = v.value0;
                $195.storage = Data_Maybe.fromMaybe(currentState.storage)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v2) {
                    return SixtySixItems_Domain_Storage.setItemStack(v2.slot)({
                        item: v2.item,
                        quantity: v2.quantity
                    })(currentState.storage);
                })(Control_Bind.bind(Data_Maybe.bindMaybe)(Control_Bind.bind(Data_Maybe.bindMaybe)(Control_Bind.bind(Data_Maybe.bindMaybe)(currentState.selectedSlot)(function (slot) {
                    return Data_Functor.map(Data_Maybe.functorMaybe)(function (stack) {
                        return {
                            slot: slot,
                            stack: stack
                        };
                    })(SixtySixItems_Domain_Storage.item(slot)(currentState.storage));
                }))(function (v2) {
                    return Data_Functor.map(Data_Maybe.functorMaybe)(function (item) {
                        return {
                            slot: v2.slot,
                            item: item,
                            quantity: v2.stack.quantity
                        };
                    })(Data_Map_Internal.lookup(SixtySixItems_Domain_Item.ordItemId)(v2.stack.item)(currentState.itemCache));
                }))(function (v2) {
                    return Data_Functor.map(Data_Maybe.functorMaybe)(function (notedItem) {
                        return {
                            slot: v2.slot,
                            item: notedItem,
                            quantity: v2.quantity
                        };
                    })((function () {
                        if (v.value0) {
                            return v2.item.noted;
                        };
                        return v2.item.nonNoted;
                    })());
                })));
                return $195;
            }))(function () {
                return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (v1) {
                    var notedVariantsNotInCache = Data_List.filter(function (itemId) {
                        return !Data_Map_Internal.member(SixtySixItems_Domain_Item.ordItemId)(itemId)(v1.itemCache);
                    })(Data_List.mapMaybe(function (item) {
                        return item.noted;
                    })(Data_Map_Internal.values(v1.itemCache)));
                    var nonNotedVariantsNotInCache = Data_List.filter(function (itemId) {
                        return !Data_Map_Internal.member(SixtySixItems_Domain_Item.ordItemId)(itemId)(v1.itemCache);
                    })(Data_List.mapMaybe(function (item) {
                        return item.nonNoted;
                    })(Data_Map_Internal.values(v1.itemCache)));
                    return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Data_Functor["void"](Halogen_Query_HalogenM.functorHalogenM)(Halogen_Query_HalogenM.fork(handleAction(LoadItemsById.create(Data_Array.fromFoldable(Data_List_Types.foldableList)(Data_Semigroup.append(Data_List_Types.semigroupList)(notedVariantsNotInCache)(nonNotedVariantsNotInCache)))))))(function () {
                        return Data_Functor["void"](Halogen_Query_HalogenM.functorHalogenM)(Halogen_Query_HalogenM.fork(handleAction(Save.value)));
                    });
                });
            });
        };
        if (v instanceof LoadItems) {
            return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                var $201 = {};
                for (var $202 in v1) {
                    if ({}.hasOwnProperty.call(v1, $202)) {
                        $201[$202] = v1[$202];
                    };
                };
                $201.items = Network_RemoteData.Loading.value;
                return $201;
            }))(function () {
                return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (v1) {
                    return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Aff_Class.liftAff(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(OsrsBox_Api.items({
                        name: v1.search
                    })))(function (items) {
                        return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (currentState) {
                            var $207 = {};
                            for (var $208 in currentState) {
                                if ({}.hasOwnProperty.call(currentState, $208)) {
                                    $207[$208] = currentState[$208];
                                };
                            };
                            $207.items = Data_Functor.map(Network_RemoteData.functorRemoteData)(Data_Array.mapMaybe(fromOsrsBoxItem))(Data_Functor.map(Network_RemoteData.functorRemoteData)(Data_Array.filter((function () {
                                var $252 = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean);
                                return function ($253) {
                                    return $252((function (v3) {
                                        return v3.duplicate;
                                    })($253));
                                };
                            })()))(Network_RemoteData.fromEither(items)));
                            $207.itemCache = Data_Map_Internal.union(SixtySixItems_Domain_Item.ordItemId)(currentState.itemCache)((function (v3) {
                                if (v3 instanceof Network_RemoteData.Success) {
                                    return Data_Map_Internal.fromFoldable(SixtySixItems_Domain_Item.ordItemId)(Data_Foldable.foldableArray)(Data_Functor.map(Data_Functor.functorArray)(function (item) {
                                        return new Data_Tuple.Tuple(item.id, item);
                                    })(v3.value0));
                                };
                                return Data_Map_Internal.empty;
                            })(Data_Functor.map(Network_RemoteData.functorRemoteData)(Data_Array.mapMaybe(fromOsrsBoxItem))(Network_RemoteData.fromEither(items))));
                            return $207;
                        });
                    });
                });
            });
        };
        if (v instanceof LoadItemsById) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Aff_Class.liftAff(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))((function () {
                var $211 = Data_Array["null"](v.value0);
                if ($211) {
                    return Control_Applicative.pure(Effect_Aff.applicativeAff)(new Data_Either.Right([  ]));
                };
                return OsrsBox_Api.itemsById(Data_Functor.map(Data_Functor.functorArray)(Data_Newtype.unwrap())(v.value0));
            })()))(function (items) {
                return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (currentState) {
                    var $214 = {};
                    for (var $215 in currentState) {
                        if ({}.hasOwnProperty.call(currentState, $215)) {
                            $214[$215] = currentState[$215];
                        };
                    };
                    $214.itemCache = Data_Map_Internal.union(SixtySixItems_Domain_Item.ordItemId)(currentState.itemCache)((function (v2) {
                        if (v2 instanceof Network_RemoteData.Success) {
                            return Data_Map_Internal.fromFoldable(SixtySixItems_Domain_Item.ordItemId)(Data_Foldable.foldableArray)(Data_Functor.map(Data_Functor.functorArray)(function (item) {
                                return new Data_Tuple.Tuple(item.id, item);
                            })(v2.value0));
                        };
                        return Data_Map_Internal.empty;
                    })(Data_Functor.map(Network_RemoteData.functorRemoteData)(Data_Array.mapMaybe(fromOsrsBoxItem))(Network_RemoteData.fromEither(items))));
                    return $214;
                });
            });
        };
        if (v instanceof SelectSlot) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (currentState) {
                var $223 = {};
                for (var $224 in currentState) {
                    if ({}.hasOwnProperty.call(currentState, $224)) {
                        $223[$224] = currentState[$224];
                    };
                };
                $223.selectedSlot = new Data_Maybe.Just(v.value0);
                $223.quantity = Data_Maybe.fromMaybe(currentState.quantity)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Show.show(Data_Show.showInt))(Data_Functor.map(Data_Maybe.functorMaybe)(function (v2) {
                    return v2.quantity;
                })(SixtySixItems_Domain_Storage.item(v.value0)(currentState.storage))));
                $223.isNoted = Data_Maybe.maybe(false)(function (v2) {
                    return v2.item.isNoted;
                })(Control_Bind.bind(Data_Maybe.bindMaybe)(SixtySixItems_Domain_Storage.item(v.value0)(currentState.storage))(function (v2) {
                    return Data_Functor.map(Data_Maybe.functorMaybe)(function (item) {
                        return {
                            item: item,
                            quantity: v2.quantity
                        };
                    })(Data_Map_Internal.lookup(SixtySixItems_Domain_Item.ordItemId)(v2.item)(currentState.itemCache));
                }));
                return $223;
            });
        };
        if (v instanceof SelectItem) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (v1) {
                var quantity = Data_Maybe.fromMaybe(1)(Data_Int.fromString(v1.quantity));
                var itemId = (function () {
                    if (v1.isNoted) {
                        return Data_Maybe.fromMaybe(v.value0.id)(v.value0.noted);
                    };
                    return v.value0.id;
                })();
                return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)((function () {
                    if (v1.selectedSlot instanceof Data_Maybe.Just) {
                        return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (currentState) {
                            var $230 = {};
                            for (var $231 in currentState) {
                                if ({}.hasOwnProperty.call(currentState, $231)) {
                                    $230[$231] = currentState[$231];
                                };
                            };
                            $230.storage = SixtySixItems_Domain_Storage.setItemStack(v1.selectedSlot.value0)({
                                item: itemId,
                                quantity: quantity
                            })(currentState.storage);
                            return $230;
                        });
                    };
                    if (v1.selectedSlot instanceof Data_Maybe.Nothing) {
                        return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                    };
                    throw new Error("Failed pattern match at Main (line 440, column 7 - line 443, column 29): " + [ v1.selectedSlot.constructor.name ]);
                })())(function () {
                    return Data_Functor["void"](Halogen_Query_HalogenM.functorHalogenM)(Halogen_Query_HalogenM.fork(handleAction(Save.value)));
                });
            });
        };
        if (v instanceof Save) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (v1) {
                return Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(SixtySixItems_SaveData.write({
                    equipment: v1.storage.equipment,
                    inventory: v1.storage.inventory,
                    lootingBag: v1.storage.lootingBag
                }));
            });
        };
        throw new Error("Failed pattern match at Main (line 278, column 18 - line 449, column 71): " + [ v.constructor.name ]);
    };
    return Halogen_Component.mkComponent({
        initialState: initialState,
        render: render,
        "eval": Halogen_Component.mkEval({
            handleAction: handleAction,
            handleQuery: Halogen_Component.defaultEval.handleQuery,
            receive: Halogen_Component.defaultEval.receive,
            initialize: new Data_Maybe.Just(Initialize.value),
            finalize: Halogen_Component.defaultEval.finalize
        })
    });
};
var main = Halogen_Aff_Util.runHalogenAff(Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Halogen_Aff_Util.awaitLoad)(function () {
    return Control_Bind.bindFlipped(Effect_Aff.bindAff)(Data_Foldable.traverse_(Effect_Aff.applicativeAff)(Data_Foldable.foldableMaybe)(Halogen_VDom_Driver.runUI(component(Effect_Aff_Class.monadAffAff))(Data_Unit.unit)))(Halogen_Aff_Util.selectElement(Data_Newtype.wrap()("#sixty-six-items")));
}));
module.exports = {
    closedLootingBag: closedLootingBag,
    openLootingBag: openLootingBag,
    coinStackSizes: coinStackSizes,
    coinIds: coinIds,
    arrowShaftStackSizes: arrowShaftStackSizes,
    arrowShaftIds: arrowShaftIds,
    replaceItemStacks: replaceItemStacks,
    fromOsrsBoxItem: fromOsrsBoxItem,
    main: main,
    Initialize: Initialize,
    SetSearch: SetSearch,
    SetQuantity: SetQuantity,
    SetIsNoted: SetIsNoted,
    LoadItems: LoadItems,
    LoadItemsById: LoadItemsById,
    SelectSlot: SelectSlot,
    SelectItem: SelectItem,
    Save: Save,
    component: component,
    itemIcon: itemIcon,
    quantityIndicator: quantityIndicator,
    itemStack: itemStack,
    inventoryGrid: inventoryGrid,
    inventorySlot: inventorySlot,
    equipmentSlot: equipmentSlot,
    equipmentList: equipmentList
};
