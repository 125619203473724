// Generated by purs version 0.14.5
"use strict";
var Control_Category = require("../Control.Category/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_MediaType_Common = require("../Data.MediaType.Common/index.js");
var $$ArrayBuffer = (function () {
    function $$ArrayBuffer(value0) {
        this.value0 = value0;
    };
    $$ArrayBuffer.create = function (value0) {
        return new $$ArrayBuffer(value0);
    };
    return $$ArrayBuffer;
})();
var Blob = (function () {
    function Blob(value0) {
        this.value0 = value0;
    };
    Blob.create = function (value0) {
        return new Blob(value0);
    };
    return Blob;
})();
var Document = (function () {
    function Document(value0) {
        this.value0 = value0;
    };
    Document.create = function (value0) {
        return new Document(value0);
    };
    return Document;
})();
var Json = (function () {
    function Json(value0) {
        this.value0 = value0;
    };
    Json.create = function (value0) {
        return new Json(value0);
    };
    return Json;
})();
var $$String = (function () {
    function $$String(value0) {
        this.value0 = value0;
    };
    $$String.create = function (value0) {
        return new $$String(value0);
    };
    return $$String;
})();
var Ignore = (function () {
    function Ignore(value0) {
        this.value0 = value0;
    };
    Ignore.create = function (value0) {
        return new Ignore(value0);
    };
    return Ignore;
})();
var toResponseType = function (v) {
    if (v instanceof $$ArrayBuffer) {
        return "arraybuffer";
    };
    if (v instanceof Blob) {
        return "blob";
    };
    if (v instanceof Document) {
        return "document";
    };
    if (v instanceof Json) {
        return "text";
    };
    if (v instanceof $$String) {
        return "text";
    };
    if (v instanceof Ignore) {
        return "";
    };
    throw new Error("Failed pattern match at Affjax.ResponseFormat (line 44, column 3 - line 50, column 19): " + [ v.constructor.name ]);
};
var toMediaType = function (v) {
    if (v instanceof Json) {
        return new Data_Maybe.Just(Data_MediaType_Common.applicationJSON);
    };
    return Data_Maybe.Nothing.value;
};
var string = new $$String(Control_Category.identity(Control_Category.categoryFn));
var json = new Json(Control_Category.identity(Control_Category.categoryFn));
var ignore = new Ignore(Control_Category.identity(Control_Category.categoryFn));
var document = new Document(Control_Category.identity(Control_Category.categoryFn));
var blob = new Blob(Control_Category.identity(Control_Category.categoryFn));
var arrayBuffer = new $$ArrayBuffer(Control_Category.identity(Control_Category.categoryFn));
module.exports = {
    "ArrayBuffer": $$ArrayBuffer,
    Blob: Blob,
    Document: Document,
    Json: Json,
    "String": $$String,
    Ignore: Ignore,
    arrayBuffer: arrayBuffer,
    blob: blob,
    document: document,
    json: json,
    string: string,
    ignore: ignore,
    toResponseType: toResponseType,
    toMediaType: toMediaType
};
