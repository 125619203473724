// Generated by purs version 0.14.5
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Enum = require("../Data.Enum/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unfoldable = require("../Data.Unfoldable/index.js");
var Data_Unfoldable1 = require("../Data.Unfoldable1/index.js");
var SixtySixItems_Domain_EquipmentSlot = require("../SixtySixItems.Domain.EquipmentSlot/index.js");
var Equipment = (function () {
    function Equipment(value0) {
        this.value0 = value0;
    };
    Equipment.create = function (value0) {
        return new Equipment(value0);
    };
    return Equipment;
})();
var toArray = function (v) {
    return Data_Functor.map(Data_Functor.functorArray)(function (v1) {
        return {
            slot: v1.value0,
            stack: v1.value1
        };
    })(Data_Map_Internal.toUnfoldable(Data_Unfoldable.unfoldableArray)(v.value0));
};
var setItemStack = function (slot) {
    return function (stack) {
        return function (v) {
            return Equipment.create(Data_Map_Internal.insert(SixtySixItems_Domain_EquipmentSlot.ordEquipmentSlot)(slot)(new Data_Maybe.Just(stack))(v.value0));
        };
    };
};
var setItem = function (slot) {
    return function (itemId) {
        return setItemStack(slot)({
            item: itemId,
            quantity: 1
        });
    };
};
var item = function (slot) {
    return function (v) {
        return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Map_Internal.lookup(SixtySixItems_Domain_EquipmentSlot.ordEquipmentSlot)(slot)(v.value0))(Control_Category.identity(Control_Category.categoryFn));
    };
};
var empty = (function () {
    var allSlots = Data_Enum.enumFromTo(SixtySixItems_Domain_EquipmentSlot.enumEquipmentSlot)(Data_Unfoldable1.unfoldable1Array)(SixtySixItems_Domain_EquipmentSlot.HeadSlot.value)(SixtySixItems_Domain_EquipmentSlot.RingSlot.value);
    return Equipment.create(Data_Map_Internal.fromFoldable(SixtySixItems_Domain_EquipmentSlot.ordEquipmentSlot)(Data_Foldable.foldableArray)(Data_Functor.map(Data_Functor.functorArray)(function (slot) {
        return new Data_Tuple.Tuple(slot, Data_Maybe.Nothing.value);
    })(allSlots)));
})();
module.exports = {
    empty: empty,
    toArray: toArray,
    item: item,
    setItemStack: setItemStack,
    setItem: setItem
};
