// Generated by purs version 0.14.5
"use strict";
var Data_Either = require("../Data.Either/index.js");
var Data_Lens_Internal_Wander = require("../Data.Lens.Internal.Wander/index.js");
var Data_Profunctor = require("../Data.Profunctor/index.js");
var Data_Profunctor_Choice = require("../Data.Profunctor.Choice/index.js");
var Data_Profunctor_Strong = require("../Data.Profunctor.Strong/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Indexed = function (x) {
    return x;
};
var profunctorIndexed = function (dictProfunctor) {
    return {
        dimap: function (f) {
            return function (g) {
                return function (v) {
                    return Data_Profunctor.dimap(dictProfunctor)(Data_Profunctor_Strong.second(Data_Profunctor_Strong.strongFn)(f))(g)(v);
                };
            };
        }
    };
};
var strongIndexed = function (dictStrong) {
    return {
        first: function (v) {
            return Indexed(Data_Profunctor.lcmap(dictStrong.Profunctor0())(function (v1) {
                return new Data_Tuple.Tuple(new Data_Tuple.Tuple(v1.value0, v1.value1.value0), v1.value1.value1);
            })(Data_Profunctor_Strong.first(dictStrong)(v)));
        },
        second: function (v) {
            return Indexed(Data_Profunctor.lcmap(dictStrong.Profunctor0())(function (v1) {
                return new Data_Tuple.Tuple(v1.value1.value0, new Data_Tuple.Tuple(v1.value0, v1.value1.value1));
            })(Data_Profunctor_Strong.second(dictStrong)(v)));
        },
        Profunctor0: function () {
            return profunctorIndexed(dictStrong.Profunctor0());
        }
    };
};
var newtypeIndexed = {
    Coercible0: function () {
        return undefined;
    }
};
var choiceIndexed = function (dictChoice) {
    return {
        left: function (v) {
            return Indexed(Data_Profunctor.lcmap(dictChoice.Profunctor0())(function (v1) {
                return Data_Either.either((function () {
                    var $44 = Data_Tuple.Tuple.create(v1.value0);
                    return function ($45) {
                        return Data_Either.Left.create($44($45));
                    };
                })())(Data_Either.Right.create)(v1.value1);
            })(Data_Profunctor_Choice.left(dictChoice)(v)));
        },
        right: function (v) {
            return Indexed(Data_Profunctor.lcmap(dictChoice.Profunctor0())(function (v1) {
                return Data_Either.either(Data_Either.Left.create)((function () {
                    var $46 = Data_Tuple.Tuple.create(v1.value0);
                    return function ($47) {
                        return Data_Either.Right.create($46($47));
                    };
                })())(v1.value1);
            })(Data_Profunctor_Choice.right(dictChoice)(v)));
        },
        Profunctor0: function () {
            return profunctorIndexed(dictChoice.Profunctor0());
        }
    };
};
var wanderIndexed = function (dictWander) {
    return {
        wander: function (trav) {
            return function (v) {
                return Indexed(Data_Lens_Internal_Wander.wander(dictWander)(function (dictApplicative) {
                    return function (ia2fb) {
                        return function (v1) {
                            return trav(dictApplicative)((function () {
                                var $48 = Data_Tuple.Tuple.create(v1.value0);
                                return function ($49) {
                                    return ia2fb($48($49));
                                };
                            })())(v1.value1);
                        };
                    };
                })(v));
            };
        },
        Strong0: function () {
            return strongIndexed(dictWander.Strong0());
        },
        Choice1: function () {
            return choiceIndexed(dictWander.Choice1());
        }
    };
};
module.exports = {
    Indexed: Indexed,
    newtypeIndexed: newtypeIndexed,
    profunctorIndexed: profunctorIndexed,
    strongIndexed: strongIndexed,
    choiceIndexed: choiceIndexed,
    wanderIndexed: wanderIndexed
};
