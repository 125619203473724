// Generated by purs version 0.14.5
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Comonad = require("../Control.Comonad/index.js");
var Control_Extend = require("../Control.Extend/index.js");
var Control_Plus = require("../Control.Plus/index.js");
var Data_Distributive = require("../Data.Distributive/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Exists = require("../Data.Exists/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Functor_Invariant = require("../Data.Functor.Invariant/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup_Foldable = require("../Data.Semigroup.Foldable/index.js");
var Data_Semigroup_Traversable = require("../Data.Semigroup.Traversable/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var CoyonedaF = (function () {
    function CoyonedaF(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    CoyonedaF.create = function (value0) {
        return function (value1) {
            return new CoyonedaF(value0, value1);
        };
    };
    return CoyonedaF;
})();
var Coyoneda = function (x) {
    return x;
};
var unCoyoneda = function (f) {
    return function (v) {
        return Data_Exists.runExists(function (v1) {
            return f(v1.value0)(v1.value1);
        })(v);
    };
};
var lowerCoyoneda = function (dictFunctor) {
    return unCoyoneda(Data_Functor.map(dictFunctor));
};
var foldableCoyoneda = function (dictFoldable) {
    return {
        foldr: function (f) {
            return function (z) {
                return unCoyoneda(function (k) {
                    return Data_Foldable.foldr(dictFoldable)(function ($79) {
                        return f(k($79));
                    })(z);
                });
            };
        },
        foldl: function (f) {
            return function (z) {
                return unCoyoneda(function (k) {
                    return Data_Foldable.foldl(dictFoldable)(function (x) {
                        var $80 = f(x);
                        return function ($81) {
                            return $80(k($81));
                        };
                    })(z);
                });
            };
        },
        foldMap: function (dictMonoid) {
            return function (f) {
                return unCoyoneda(function (k) {
                    return Data_Foldable.foldMap(dictFoldable)(dictMonoid)(function ($82) {
                        return f(k($82));
                    });
                });
            };
        }
    };
};
var foldable1Coyoneda = function (dictFoldable1) {
    return {
        foldMap1: function (dictSemigroup) {
            return function (f) {
                return unCoyoneda(function (k) {
                    return Data_Semigroup_Foldable.foldMap1(dictFoldable1)(dictSemigroup)(function ($83) {
                        return f(k($83));
                    });
                });
            };
        },
        foldr1: Data_Semigroup_Foldable.foldr1Default(foldable1Coyoneda(dictFoldable1)),
        foldl1: Data_Semigroup_Foldable.foldl1Default(foldable1Coyoneda(dictFoldable1)),
        Foldable0: function () {
            return foldableCoyoneda(dictFoldable1.Foldable0());
        }
    };
};
var eqCoyoneda = function (dictFunctor) {
    return function (dictEq1) {
        return function (dictEq) {
            return {
                eq: function (x) {
                    return function (y) {
                        return Data_Eq.eq1(dictEq1)(dictEq)(lowerCoyoneda(dictFunctor)(x))(lowerCoyoneda(dictFunctor)(y));
                    };
                }
            };
        };
    };
};
var ordCoyoneda = function (dictFunctor) {
    return function (dictOrd1) {
        return function (dictOrd) {
            return {
                compare: function (x) {
                    return function (y) {
                        return Data_Ord.compare1(dictOrd1)(dictOrd)(lowerCoyoneda(dictFunctor)(x))(lowerCoyoneda(dictFunctor)(y));
                    };
                },
                Eq0: function () {
                    return eqCoyoneda(dictFunctor)(dictOrd1.Eq10())(dictOrd.Eq0());
                }
            };
        };
    };
};
var eq1Coyoneda = function (dictFunctor) {
    return function (dictEq1) {
        return {
            eq1: function (dictEq) {
                return Data_Eq.eq(eqCoyoneda(dictFunctor)(dictEq1)(dictEq));
            }
        };
    };
};
var ord1Coyoneda = function (dictFunctor) {
    return function (dictOrd1) {
        return {
            compare1: function (dictOrd) {
                return Data_Ord.compare(ordCoyoneda(dictFunctor)(dictOrd1)(dictOrd));
            },
            Eq10: function () {
                return eq1Coyoneda(dictFunctor)(dictOrd1.Eq10());
            }
        };
    };
};
var coyoneda = function (k) {
    return function (fi) {
        return Coyoneda(Data_Exists.mkExists(new CoyonedaF(k, fi)));
    };
};
var functorCoyoneda = {
    map: function (f) {
        return function (v) {
            return Data_Exists.runExists(function (v1) {
                return coyoneda(function ($84) {
                    return f(v1.value0($84));
                })(v1.value1);
            })(v);
        };
    }
};
var invatiantCoyoneda = {
    imap: Data_Functor_Invariant.imapF(functorCoyoneda)
};
var hoistCoyoneda = function (nat) {
    return function (v) {
        return Data_Exists.runExists(function (v1) {
            return coyoneda(v1.value0)(nat(v1.value1));
        })(v);
    };
};
var liftCoyoneda = coyoneda(Control_Category.identity(Control_Category.categoryFn));
var distributiveCoyoneda = function (dictDistributive) {
    return {
        collect: function (dictFunctor) {
            return function (f) {
                var $85 = Data_Distributive.collect(dictDistributive)(dictFunctor)((function () {
                    var $87 = lowerCoyoneda(dictDistributive.Functor0());
                    return function ($88) {
                        return $87(f($88));
                    };
                })());
                return function ($86) {
                    return liftCoyoneda($85($86));
                };
            };
        },
        distribute: function (dictFunctor) {
            var $89 = Data_Distributive.collect(dictDistributive)(dictFunctor)(lowerCoyoneda(dictDistributive.Functor0()));
            return function ($90) {
                return liftCoyoneda($89($90));
            };
        },
        Functor0: function () {
            return functorCoyoneda;
        }
    };
};
var extendCoyoneda = function (dictExtend) {
    return {
        extend: function (f) {
            return function (v) {
                return Data_Exists.runExists(function (v1) {
                    return liftCoyoneda(Control_Extend.extend(dictExtend)((function () {
                        var $91 = coyoneda(v1.value0);
                        return function ($92) {
                            return f($91($92));
                        };
                    })())(v1.value1));
                })(v);
            };
        },
        Functor0: function () {
            return functorCoyoneda;
        }
    };
};
var monadTransCoyoneda = {
    lift: function (dictMonad) {
        return liftCoyoneda;
    }
};
var traversableCoyoneda = function (dictTraversable) {
    return {
        traverse: function (dictApplicative) {
            return function (f) {
                return unCoyoneda(function (k) {
                    var $93 = Data_Functor.map((dictApplicative.Apply0()).Functor0())(liftCoyoneda);
                    var $94 = Data_Traversable.traverse(dictTraversable)(dictApplicative)(function ($96) {
                        return f(k($96));
                    });
                    return function ($95) {
                        return $93($94($95));
                    };
                });
            };
        },
        sequence: function (dictApplicative) {
            return unCoyoneda(function (k) {
                var $97 = Data_Functor.map((dictApplicative.Apply0()).Functor0())(liftCoyoneda);
                var $98 = Data_Traversable.traverse(dictTraversable)(dictApplicative)(k);
                return function ($99) {
                    return $97($98($99));
                };
            });
        },
        Functor0: function () {
            return functorCoyoneda;
        },
        Foldable1: function () {
            return foldableCoyoneda(dictTraversable.Foldable1());
        }
    };
};
var traversable1Coyoneda = function (dictTraversable1) {
    return {
        traverse1: function (dictApply) {
            return function (f) {
                return unCoyoneda(function (k) {
                    var $100 = Data_Functor.map(dictApply.Functor0())(liftCoyoneda);
                    var $101 = Data_Semigroup_Traversable.traverse1(dictTraversable1)(dictApply)(function ($103) {
                        return f(k($103));
                    });
                    return function ($102) {
                        return $100($101($102));
                    };
                });
            };
        },
        sequence1: function (dictApply) {
            return unCoyoneda(function (k) {
                var $104 = Data_Functor.map(dictApply.Functor0())(liftCoyoneda);
                var $105 = Data_Semigroup_Traversable.sequence1(dictTraversable1)(dictApply);
                var $106 = Data_Functor.map((dictTraversable1.Traversable1()).Functor0())(k);
                return function ($107) {
                    return $104($105($106($107)));
                };
            });
        },
        Foldable10: function () {
            return foldable1Coyoneda(dictTraversable1.Foldable10());
        },
        Traversable1: function () {
            return traversableCoyoneda(dictTraversable1.Traversable1());
        }
    };
};
var comonadCoyoneda = function (dictComonad) {
    return {
        extract: function (v) {
            return Data_Exists.runExists(function (v1) {
                return v1.value0(Control_Comonad.extract(dictComonad)(v1.value1));
            })(v);
        },
        Extend0: function () {
            return extendCoyoneda(dictComonad.Extend0());
        }
    };
};
var applyCoyoneda = function (dictApply) {
    return {
        apply: function (f) {
            return function (g) {
                return liftCoyoneda(Control_Apply.apply(dictApply)(lowerCoyoneda(dictApply.Functor0())(f))(lowerCoyoneda(dictApply.Functor0())(g)));
            };
        },
        Functor0: function () {
            return functorCoyoneda;
        }
    };
};
var bindCoyoneda = function (dictBind) {
    return {
        bind: function (v) {
            return function (f) {
                return liftCoyoneda(Data_Exists.runExists(function (v1) {
                    return Control_Bind.bindFlipped(dictBind)((function () {
                        var $108 = lowerCoyoneda((dictBind.Apply0()).Functor0());
                        return function ($109) {
                            return $108(f(v1.value0($109)));
                        };
                    })())(v1.value1);
                })(v));
            };
        },
        Apply0: function () {
            return applyCoyoneda(dictBind.Apply0());
        }
    };
};
var applicativeCoyoneda = function (dictApplicative) {
    return {
        pure: (function () {
            var $110 = Control_Applicative.pure(dictApplicative);
            return function ($111) {
                return liftCoyoneda($110($111));
            };
        })(),
        Apply0: function () {
            return applyCoyoneda(dictApplicative.Apply0());
        }
    };
};
var monadCoyoneda = function (dictMonad) {
    return {
        Applicative0: function () {
            return applicativeCoyoneda(dictMonad.Applicative0());
        },
        Bind1: function () {
            return bindCoyoneda(dictMonad.Bind1());
        }
    };
};
var altCoyoneda = function (dictAlt) {
    return {
        alt: function (x) {
            return function (y) {
                return liftCoyoneda(Control_Alt.alt(dictAlt)(lowerCoyoneda(dictAlt.Functor0())(x))(lowerCoyoneda(dictAlt.Functor0())(y)));
            };
        },
        Functor0: function () {
            return functorCoyoneda;
        }
    };
};
var plusCoyoneda = function (dictPlus) {
    return {
        empty: liftCoyoneda(Control_Plus.empty(dictPlus)),
        Alt0: function () {
            return altCoyoneda(dictPlus.Alt0());
        }
    };
};
var alternativeCoyoneda = function (dictAlternative) {
    return {
        Applicative0: function () {
            return applicativeCoyoneda(dictAlternative.Applicative0());
        },
        Plus1: function () {
            return plusCoyoneda(dictAlternative.Plus1());
        }
    };
};
var monadPlusCoyoneda = function (dictMonadPlus) {
    return {
        Monad0: function () {
            return monadCoyoneda(dictMonadPlus.Monad0());
        },
        Alternative1: function () {
            return alternativeCoyoneda(dictMonadPlus.Alternative1());
        }
    };
};
var monadZeroCoyoneda = function (dictMonadZero) {
    return {
        Monad0: function () {
            return monadCoyoneda(dictMonadZero.Monad0());
        },
        Alternative1: function () {
            return alternativeCoyoneda(dictMonadZero.Alternative1());
        },
        MonadZeroIsDeprecated2: function () {
            return undefined;
        }
    };
};
module.exports = {
    Coyoneda: Coyoneda,
    coyoneda: coyoneda,
    unCoyoneda: unCoyoneda,
    liftCoyoneda: liftCoyoneda,
    lowerCoyoneda: lowerCoyoneda,
    hoistCoyoneda: hoistCoyoneda,
    eqCoyoneda: eqCoyoneda,
    eq1Coyoneda: eq1Coyoneda,
    ordCoyoneda: ordCoyoneda,
    ord1Coyoneda: ord1Coyoneda,
    functorCoyoneda: functorCoyoneda,
    invatiantCoyoneda: invatiantCoyoneda,
    applyCoyoneda: applyCoyoneda,
    applicativeCoyoneda: applicativeCoyoneda,
    altCoyoneda: altCoyoneda,
    plusCoyoneda: plusCoyoneda,
    alternativeCoyoneda: alternativeCoyoneda,
    bindCoyoneda: bindCoyoneda,
    monadCoyoneda: monadCoyoneda,
    monadTransCoyoneda: monadTransCoyoneda,
    monadZeroCoyoneda: monadZeroCoyoneda,
    monadPlusCoyoneda: monadPlusCoyoneda,
    extendCoyoneda: extendCoyoneda,
    comonadCoyoneda: comonadCoyoneda,
    foldableCoyoneda: foldableCoyoneda,
    traversableCoyoneda: traversableCoyoneda,
    foldable1Coyoneda: foldable1Coyoneda,
    traversable1Coyoneda: traversable1Coyoneda,
    distributiveCoyoneda: distributiveCoyoneda
};
