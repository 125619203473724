// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Monoid_Conj = require("../Data.Monoid.Conj/index.js");
var Data_Monoid_Disj = require("../Data.Monoid.Disj/index.js");
var Data_Monoid_Dual = require("../Data.Monoid.Dual/index.js");
var Data_Monoid_Endo = require("../Data.Monoid.Endo/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var bifoldr = function (dict) {
    return dict.bifoldr;
};
var bitraverse_ = function (dictBifoldable) {
    return function (dictApplicative) {
        return function (f) {
            return function (g) {
                return bifoldr(dictBifoldable)((function () {
                    var $150 = Control_Apply.applySecond(dictApplicative.Apply0());
                    return function ($151) {
                        return $150(f($151));
                    };
                })())((function () {
                    var $152 = Control_Apply.applySecond(dictApplicative.Apply0());
                    return function ($153) {
                        return $152(g($153));
                    };
                })())(Control_Applicative.pure(dictApplicative)(Data_Unit.unit));
            };
        };
    };
};
var bifor_ = function (dictBifoldable) {
    return function (dictApplicative) {
        return function (t) {
            return function (f) {
                return function (g) {
                    return bitraverse_(dictBifoldable)(dictApplicative)(f)(g)(t);
                };
            };
        };
    };
};
var bisequence_ = function (dictBifoldable) {
    return function (dictApplicative) {
        return bitraverse_(dictBifoldable)(dictApplicative)(Control_Category.identity(Control_Category.categoryFn))(Control_Category.identity(Control_Category.categoryFn));
    };
};
var bifoldl = function (dict) {
    return dict.bifoldl;
};
var bifoldableTuple = {
    bifoldMap: function (dictMonoid) {
        return function (f) {
            return function (g) {
                return function (v) {
                    return Data_Semigroup.append(dictMonoid.Semigroup0())(f(v.value0))(g(v.value1));
                };
            };
        };
    },
    bifoldr: function (f) {
        return function (g) {
            return function (z) {
                return function (v) {
                    return f(v.value0)(g(v.value1)(z));
                };
            };
        };
    },
    bifoldl: function (f) {
        return function (g) {
            return function (z) {
                return function (v) {
                    return g(f(z)(v.value0))(v.value1);
                };
            };
        };
    }
};
var bifoldableJoker = function (dictFoldable) {
    return {
        bifoldr: function (v) {
            return function (r) {
                return function (u) {
                    return function (v1) {
                        return Data_Foldable.foldr(dictFoldable)(r)(u)(v1);
                    };
                };
            };
        },
        bifoldl: function (v) {
            return function (r) {
                return function (u) {
                    return function (v1) {
                        return Data_Foldable.foldl(dictFoldable)(r)(u)(v1);
                    };
                };
            };
        },
        bifoldMap: function (dictMonoid) {
            return function (v) {
                return function (r) {
                    return function (v1) {
                        return Data_Foldable.foldMap(dictFoldable)(dictMonoid)(r)(v1);
                    };
                };
            };
        }
    };
};
var bifoldableEither = {
    bifoldr: function (v) {
        return function (v1) {
            return function (z) {
                return function (v2) {
                    if (v2 instanceof Data_Either.Left) {
                        return v(v2.value0)(z);
                    };
                    if (v2 instanceof Data_Either.Right) {
                        return v1(v2.value0)(z);
                    };
                    throw new Error("Failed pattern match at Data.Bifoldable (line 62, column 1 - line 68, column 32): " + [ v.constructor.name, v1.constructor.name, z.constructor.name, v2.constructor.name ]);
                };
            };
        };
    },
    bifoldl: function (v) {
        return function (v1) {
            return function (z) {
                return function (v2) {
                    if (v2 instanceof Data_Either.Left) {
                        return v(z)(v2.value0);
                    };
                    if (v2 instanceof Data_Either.Right) {
                        return v1(z)(v2.value0);
                    };
                    throw new Error("Failed pattern match at Data.Bifoldable (line 62, column 1 - line 68, column 32): " + [ v.constructor.name, v1.constructor.name, z.constructor.name, v2.constructor.name ]);
                };
            };
        };
    },
    bifoldMap: function (dictMonoid) {
        return function (v) {
            return function (v1) {
                return function (v2) {
                    if (v2 instanceof Data_Either.Left) {
                        return v(v2.value0);
                    };
                    if (v2 instanceof Data_Either.Right) {
                        return v1(v2.value0);
                    };
                    throw new Error("Failed pattern match at Data.Bifoldable (line 62, column 1 - line 68, column 32): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
                };
            };
        };
    }
};
var bifoldableConst = {
    bifoldr: function (f) {
        return function (v) {
            return function (z) {
                return function (v1) {
                    return f(v1)(z);
                };
            };
        };
    },
    bifoldl: function (f) {
        return function (v) {
            return function (z) {
                return function (v1) {
                    return f(z)(v1);
                };
            };
        };
    },
    bifoldMap: function (dictMonoid) {
        return function (f) {
            return function (v) {
                return function (v1) {
                    return f(v1);
                };
            };
        };
    }
};
var bifoldableClown = function (dictFoldable) {
    return {
        bifoldr: function (l) {
            return function (v) {
                return function (u) {
                    return function (v1) {
                        return Data_Foldable.foldr(dictFoldable)(l)(u)(v1);
                    };
                };
            };
        },
        bifoldl: function (l) {
            return function (v) {
                return function (u) {
                    return function (v1) {
                        return Data_Foldable.foldl(dictFoldable)(l)(u)(v1);
                    };
                };
            };
        },
        bifoldMap: function (dictMonoid) {
            return function (l) {
                return function (v) {
                    return function (v1) {
                        return Data_Foldable.foldMap(dictFoldable)(dictMonoid)(l)(v1);
                    };
                };
            };
        }
    };
};
var bifoldMapDefaultR = function (dictBifoldable) {
    return function (dictMonoid) {
        return function (f) {
            return function (g) {
                return bifoldr(dictBifoldable)((function () {
                    var $154 = Data_Semigroup.append(dictMonoid.Semigroup0());
                    return function ($155) {
                        return $154(f($155));
                    };
                })())((function () {
                    var $156 = Data_Semigroup.append(dictMonoid.Semigroup0());
                    return function ($157) {
                        return $156(g($157));
                    };
                })())(Data_Monoid.mempty(dictMonoid));
            };
        };
    };
};
var bifoldMapDefaultL = function (dictBifoldable) {
    return function (dictMonoid) {
        return function (f) {
            return function (g) {
                return bifoldl(dictBifoldable)(function (m) {
                    return function (a) {
                        return Data_Semigroup.append(dictMonoid.Semigroup0())(m)(f(a));
                    };
                })(function (m) {
                    return function (b) {
                        return Data_Semigroup.append(dictMonoid.Semigroup0())(m)(g(b));
                    };
                })(Data_Monoid.mempty(dictMonoid));
            };
        };
    };
};
var bifoldMap = function (dict) {
    return dict.bifoldMap;
};
var bifoldableFlip = function (dictBifoldable) {
    return {
        bifoldr: function (r) {
            return function (l) {
                return function (u) {
                    return function (v) {
                        return bifoldr(dictBifoldable)(l)(r)(u)(v);
                    };
                };
            };
        },
        bifoldl: function (r) {
            return function (l) {
                return function (u) {
                    return function (v) {
                        return bifoldl(dictBifoldable)(l)(r)(u)(v);
                    };
                };
            };
        },
        bifoldMap: function (dictMonoid) {
            return function (r) {
                return function (l) {
                    return function (v) {
                        return bifoldMap(dictBifoldable)(dictMonoid)(l)(r)(v);
                    };
                };
            };
        }
    };
};
var bifoldlDefault = function (dictBifoldable) {
    return function (f) {
        return function (g) {
            return function (z) {
                return function (p) {
                    return Data_Newtype.unwrap()(Data_Newtype.unwrap()(bifoldMap(dictBifoldable)(Data_Monoid_Dual.monoidDual(Data_Monoid_Endo.monoidEndo(Control_Category.categoryFn)))((function () {
                        var $158 = Data_Function.flip(f);
                        return function ($159) {
                            return Data_Monoid_Dual.Dual(Data_Monoid_Endo.Endo($158($159)));
                        };
                    })())((function () {
                        var $160 = Data_Function.flip(g);
                        return function ($161) {
                            return Data_Monoid_Dual.Dual(Data_Monoid_Endo.Endo($160($161)));
                        };
                    })())(p)))(z);
                };
            };
        };
    };
};
var bifoldrDefault = function (dictBifoldable) {
    return function (f) {
        return function (g) {
            return function (z) {
                return function (p) {
                    return Data_Newtype.unwrap()(bifoldMap(dictBifoldable)(Data_Monoid_Endo.monoidEndo(Control_Category.categoryFn))(function ($162) {
                        return Data_Monoid_Endo.Endo(f($162));
                    })(function ($163) {
                        return Data_Monoid_Endo.Endo(g($163));
                    })(p))(z);
                };
            };
        };
    };
};
var bifoldableProduct2 = function (dictBifoldable) {
    return function (dictBifoldable1) {
        return {
            bifoldr: function (l) {
                return function (r) {
                    return function (u) {
                        return function (m) {
                            return bifoldrDefault(bifoldableProduct2(dictBifoldable)(dictBifoldable1))(l)(r)(u)(m);
                        };
                    };
                };
            },
            bifoldl: function (l) {
                return function (r) {
                    return function (u) {
                        return function (m) {
                            return bifoldlDefault(bifoldableProduct2(dictBifoldable)(dictBifoldable1))(l)(r)(u)(m);
                        };
                    };
                };
            },
            bifoldMap: function (dictMonoid) {
                return function (l) {
                    return function (r) {
                        return function (v) {
                            return Data_Semigroup.append(dictMonoid.Semigroup0())(bifoldMap(dictBifoldable)(dictMonoid)(l)(r)(v.value0))(bifoldMap(dictBifoldable1)(dictMonoid)(l)(r)(v.value1));
                        };
                    };
                };
            }
        };
    };
};
var bifold = function (dictBifoldable) {
    return function (dictMonoid) {
        return bifoldMap(dictBifoldable)(dictMonoid)(Control_Category.identity(Control_Category.categoryFn))(Control_Category.identity(Control_Category.categoryFn));
    };
};
var biany = function (dictBifoldable) {
    return function (dictBooleanAlgebra) {
        return function (p) {
            return function (q) {
                var $164 = Data_Newtype.unwrap();
                var $165 = bifoldMap(dictBifoldable)(Data_Monoid_Disj.monoidDisj(dictBooleanAlgebra.HeytingAlgebra0()))(function ($167) {
                    return Data_Monoid_Disj.Disj(p($167));
                })(function ($168) {
                    return Data_Monoid_Disj.Disj(q($168));
                });
                return function ($166) {
                    return $164($165($166));
                };
            };
        };
    };
};
var biall = function (dictBifoldable) {
    return function (dictBooleanAlgebra) {
        return function (p) {
            return function (q) {
                var $169 = Data_Newtype.unwrap();
                var $170 = bifoldMap(dictBifoldable)(Data_Monoid_Conj.monoidConj(dictBooleanAlgebra.HeytingAlgebra0()))(function ($172) {
                    return Data_Monoid_Conj.Conj(p($172));
                })(function ($173) {
                    return Data_Monoid_Conj.Conj(q($173));
                });
                return function ($171) {
                    return $169($170($171));
                };
            };
        };
    };
};
module.exports = {
    bifoldMap: bifoldMap,
    bifoldl: bifoldl,
    bifoldr: bifoldr,
    bifoldrDefault: bifoldrDefault,
    bifoldlDefault: bifoldlDefault,
    bifoldMapDefaultR: bifoldMapDefaultR,
    bifoldMapDefaultL: bifoldMapDefaultL,
    bifold: bifold,
    bitraverse_: bitraverse_,
    bifor_: bifor_,
    bisequence_: bisequence_,
    biany: biany,
    biall: biall,
    bifoldableClown: bifoldableClown,
    bifoldableJoker: bifoldableJoker,
    bifoldableFlip: bifoldableFlip,
    bifoldableProduct2: bifoldableProduct2,
    bifoldableEither: bifoldableEither,
    bifoldableTuple: bifoldableTuple,
    bifoldableConst: bifoldableConst
};
