// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Functor_Contravariant = require("../Data.Functor.Contravariant/index.js");
var Data_Show = require("../Data.Show/index.js");
var Clown = function (x) {
    return x;
};
var showClown = function (dictShow) {
    return {
        show: function (v) {
            return "(Clown " + (Data_Show.show(dictShow)(v) + ")");
        }
    };
};
var profunctorClown = function (dictContravariant) {
    return {
        dimap: function (f) {
            return function (v) {
                return function (v1) {
                    return Data_Functor_Contravariant.cmap(dictContravariant)(f)(v1);
                };
            };
        }
    };
};
var ordClown = function (dictOrd) {
    return dictOrd;
};
var newtypeClown = {
    Coercible0: function () {
        return undefined;
    }
};
var hoistClown = function (f) {
    return function (v) {
        return f(v);
    };
};
var functorClown = {
    map: function (v) {
        return function (v1) {
            return v1;
        };
    }
};
var eqClown = function (dictEq) {
    return dictEq;
};
var bifunctorClown = function (dictFunctor) {
    return {
        bimap: function (f) {
            return function (v) {
                return function (v1) {
                    return Data_Functor.map(dictFunctor)(f)(v1);
                };
            };
        }
    };
};
var biapplyClown = function (dictApply) {
    return {
        biapply: function (v) {
            return function (v1) {
                return Control_Apply.apply(dictApply)(v)(v1);
            };
        },
        Bifunctor0: function () {
            return bifunctorClown(dictApply.Functor0());
        }
    };
};
var biapplicativeClown = function (dictApplicative) {
    return {
        bipure: function (a) {
            return function (v) {
                return Control_Applicative.pure(dictApplicative)(a);
            };
        },
        Biapply0: function () {
            return biapplyClown(dictApplicative.Apply0());
        }
    };
};
module.exports = {
    Clown: Clown,
    hoistClown: hoistClown,
    newtypeClown: newtypeClown,
    eqClown: eqClown,
    ordClown: ordClown,
    showClown: showClown,
    functorClown: functorClown,
    bifunctorClown: bifunctorClown,
    biapplyClown: biapplyClown,
    biapplicativeClown: biapplicativeClown,
    profunctorClown: profunctorClown
};
