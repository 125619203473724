// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_String_Base64_Internal = require("../Data.String.Base64.Internal/index.js");
var Data_TextDecoder = require("../Data.TextDecoder/index.js");
var Data_TextEncoder = require("../Data.TextEncoder/index.js");
var encodeNode = function (s) {
    return $foreign.encodeNodeImpl(s);
};
var btoa = function (str) {
    return $foreign.btoaImpl(Data_Either.Left.create, Data_Either.Right.create, str);
};
var encode = function (str) {
    if (Data_String_Base64_Internal.btoaIsDefined) {
        return Data_String_Base64_Internal.unsafeFromRight(btoa(Data_String_Base64_Internal.uint8ArrayToBtoaSafeString(Data_TextEncoder.encodeUtf8(str))));
    };
    return encodeNode(str);
};
var encodeUrl = function ($2) {
    return Data_String_Base64_Internal.toUrlSafe(encode($2));
};
var atob = function (str) {
    return $foreign.atobImpl(Data_Either.Left.create, Data_Either.Right.create, str);
};
var decode = function (str) {
    if (Data_String_Base64_Internal.atobIsDefined) {
        return Control_Bind.bind(Data_Either.bindEither)(Data_Functor.map(Data_Either.functorEither)(Data_String_Base64_Internal.unsafeStringToUint8ArrayOfCharCodes)(atob(Data_String_Base64_Internal.toRfc4648(str))))(Data_TextDecoder.decodeUtf8);
    };
    return $foreign.decodeNodeImpl(Data_Either.Left.create, Data_Either.Right.create, Data_String_Base64_Internal.toRfc4648(str));
};
module.exports = {
    atob: atob,
    btoa: btoa,
    decode: decode,
    encode: encode,
    encodeUrl: encodeUrl
};
