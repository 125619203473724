// Generated by purs version 0.14.5
"use strict";
var Control_Biapplicative = require("../Control.Biapplicative/index.js");
var Control_Biapply = require("../Control.Biapply/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Data_Bifunctor = require("../Data.Bifunctor/index.js");
var Data_Profunctor = require("../Data.Profunctor/index.js");
var Data_Show = require("../Data.Show/index.js");
var Flip = function (x) {
    return x;
};
var showFlip = function (dictShow) {
    return {
        show: function (v) {
            return "(Flip " + (Data_Show.show(dictShow)(v) + ")");
        }
    };
};
var semigroupoidFlip = function (dictSemigroupoid) {
    return {
        compose: function (v) {
            return function (v1) {
                return Flip(Control_Semigroupoid.compose(dictSemigroupoid)(v1)(v));
            };
        }
    };
};
var ordFlip = function (dictOrd) {
    return dictOrd;
};
var newtypeFlip = {
    Coercible0: function () {
        return undefined;
    }
};
var functorFlip = function (dictBifunctor) {
    return {
        map: function (f) {
            return function (v) {
                return Data_Bifunctor.lmap(dictBifunctor)(f)(v);
            };
        }
    };
};
var eqFlip = function (dictEq) {
    return dictEq;
};
var contravariantFlip = function (dictProfunctor) {
    return {
        cmap: function (f) {
            return function (v) {
                return Data_Profunctor.lcmap(dictProfunctor)(f)(v);
            };
        }
    };
};
var categoryFlip = function (dictCategory) {
    return {
        identity: Control_Category.identity(dictCategory),
        Semigroupoid0: function () {
            return semigroupoidFlip(dictCategory.Semigroupoid0());
        }
    };
};
var bifunctorFlip = function (dictBifunctor) {
    return {
        bimap: function (f) {
            return function (g) {
                return function (v) {
                    return Data_Bifunctor.bimap(dictBifunctor)(g)(f)(v);
                };
            };
        }
    };
};
var biapplyFlip = function (dictBiapply) {
    return {
        biapply: function (v) {
            return function (v1) {
                return Control_Biapply.biapply(dictBiapply)(v)(v1);
            };
        },
        Bifunctor0: function () {
            return bifunctorFlip(dictBiapply.Bifunctor0());
        }
    };
};
var biapplicativeFlip = function (dictBiapplicative) {
    return {
        bipure: function (a) {
            return function (b) {
                return Control_Biapplicative.bipure(dictBiapplicative)(b)(a);
            };
        },
        Biapply0: function () {
            return biapplyFlip(dictBiapplicative.Biapply0());
        }
    };
};
module.exports = {
    Flip: Flip,
    newtypeFlip: newtypeFlip,
    eqFlip: eqFlip,
    ordFlip: ordFlip,
    showFlip: showFlip,
    functorFlip: functorFlip,
    bifunctorFlip: bifunctorFlip,
    biapplyFlip: biapplyFlip,
    biapplicativeFlip: biapplicativeFlip,
    contravariantFlip: contravariantFlip,
    semigroupoidFlip: semigroupoidFlip,
    categoryFlip: categoryFlip
};
