// Generated by purs version 0.14.5
"use strict";
var Data_Enum = require("../Data.Enum/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var HeadSlot = (function () {
    function HeadSlot() {

    };
    HeadSlot.value = new HeadSlot();
    return HeadSlot;
})();
var NeckSlot = (function () {
    function NeckSlot() {

    };
    NeckSlot.value = new NeckSlot();
    return NeckSlot;
})();
var CapeSlot = (function () {
    function CapeSlot() {

    };
    CapeSlot.value = new CapeSlot();
    return CapeSlot;
})();
var BodySlot = (function () {
    function BodySlot() {

    };
    BodySlot.value = new BodySlot();
    return BodySlot;
})();
var LegsSlot = (function () {
    function LegsSlot() {

    };
    LegsSlot.value = new LegsSlot();
    return LegsSlot;
})();
var WeaponSlot = (function () {
    function WeaponSlot() {

    };
    WeaponSlot.value = new WeaponSlot();
    return WeaponSlot;
})();
var ShieldSlot = (function () {
    function ShieldSlot() {

    };
    ShieldSlot.value = new ShieldSlot();
    return ShieldSlot;
})();
var AmmoSlot = (function () {
    function AmmoSlot() {

    };
    AmmoSlot.value = new AmmoSlot();
    return AmmoSlot;
})();
var HandsSlot = (function () {
    function HandsSlot() {

    };
    HandsSlot.value = new HandsSlot();
    return HandsSlot;
})();
var FeetSlot = (function () {
    function FeetSlot() {

    };
    FeetSlot.value = new FeetSlot();
    return FeetSlot;
})();
var RingSlot = (function () {
    function RingSlot() {

    };
    RingSlot.value = new RingSlot();
    return RingSlot;
})();
var eqEquipmentSlot = {
    eq: function (x) {
        return function (y) {
            if (x instanceof HeadSlot && y instanceof HeadSlot) {
                return true;
            };
            if (x instanceof NeckSlot && y instanceof NeckSlot) {
                return true;
            };
            if (x instanceof CapeSlot && y instanceof CapeSlot) {
                return true;
            };
            if (x instanceof BodySlot && y instanceof BodySlot) {
                return true;
            };
            if (x instanceof LegsSlot && y instanceof LegsSlot) {
                return true;
            };
            if (x instanceof WeaponSlot && y instanceof WeaponSlot) {
                return true;
            };
            if (x instanceof ShieldSlot && y instanceof ShieldSlot) {
                return true;
            };
            if (x instanceof AmmoSlot && y instanceof AmmoSlot) {
                return true;
            };
            if (x instanceof HandsSlot && y instanceof HandsSlot) {
                return true;
            };
            if (x instanceof FeetSlot && y instanceof FeetSlot) {
                return true;
            };
            if (x instanceof RingSlot && y instanceof RingSlot) {
                return true;
            };
            return false;
        };
    }
};
var ordEquipmentSlot = {
    compare: function (x) {
        return function (y) {
            if (x instanceof HeadSlot && y instanceof HeadSlot) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof HeadSlot) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof HeadSlot) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof NeckSlot && y instanceof NeckSlot) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof NeckSlot) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof NeckSlot) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof CapeSlot && y instanceof CapeSlot) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof CapeSlot) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof CapeSlot) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof BodySlot && y instanceof BodySlot) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof BodySlot) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof BodySlot) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof LegsSlot && y instanceof LegsSlot) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof LegsSlot) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof LegsSlot) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof WeaponSlot && y instanceof WeaponSlot) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof WeaponSlot) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof WeaponSlot) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ShieldSlot && y instanceof ShieldSlot) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ShieldSlot) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ShieldSlot) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AmmoSlot && y instanceof AmmoSlot) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AmmoSlot) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AmmoSlot) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof HandsSlot && y instanceof HandsSlot) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof HandsSlot) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof HandsSlot) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof FeetSlot && y instanceof FeetSlot) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof FeetSlot) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof FeetSlot) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof RingSlot && y instanceof RingSlot) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at SixtySixItems.Domain.EquipmentSlot (line 22, column 1 - line 22, column 34): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqEquipmentSlot;
    }
};
var enumEquipmentSlot = {
    succ: function (v) {
        if (v instanceof HeadSlot) {
            return new Data_Maybe.Just(NeckSlot.value);
        };
        if (v instanceof NeckSlot) {
            return new Data_Maybe.Just(CapeSlot.value);
        };
        if (v instanceof CapeSlot) {
            return new Data_Maybe.Just(BodySlot.value);
        };
        if (v instanceof BodySlot) {
            return new Data_Maybe.Just(LegsSlot.value);
        };
        if (v instanceof LegsSlot) {
            return new Data_Maybe.Just(WeaponSlot.value);
        };
        if (v instanceof WeaponSlot) {
            return new Data_Maybe.Just(ShieldSlot.value);
        };
        if (v instanceof ShieldSlot) {
            return new Data_Maybe.Just(AmmoSlot.value);
        };
        if (v instanceof AmmoSlot) {
            return new Data_Maybe.Just(HandsSlot.value);
        };
        if (v instanceof HandsSlot) {
            return new Data_Maybe.Just(FeetSlot.value);
        };
        if (v instanceof FeetSlot) {
            return new Data_Maybe.Just(RingSlot.value);
        };
        if (v instanceof RingSlot) {
            return Data_Maybe.Nothing.value;
        };
        throw new Error("Failed pattern match at SixtySixItems.Domain.EquipmentSlot (line 25, column 10 - line 36, column 24): " + [ v.constructor.name ]);
    },
    pred: function (v) {
        if (v instanceof HeadSlot) {
            return Data_Maybe.Nothing.value;
        };
        if (v instanceof NeckSlot) {
            return new Data_Maybe.Just(HeadSlot.value);
        };
        if (v instanceof CapeSlot) {
            return new Data_Maybe.Just(NeckSlot.value);
        };
        if (v instanceof BodySlot) {
            return new Data_Maybe.Just(CapeSlot.value);
        };
        if (v instanceof LegsSlot) {
            return new Data_Maybe.Just(BodySlot.value);
        };
        if (v instanceof WeaponSlot) {
            return new Data_Maybe.Just(LegsSlot.value);
        };
        if (v instanceof ShieldSlot) {
            return new Data_Maybe.Just(WeaponSlot.value);
        };
        if (v instanceof AmmoSlot) {
            return new Data_Maybe.Just(ShieldSlot.value);
        };
        if (v instanceof HandsSlot) {
            return new Data_Maybe.Just(AmmoSlot.value);
        };
        if (v instanceof FeetSlot) {
            return new Data_Maybe.Just(HandsSlot.value);
        };
        if (v instanceof RingSlot) {
            return new Data_Maybe.Just(FeetSlot.value);
        };
        throw new Error("Failed pattern match at SixtySixItems.Domain.EquipmentSlot (line 38, column 10 - line 49, column 30): " + [ v.constructor.name ]);
    },
    Ord0: function () {
        return ordEquipmentSlot;
    }
};
var boundedEquipmentSlot = {
    bottom: HeadSlot.value,
    top: RingSlot.value,
    Ord0: function () {
        return ordEquipmentSlot;
    }
};
var boundedEnumEquipmentSlot = {
    cardinality: Data_Enum.defaultCardinality(boundedEquipmentSlot)(enumEquipmentSlot),
    toEnum: Data_Enum.defaultToEnum(boundedEquipmentSlot)(enumEquipmentSlot),
    fromEnum: Data_Enum.defaultFromEnum(enumEquipmentSlot),
    Bounded0: function () {
        return boundedEquipmentSlot;
    },
    Enum1: function () {
        return enumEquipmentSlot;
    }
};
var displayName = function (v) {
    if (v instanceof HeadSlot) {
        return "Head";
    };
    if (v instanceof NeckSlot) {
        return "Neck";
    };
    if (v instanceof CapeSlot) {
        return "Cape";
    };
    if (v instanceof BodySlot) {
        return "Body";
    };
    if (v instanceof LegsSlot) {
        return "Legs";
    };
    if (v instanceof WeaponSlot) {
        return "Weapon";
    };
    if (v instanceof ShieldSlot) {
        return "Shield";
    };
    if (v instanceof AmmoSlot) {
        return "Ammo";
    };
    if (v instanceof HandsSlot) {
        return "Hands";
    };
    if (v instanceof FeetSlot) {
        return "Feet";
    };
    if (v instanceof RingSlot) {
        return "Ring";
    };
    throw new Error("Failed pattern match at SixtySixItems.Domain.EquipmentSlot (line 61, column 15 - line 72, column 21): " + [ v.constructor.name ]);
};
module.exports = {
    HeadSlot: HeadSlot,
    NeckSlot: NeckSlot,
    CapeSlot: CapeSlot,
    BodySlot: BodySlot,
    LegsSlot: LegsSlot,
    WeaponSlot: WeaponSlot,
    ShieldSlot: ShieldSlot,
    AmmoSlot: AmmoSlot,
    HandsSlot: HandsSlot,
    FeetSlot: FeetSlot,
    RingSlot: RingSlot,
    displayName: displayName,
    eqEquipmentSlot: eqEquipmentSlot,
    ordEquipmentSlot: ordEquipmentSlot,
    enumEquipmentSlot: enumEquipmentSlot,
    boundedEquipmentSlot: boundedEquipmentSlot,
    boundedEnumEquipmentSlot: boundedEnumEquipmentSlot
};
