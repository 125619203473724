function main() {
  require('./output/Main').main();
}

if (module.hot) {
  module.hot.accept(() => {
    document.getElementById('sixty-six-items').innerHTML = '';

    main();
  });
}

main();
