// Generated by purs version 0.14.5
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var ItemId = function (x) {
    return x;
};
var ordItemId = Data_Ord.ordInt;
var newtypeItemId_ = {
    Coercible0: function () {
        return undefined;
    }
};
var eqItemId = Data_Eq.eqInt;
module.exports = {
    ItemId: ItemId,
    newtypeItemId_: newtypeItemId_,
    eqItemId: eqItemId,
    ordItemId: ordItemId
};
