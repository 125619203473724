// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");
var Web_Internal_FFI = require("../Web.Internal.FFI/index.js");
var toParentNode = Unsafe_Coerce.unsafeCoerce;
var toNonDocumentTypeChildNode = Unsafe_Coerce.unsafeCoerce;
var toNode = Unsafe_Coerce.unsafeCoerce;
var toHTMLMediaElement = Unsafe_Coerce.unsafeCoerce;
var toHTMLElement = Unsafe_Coerce.unsafeCoerce;
var toEventTarget = Unsafe_Coerce.unsafeCoerce;
var toElement = Unsafe_Coerce.unsafeCoerce;
var toChildNode = Unsafe_Coerce.unsafeCoerce;
var fromParentNode = Web_Internal_FFI.unsafeReadProtoTagged("HTMLAudioElement");
var fromNonDocumentTypeChildNode = Web_Internal_FFI.unsafeReadProtoTagged("HTMLAudioElement");
var fromNode = Web_Internal_FFI.unsafeReadProtoTagged("HTMLAudioElement");
var fromHTMLMediaElement = Web_Internal_FFI.unsafeReadProtoTagged("HTMLAudioElement");
var fromHTMLElement = Web_Internal_FFI.unsafeReadProtoTagged("HTMLAudioElement");
var fromEventTarget = Web_Internal_FFI.unsafeReadProtoTagged("HTMLAudioElement");
var fromElement = Web_Internal_FFI.unsafeReadProtoTagged("HTMLAudioElement");
var fromChildNode = Web_Internal_FFI.unsafeReadProtoTagged("HTMLAudioElement");
var create$prime = $foreign.createWithURL;
module.exports = {
    fromHTMLMediaElement: fromHTMLMediaElement,
    fromHTMLElement: fromHTMLElement,
    fromElement: fromElement,
    fromNode: fromNode,
    fromChildNode: fromChildNode,
    fromNonDocumentTypeChildNode: fromNonDocumentTypeChildNode,
    fromParentNode: fromParentNode,
    fromEventTarget: fromEventTarget,
    toHTMLMediaElement: toHTMLMediaElement,
    toHTMLElement: toHTMLElement,
    toElement: toElement,
    toNode: toNode,
    toChildNode: toChildNode,
    toNonDocumentTypeChildNode: toNonDocumentTypeChildNode,
    toParentNode: toParentNode,
    toEventTarget: toEventTarget,
    "create'": create$prime,
    create: $foreign.create
};
