// Generated by purs version 0.14.5
"use strict";
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Last = function (x) {
    return x;
};
var showLast = function (dictShow) {
    return {
        show: function (v) {
            return "(Last " + (Data_Show.show(Data_Maybe.showMaybe(dictShow))(v) + ")");
        }
    };
};
var semigroupLast = {
    append: function (v) {
        return function (v1) {
            if (v1 instanceof Data_Maybe.Just) {
                return v1;
            };
            if (v1 instanceof Data_Maybe.Nothing) {
                return v;
            };
            throw new Error("Failed pattern match at Data.Maybe.Last (line 52, column 1 - line 54, column 36): " + [ v.constructor.name, v1.constructor.name ]);
        };
    }
};
var ordLast = function (dictOrd) {
    return Data_Maybe.ordMaybe(dictOrd);
};
var ord1Last = Data_Maybe.ord1Maybe;
var newtypeLast = {
    Coercible0: function () {
        return undefined;
    }
};
var monoidLast = {
    mempty: Data_Maybe.Nothing.value,
    Semigroup0: function () {
        return semigroupLast;
    }
};
var monadLast = Data_Maybe.monadMaybe;
var invariantLast = Data_Maybe.invariantMaybe;
var functorLast = Data_Maybe.functorMaybe;
var extendLast = Data_Maybe.extendMaybe;
var eqLast = function (dictEq) {
    return Data_Maybe.eqMaybe(dictEq);
};
var eq1Last = Data_Maybe.eq1Maybe;
var boundedLast = function (dictBounded) {
    return Data_Maybe.boundedMaybe(dictBounded);
};
var bindLast = Data_Maybe.bindMaybe;
var applyLast = Data_Maybe.applyMaybe;
var applicativeLast = Data_Maybe.applicativeMaybe;
var altLast = {
    alt: Data_Semigroup.append(semigroupLast),
    Functor0: function () {
        return functorLast;
    }
};
var plusLast = {
    empty: Data_Monoid.mempty(monoidLast),
    Alt0: function () {
        return altLast;
    }
};
var alternativeLast = {
    Applicative0: function () {
        return applicativeLast;
    },
    Plus1: function () {
        return plusLast;
    }
};
var monadZeroLast = {
    Monad0: function () {
        return monadLast;
    },
    Alternative1: function () {
        return alternativeLast;
    },
    MonadZeroIsDeprecated2: function () {
        return undefined;
    }
};
module.exports = {
    Last: Last,
    newtypeLast: newtypeLast,
    eqLast: eqLast,
    eq1Last: eq1Last,
    ordLast: ordLast,
    ord1Last: ord1Last,
    boundedLast: boundedLast,
    functorLast: functorLast,
    invariantLast: invariantLast,
    applyLast: applyLast,
    applicativeLast: applicativeLast,
    bindLast: bindLast,
    monadLast: monadLast,
    extendLast: extendLast,
    showLast: showLast,
    semigroupLast: semigroupLast,
    monoidLast: monoidLast,
    altLast: altLast,
    plusLast: plusLast,
    alternativeLast: alternativeLast,
    monadZeroLast: monadZeroLast
};
