// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Data_Eq = require("../Data.Eq/index.js");
var UnsafeRefEqFallback = function (x) {
    return x;
};
var UnsafeRefEq = function (x) {
    return x;
};
var unsafeRefEq = $foreign.reallyUnsafeRefEq;
var eqUnsafeRefEqFallback = function (dictEq) {
    return {
        eq: function (v) {
            return function (v1) {
                return unsafeRefEq(v)(v1) || Data_Eq.eq(dictEq)(v)(v1);
            };
        }
    };
};
var eqUnsafeRefEq = {
    eq: function (v) {
        return function (v1) {
            return unsafeRefEq(v)(v1);
        };
    }
};
module.exports = {
    unsafeRefEq: unsafeRefEq,
    UnsafeRefEq: UnsafeRefEq,
    UnsafeRefEqFallback: UnsafeRefEqFallback,
    eqUnsafeRefEq: eqUnsafeRefEq,
    eqUnsafeRefEqFallback: eqUnsafeRefEqFallback,
    reallyUnsafeRefEq: $foreign.reallyUnsafeRefEq
};
