// Generated by purs version 0.14.5
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var SixtySixItems_Domain_Equipment = require("../SixtySixItems.Domain.Equipment/index.js");
var SixtySixItems_Domain_EquipmentSlot = require("../SixtySixItems.Domain.EquipmentSlot/index.js");
var SixtySixItems_Domain_Inventory = require("../SixtySixItems.Domain.Inventory/index.js");
var SixtySixItems_Domain_InventorySlot = require("../SixtySixItems.Domain.InventorySlot/index.js");
var EquipmentSlot = (function () {
    function EquipmentSlot(value0) {
        this.value0 = value0;
    };
    EquipmentSlot.create = function (value0) {
        return new EquipmentSlot(value0);
    };
    return EquipmentSlot;
})();
var InventorySlot = (function () {
    function InventorySlot(value0) {
        this.value0 = value0;
    };
    InventorySlot.create = function (value0) {
        return new InventorySlot(value0);
    };
    return InventorySlot;
})();
var LootingBagSlot = (function () {
    function LootingBagSlot(value0) {
        this.value0 = value0;
    };
    LootingBagSlot.create = function (value0) {
        return new LootingBagSlot(value0);
    };
    return LootingBagSlot;
})();
var eqStorageSlot = {
    eq: function (x) {
        return function (y) {
            if (x instanceof EquipmentSlot && y instanceof EquipmentSlot) {
                return Data_Eq.eq(SixtySixItems_Domain_EquipmentSlot.eqEquipmentSlot)(x.value0)(y.value0);
            };
            if (x instanceof InventorySlot && y instanceof InventorySlot) {
                return Data_Eq.eq(SixtySixItems_Domain_InventorySlot.eqInventorySlot)(x.value0)(y.value0);
            };
            if (x instanceof LootingBagSlot && y instanceof LootingBagSlot) {
                return Data_Eq.eq(SixtySixItems_Domain_InventorySlot.eqInventorySlot)(x.value0)(y.value0);
            };
            return false;
        };
    }
};
var setItemStack = function (slot) {
    return function (stack) {
        return function (storage) {
            if (slot instanceof EquipmentSlot) {
                return {
                    equipment: SixtySixItems_Domain_Equipment.setItemStack(slot.value0)(stack)(storage.equipment),
                    inventory: storage.inventory,
                    lootingBag: storage.lootingBag
                };
            };
            if (slot instanceof InventorySlot) {
                return {
                    equipment: storage.equipment,
                    inventory: SixtySixItems_Domain_Inventory.setItemStack(slot.value0)(stack)(storage.inventory),
                    lootingBag: storage.lootingBag
                };
            };
            if (slot instanceof LootingBagSlot) {
                return {
                    equipment: storage.equipment,
                    inventory: storage.inventory,
                    lootingBag: SixtySixItems_Domain_Inventory.setItemStack(slot.value0)(stack)(storage.lootingBag)
                };
            };
            throw new Error("Failed pattern match at SixtySixItems.Domain.Storage (line 50, column 3 - line 56, column 87): " + [ slot.constructor.name ]);
        };
    };
};
var setItem = function (slot) {
    return function (itemId) {
        return function (storage) {
            if (slot instanceof EquipmentSlot) {
                return {
                    equipment: SixtySixItems_Domain_Equipment.setItem(slot.value0)(itemId)(storage.equipment),
                    inventory: storage.inventory,
                    lootingBag: storage.lootingBag
                };
            };
            if (slot instanceof InventorySlot) {
                return {
                    equipment: storage.equipment,
                    inventory: SixtySixItems_Domain_Inventory.setItem(slot.value0)(itemId)(storage.inventory),
                    lootingBag: storage.lootingBag
                };
            };
            if (slot instanceof LootingBagSlot) {
                return {
                    equipment: storage.equipment,
                    inventory: storage.inventory,
                    lootingBag: SixtySixItems_Domain_Inventory.setItem(slot.value0)(itemId)(storage.lootingBag)
                };
            };
            throw new Error("Failed pattern match at SixtySixItems.Domain.Storage (line 60, column 3 - line 66, column 83): " + [ slot.constructor.name ]);
        };
    };
};
var item = function (slot) {
    return function (storage) {
        if (slot instanceof EquipmentSlot) {
            return SixtySixItems_Domain_Equipment.item(slot.value0)(storage.equipment);
        };
        if (slot instanceof InventorySlot) {
            return SixtySixItems_Domain_Inventory.item(slot.value0)(storage.inventory);
        };
        if (slot instanceof LootingBagSlot) {
            return SixtySixItems_Domain_Inventory.item(slot.value0)(storage.lootingBag);
        };
        throw new Error("Failed pattern match at SixtySixItems.Domain.Storage (line 43, column 3 - line 46, column 70): " + [ slot.constructor.name ]);
    };
};
var empty = {
    equipment: SixtySixItems_Domain_Equipment.empty,
    inventory: SixtySixItems_Domain_Inventory.empty,
    lootingBag: SixtySixItems_Domain_Inventory.empty
};
module.exports = {
    EquipmentSlot: EquipmentSlot,
    InventorySlot: InventorySlot,
    LootingBagSlot: LootingBagSlot,
    empty: empty,
    item: item,
    setItemStack: setItemStack,
    setItem: setItem,
    eqStorageSlot: eqStorageSlot
};
