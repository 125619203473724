// Generated by purs version 0.14.5
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Data_Argonaut_Core = require("../Data.Argonaut.Core/index.js");
var Data_Argonaut_Decode_Decoders = require("../Data.Argonaut.Decode.Decoders/index.js");
var Data_Argonaut_Decode_Error = require("../Data.Argonaut.Decode.Error/index.js");
var Data_Bifunctor = require("../Data.Bifunctor/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Foreign_Object = require("../Foreign.Object/index.js");
var Record = require("../Record/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var gDecodeJsonNil = {
    gDecodeJson: function (v) {
        return function (v1) {
            return new Data_Either.Right({});
        };
    }
};
var gDecodeJson = function (dict) {
    return dict.gDecodeJson;
};
var decodeVoid = {
    decodeJson: Data_Argonaut_Decode_Decoders.decodeVoid
};
var decodeRecord = function (dictGDecodeJson) {
    return function (dictRowToList) {
        return {
            decodeJson: function (json) {
                var v = Data_Argonaut_Core.toObject(json);
                if (v instanceof Data_Maybe.Just) {
                    return gDecodeJson(dictGDecodeJson)(v.value0)(Type_Proxy["Proxy"].value);
                };
                if (v instanceof Data_Maybe.Nothing) {
                    return Data_Either.Left.create(new Data_Argonaut_Decode_Error.TypeMismatch("Object"));
                };
                throw new Error("Failed pattern match at Data.Argonaut.Decode.Class (line 103, column 5 - line 105, column 46): " + [ v.constructor.name ]);
            }
        };
    };
};
var decodeJsonString = {
    decodeJson: Data_Argonaut_Decode_Decoders.decodeString
};
var decodeJsonNumber = {
    decodeJson: Data_Argonaut_Decode_Decoders.decodeNumber
};
var decodeJsonNull = {
    decodeJson: Data_Argonaut_Decode_Decoders.decodeNull
};
var decodeJsonNonEmptyString = {
    decodeJson: Data_Argonaut_Decode_Decoders.decodeNonEmptyString
};
var decodeJsonJson = {
    decodeJson: Data_Either.Right.create
};
var decodeJsonInt = {
    decodeJson: Data_Argonaut_Decode_Decoders.decodeInt
};
var decodeJsonField = function (dict) {
    return dict.decodeJsonField;
};
var gDecodeJsonCons = function (dictDecodeJsonField) {
    return function (dictGDecodeJson) {
        return function (dictIsSymbol) {
            return function (dictCons) {
                return function (dictLacks) {
                    return {
                        gDecodeJson: function (object) {
                            return function (v) {
                                var fieldName = Data_Symbol.reflectSymbol(dictIsSymbol)(Type_Proxy["Proxy"].value);
                                var fieldValue = Foreign_Object.lookup(fieldName)(object);
                                var v1 = decodeJsonField(dictDecodeJsonField)(fieldValue);
                                if (v1 instanceof Data_Maybe.Just) {
                                    return Control_Bind.bind(Data_Either.bindEither)(Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(Data_Argonaut_Decode_Error.AtKey.create(fieldName))(v1.value0))(function (val) {
                                        return Control_Bind.bind(Data_Either.bindEither)(gDecodeJson(dictGDecodeJson)(object)(Type_Proxy["Proxy"].value))(function (rest) {
                                            return Data_Either.Right.create(Record.insert(dictIsSymbol)()()(Type_Proxy["Proxy"].value)(val)(rest));
                                        });
                                    });
                                };
                                if (v1 instanceof Data_Maybe.Nothing) {
                                    return Data_Either.Left.create(new Data_Argonaut_Decode_Error.AtKey(fieldName, Data_Argonaut_Decode_Error.MissingValue.value));
                                };
                                throw new Error("Failed pattern match at Data.Argonaut.Decode.Class (line 127, column 5 - line 134, column 44): " + [ v1.constructor.name ]);
                            };
                        }
                    };
                };
            };
        };
    };
};
var decodeJsonCodePoint = {
    decodeJson: Data_Argonaut_Decode_Decoders.decodeCodePoint
};
var decodeJsonBoolean = {
    decodeJson: Data_Argonaut_Decode_Decoders.decodeBoolean
};
var decodeJson = function (dict) {
    return dict.decodeJson;
};
var decodeJsonEither = function (dictDecodeJson) {
    return function (dictDecodeJson1) {
        return {
            decodeJson: Data_Argonaut_Decode_Decoders.decodeEither(decodeJson(dictDecodeJson))(decodeJson(dictDecodeJson1))
        };
    };
};
var decodeJsonMaybe = function (dictDecodeJson) {
    return {
        decodeJson: Data_Argonaut_Decode_Decoders.decodeMaybe(decodeJson(dictDecodeJson))
    };
};
var decodeJsonNonEmptyArray = function (dictDecodeJson) {
    return {
        decodeJson: Data_Argonaut_Decode_Decoders.decodeNonEmptyArray(decodeJson(dictDecodeJson))
    };
};
var decodeJsonNonEmptyList = function (dictDecodeJson) {
    return {
        decodeJson: Data_Argonaut_Decode_Decoders.decodeNonEmptyList(decodeJson(dictDecodeJson))
    };
};
var decodeJsonNonEmpty_Array = function (dictDecodeJson) {
    return {
        decodeJson: Data_Argonaut_Decode_Decoders.decodeNonEmpty_Array(decodeJson(dictDecodeJson))
    };
};
var decodeJsonNonEmpty_List = function (dictDecodeJson) {
    return {
        decodeJson: Data_Argonaut_Decode_Decoders.decodeNonEmpty_List(decodeJson(dictDecodeJson))
    };
};
var decodeJsonTuple = function (dictDecodeJson) {
    return function (dictDecodeJson1) {
        return {
            decodeJson: Data_Argonaut_Decode_Decoders.decodeTuple(decodeJson(dictDecodeJson))(decodeJson(dictDecodeJson1))
        };
    };
};
var decodeList = function (dictDecodeJson) {
    return {
        decodeJson: Data_Argonaut_Decode_Decoders.decodeList(decodeJson(dictDecodeJson))
    };
};
var decodeMap = function (dictOrd) {
    return function (dictDecodeJson) {
        return function (dictDecodeJson1) {
            return {
                decodeJson: Data_Argonaut_Decode_Decoders.decodeMap(dictOrd)(decodeJson(dictDecodeJson))(decodeJson(dictDecodeJson1))
            };
        };
    };
};
var decodeSet = function (dictOrd) {
    return function (dictDecodeJson) {
        return {
            decodeJson: Data_Argonaut_Decode_Decoders.decodeSet(dictOrd)(decodeJson(dictDecodeJson))
        };
    };
};
var decodeIdentity = function (dictDecodeJson) {
    return {
        decodeJson: Data_Argonaut_Decode_Decoders.decodeIdentity(decodeJson(dictDecodeJson))
    };
};
var decodeForeignObject = function (dictDecodeJson) {
    return {
        decodeJson: Data_Argonaut_Decode_Decoders.decodeForeignObject(decodeJson(dictDecodeJson))
    };
};
var decodeFieldMaybe = function (dictDecodeJson) {
    return {
        decodeJsonField: function (v) {
            if (v instanceof Data_Maybe.Nothing) {
                return Data_Maybe.Just.create(new Data_Either.Right(Data_Maybe.Nothing.value));
            };
            if (v instanceof Data_Maybe.Just) {
                return Data_Maybe.Just.create(decodeJson(decodeJsonMaybe(dictDecodeJson))(v.value0));
            };
            throw new Error("Failed pattern match at Data.Argonaut.Decode.Class (line 139, column 1 - line 143, column 49): " + [ v.constructor.name ]);
        }
    };
};
var decodeFieldId = function (dictDecodeJson) {
    return {
        decodeJsonField: function (j) {
            return Data_Functor.map(Data_Maybe.functorMaybe)(decodeJson(dictDecodeJson))(j);
        }
    };
};
var decodeArray = function (dictDecodeJson) {
    return {
        decodeJson: Data_Argonaut_Decode_Decoders.decodeArray(decodeJson(dictDecodeJson))
    };
};
module.exports = {
    decodeJson: decodeJson,
    decodeJsonField: decodeJsonField,
    gDecodeJson: gDecodeJson,
    decodeIdentity: decodeIdentity,
    decodeJsonMaybe: decodeJsonMaybe,
    decodeJsonTuple: decodeJsonTuple,
    decodeJsonEither: decodeJsonEither,
    decodeJsonNull: decodeJsonNull,
    decodeJsonBoolean: decodeJsonBoolean,
    decodeJsonNumber: decodeJsonNumber,
    decodeJsonInt: decodeJsonInt,
    decodeJsonString: decodeJsonString,
    decodeJsonNonEmptyString: decodeJsonNonEmptyString,
    decodeJsonJson: decodeJsonJson,
    decodeJsonNonEmpty_Array: decodeJsonNonEmpty_Array,
    decodeJsonNonEmptyArray: decodeJsonNonEmptyArray,
    decodeJsonNonEmpty_List: decodeJsonNonEmpty_List,
    decodeJsonNonEmptyList: decodeJsonNonEmptyList,
    decodeJsonCodePoint: decodeJsonCodePoint,
    decodeForeignObject: decodeForeignObject,
    decodeArray: decodeArray,
    decodeList: decodeList,
    decodeSet: decodeSet,
    decodeMap: decodeMap,
    decodeVoid: decodeVoid,
    decodeRecord: decodeRecord,
    gDecodeJsonNil: gDecodeJsonNil,
    gDecodeJsonCons: gDecodeJsonCons,
    decodeFieldMaybe: decodeFieldMaybe,
    decodeFieldId: decodeFieldId
};
