// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Enum = require("../Data.Enum/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Partial_Unsafe = require("../Partial.Unsafe/index.js");
var unsafeFromRight = Data_Either.either(function (v) {
    return Partial_Unsafe.unsafeCrashWith("This should never happen! If you see this message, please file a bug report in the `purescript-b64` issue tracker.");
})(Control_Category.identity(Control_Category.categoryFn));
var uint8ArrayToBtoaSafeString = function (u8) {
    return $foreign.uint8ArrayToBtoaSafeStringImpl(u8);
};
var toUrlSafe = (function () {
    var $1 = Data_String_Common.replaceAll("=")("");
    var $2 = Data_String_Common.replaceAll("/")("_");
    var $3 = Data_String_Common.replaceAll("+")("-");
    return function ($4) {
        return $1($2($3($4)));
    };
})();
var toRfc4648 = (function () {
    var $5 = Data_String_Common.replaceAll("-")("+");
    var $6 = Data_String_Common.replaceAll("_")("/");
    return function ($7) {
        return $5($6($7));
    };
})();
var asUint8Array = function (arr) {
    return $foreign.asUint8ArrayImpl(arr);
};
var unsafeStringToUint8ArrayOfCharCodes = (function () {
    var $8 = Data_Functor.map(Data_Functor.functorArray)(Data_Enum.fromEnum(Data_String_CodePoints.boundedEnumCodePoint));
    return function ($9) {
        return asUint8Array($8(Data_String_CodePoints.toCodePointArray($9)));
    };
})();
module.exports = {
    uint8ArrayToBtoaSafeString: uint8ArrayToBtoaSafeString,
    unsafeFromRight: unsafeFromRight,
    unsafeStringToUint8ArrayOfCharCodes: unsafeStringToUint8ArrayOfCharCodes,
    toUrlSafe: toUrlSafe,
    toRfc4648: toRfc4648,
    atobIsDefined: $foreign.atobIsDefined,
    btoaIsDefined: $foreign.btoaIsDefined
};
