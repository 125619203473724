// Generated by purs version 0.14.5
"use strict";
var Control_Category = require("../Control.Category/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Monoid_Dual = require("../Data.Monoid.Dual/index.js");
var Data_Monoid_Multiplicative = require("../Data.Monoid.Multiplicative/index.js");
var Data_Semigroup_Foldable = require("../Data.Semigroup.Foldable/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var traverse1 = function (dict) {
    return dict.traverse1;
};
var traversableTuple = {
    traverse1: function (dictApply) {
        return function (f) {
            return function (v) {
                return Data_Functor.map(dictApply.Functor0())(Data_Tuple.Tuple.create(v.value0))(f(v.value1));
            };
        };
    },
    sequence1: function (dictApply) {
        return function (v) {
            return Data_Functor.map(dictApply.Functor0())(Data_Tuple.Tuple.create(v.value0))(v.value1);
        };
    },
    Foldable10: function () {
        return Data_Semigroup_Foldable.foldableTuple;
    },
    Traversable1: function () {
        return Data_Traversable.traversableTuple;
    }
};
var traversableIdentity = {
    traverse1: function (dictApply) {
        return function (f) {
            return function (v) {
                return Data_Functor.map(dictApply.Functor0())(Data_Identity.Identity)(f(v));
            };
        };
    },
    sequence1: function (dictApply) {
        return function (v) {
            return Data_Functor.map(dictApply.Functor0())(Data_Identity.Identity)(v);
        };
    },
    Foldable10: function () {
        return Data_Semigroup_Foldable.foldableIdentity;
    },
    Traversable1: function () {
        return Data_Traversable.traversableIdentity;
    }
};
var sequence1Default = function (dictTraversable1) {
    return function (dictApply) {
        return traverse1(dictTraversable1)(dictApply)(Control_Category.identity(Control_Category.categoryFn));
    };
};
var traversableDual = {
    traverse1: function (dictApply) {
        return function (f) {
            return function (v) {
                return Data_Functor.map(dictApply.Functor0())(Data_Monoid_Dual.Dual)(f(v));
            };
        };
    },
    sequence1: function (dictApply) {
        return sequence1Default(traversableDual)(dictApply);
    },
    Foldable10: function () {
        return Data_Semigroup_Foldable.foldableDual;
    },
    Traversable1: function () {
        return Data_Traversable.traversableDual;
    }
};
var traversableMultiplicative = {
    traverse1: function (dictApply) {
        return function (f) {
            return function (v) {
                return Data_Functor.map(dictApply.Functor0())(Data_Monoid_Multiplicative.Multiplicative)(f(v));
            };
        };
    },
    sequence1: function (dictApply) {
        return sequence1Default(traversableMultiplicative)(dictApply);
    },
    Foldable10: function () {
        return Data_Semigroup_Foldable.foldableMultiplicative;
    },
    Traversable1: function () {
        return Data_Traversable.traversableMultiplicative;
    }
};
var sequence1 = function (dict) {
    return dict.sequence1;
};
var traverse1Default = function (dictTraversable1) {
    return function (dictApply) {
        return function (f) {
            return function (ta) {
                return sequence1(dictTraversable1)(dictApply)(Data_Functor.map((dictTraversable1.Traversable1()).Functor0())(f)(ta));
            };
        };
    };
};
module.exports = {
    sequence1: sequence1,
    traverse1: traverse1,
    traverse1Default: traverse1Default,
    sequence1Default: sequence1Default,
    traversableDual: traversableDual,
    traversableMultiplicative: traversableMultiplicative,
    traversableTuple: traversableTuple,
    traversableIdentity: traversableIdentity
};
