// Generated by purs version 0.14.5
"use strict";
var Data_Maybe = require("../Data.Maybe/index.js");
var JSURI = require("../JSURI/index.js");
var Routing_Match = require("../Routing.Match/index.js");
var Routing_Parser = require("../Routing.Parser/index.js");
var matchWith = function (decoder) {
    return function (matcher) {
        var $1 = Routing_Match.runMatch(matcher);
        var $2 = Routing_Parser.parse(decoder);
        return function ($3) {
            return $1($2($3));
        };
    };
};
var match = matchWith((function () {
    var $4 = Data_Maybe.fromJust();
    return function ($5) {
        return $4(JSURI["decodeURIComponent"]($5));
    };
})());
module.exports = {
    match: match,
    matchWith: matchWith
};
