// Generated by purs version 0.14.5
"use strict";
var Data_Ring = require("../Data.Ring/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var commutativeRingUnit = {
    Ring0: function () {
        return Data_Ring.ringUnit;
    }
};
var commutativeRingRecordNil = {
    RingRecord0: function () {
        return Data_Ring.ringRecordNil;
    }
};
var commutativeRingRecordCons = function (dictIsSymbol) {
    return function (dictCons) {
        return function (dictCommutativeRingRecord) {
            return function (dictCommutativeRing) {
                return {
                    RingRecord0: function () {
                        return Data_Ring.ringRecordCons(dictIsSymbol)()(dictCommutativeRingRecord.RingRecord0())(dictCommutativeRing.Ring0());
                    }
                };
            };
        };
    };
};
var commutativeRingRecord = function (dictRowToList) {
    return function (dictCommutativeRingRecord) {
        return {
            Ring0: function () {
                return Data_Ring.ringRecord()(dictCommutativeRingRecord.RingRecord0());
            }
        };
    };
};
var commutativeRingProxy3 = {
    Ring0: function () {
        return Data_Ring.ringProxy3;
    }
};
var commutativeRingProxy2 = {
    Ring0: function () {
        return Data_Ring.ringProxy2;
    }
};
var commutativeRingProxy = {
    Ring0: function () {
        return Data_Ring.ringProxy;
    }
};
var commutativeRingNumber = {
    Ring0: function () {
        return Data_Ring.ringNumber;
    }
};
var commutativeRingInt = {
    Ring0: function () {
        return Data_Ring.ringInt;
    }
};
var commutativeRingFn = function (dictCommutativeRing) {
    return {
        Ring0: function () {
            return Data_Ring.ringFn(dictCommutativeRing.Ring0());
        }
    };
};
module.exports = {
    commutativeRingInt: commutativeRingInt,
    commutativeRingNumber: commutativeRingNumber,
    commutativeRingUnit: commutativeRingUnit,
    commutativeRingFn: commutativeRingFn,
    commutativeRingRecord: commutativeRingRecord,
    commutativeRingProxy: commutativeRingProxy,
    commutativeRingProxy2: commutativeRingProxy2,
    commutativeRingProxy3: commutativeRingProxy3,
    commutativeRingRecordNil: commutativeRingRecordNil,
    commutativeRingRecordCons: commutativeRingRecordCons,
    add: Data_Semiring.add,
    mul: Data_Semiring.mul,
    one: Data_Semiring.one,
    zero: Data_Semiring.zero
};
