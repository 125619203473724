// Generated by purs version 0.14.5
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Plus = require("../Control.Plus/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var guard = function (dictAlternative) {
    return function (v) {
        if (v) {
            return Control_Applicative.pure(dictAlternative.Applicative0())(Data_Unit.unit);
        };
        if (!v) {
            return Control_Plus.empty(dictAlternative.Plus1());
        };
        throw new Error("Failed pattern match at Control.Alternative (line 48, column 1 - line 48, column 54): " + [ v.constructor.name ]);
    };
};
var alternativeArray = {
    Applicative0: function () {
        return Control_Applicative.applicativeArray;
    },
    Plus1: function () {
        return Control_Plus.plusArray;
    }
};
module.exports = {
    guard: guard,
    alternativeArray: alternativeArray,
    alt: Control_Alt.alt,
    liftA1: Control_Applicative.liftA1,
    pure: Control_Applicative.pure,
    unless: Control_Applicative.unless,
    when: Control_Applicative.when,
    apply: Control_Apply.apply,
    empty: Control_Plus.empty,
    map: Data_Functor.map,
    "void": Data_Functor["void"]
};
