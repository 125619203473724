// Generated by purs version 0.14.5
"use strict";
var unright = function (dict) {
    return dict.unright;
};
var unleft = function (dict) {
    return dict.unleft;
};
module.exports = {
    unleft: unleft,
    unright: unright
};
