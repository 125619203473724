// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Argonaut_Core = require("../Data.Argonaut.Core/index.js");
var Data_Argonaut_Decode_Class = require("../Data.Argonaut.Decode.Class/index.js");
var Data_Argonaut_Decode_Error = require("../Data.Argonaut.Decode.Error/index.js");
var Data_Argonaut_Decode_Parser = require("../Data.Argonaut.Decode.Parser/index.js");
var Data_Argonaut_Encode_Class = require("../Data.Argonaut.Encode.Class/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Bifunctor = require("../Data.Bifunctor/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Enum = require("../Data.Enum/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Base64 = require("../Data.String.Base64/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_TraversableWithIndex = require("../Data.TraversableWithIndex/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var Routing_Hash = require("../Routing.Hash/index.js");
var SixtySixItems_Domain_Equipment = require("../SixtySixItems.Domain.Equipment/index.js");
var SixtySixItems_Domain_EquipmentSlot = require("../SixtySixItems.Domain.EquipmentSlot/index.js");
var SixtySixItems_Domain_Inventory = require("../SixtySixItems.Domain.Inventory/index.js");
var SixtySixItems_Domain_InventorySlot = require("../SixtySixItems.Domain.InventorySlot/index.js");
var encode = function (v) {
    var encodeInventory = (function () {
        var $44 = Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v1) {
            return new Data_Tuple.Tuple(Data_Newtype.unwrap()(v1.item), v1.quantity);
        }));
        var $45 = Data_Functor.map(Data_Functor.functorArray)(function (v1) {
            return v1.stack;
        });
        return function ($46) {
            return $44($45(SixtySixItems_Domain_Inventory.toArray($46)));
        };
    })();
    var encodeEquipment = (function () {
        var $47 = Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v1) {
            return new Data_Tuple.Tuple(Data_Newtype.unwrap()(v1.item), v1.quantity);
        }));
        var $48 = Data_Functor.map(Data_Functor.functorArray)(function (v1) {
            return v1.stack;
        });
        return function ($49) {
            return $47($48(SixtySixItems_Domain_Equipment.toArray($49)));
        };
    })();
    var json = {
        v: 1,
        e: encodeEquipment(v.equipment),
        i: encodeInventory(v.inventory),
        l: encodeInventory(v.lootingBag)
    };
    return Data_String_Base64.encodeUrl(Data_Argonaut_Core.stringify(Data_Argonaut_Encode_Class.encodeJson(Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonMaybe(Data_Argonaut_Encode_Class.encodeJsonTuple(Data_Argonaut_Encode_Class.encodeJsonInt)(Data_Argonaut_Encode_Class.encodeJsonInt))))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonMaybe(Data_Argonaut_Encode_Class.encodeJsonTuple(Data_Argonaut_Encode_Class.encodeJsonInt)(Data_Argonaut_Encode_Class.encodeJsonInt))))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonMaybe(Data_Argonaut_Encode_Class.encodeJsonTuple(Data_Argonaut_Encode_Class.encodeJsonInt)(Data_Argonaut_Encode_Class.encodeJsonInt))))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonInt)(Data_Argonaut_Encode_Class.gEncodeJsonNil)({
        reflectSymbol: function () {
            return "v";
        }
    })())({
        reflectSymbol: function () {
            return "l";
        }
    })())({
        reflectSymbol: function () {
            return "i";
        }
    })())({
        reflectSymbol: function () {
            return "e";
        }
    })())())(json)));
};
var write = function ($50) {
    return Routing_Hash.setHash(encode($50));
};
var decode = function (hash) {
    var decodeInventory = function (items) {
        return Control_Bind.bind(Data_Either.bindEither)(Data_TraversableWithIndex.traverseWithIndex(Data_TraversableWithIndex.traversableWithIndexArray)(Data_Either.applicativeEither)(function (index) {
            return function (stack) {
                return Control_Bind.bind(Data_Either.bindEither)(Data_Either.note("\"" + (Data_Show.show(Data_Show.showInt)(index) + "\" is not a valid slot."))(Data_Enum.toEnum(SixtySixItems_Domain_InventorySlot.boundedEnumInventorySlot)(index)))(function (slot) {
                    return Control_Applicative.pure(Data_Either.applicativeEither)({
                        slot: slot,
                        stack: stack
                    });
                });
            };
        })(Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
            return {
                item: v.value0,
                quantity: v.value1
            };
        }))(items)))(function (slotAndStackPairs) {
            return Control_Applicative.pure(Data_Either.applicativeEither)(Data_Foldable.foldl(Data_Foldable.foldableArray)(function (acc) {
                return function (v) {
                    return SixtySixItems_Domain_Inventory.setItemStack(v.slot)(v.stack)(acc);
                };
            })(SixtySixItems_Domain_Inventory.empty)(Data_Array.mapMaybe(function (v) {
                return Data_Functor.map(Data_Maybe.functorMaybe)(function (stack$prime) {
                    return {
                        slot: v.slot,
                        stack: stack$prime
                    };
                })(v.stack);
            })(slotAndStackPairs)));
        });
    };
    var decodeEquipment = function (items) {
        return Control_Bind.bind(Data_Either.bindEither)(Data_TraversableWithIndex.traverseWithIndex(Data_TraversableWithIndex.traversableWithIndexArray)(Data_Either.applicativeEither)(function (index) {
            return function (stack) {
                return Control_Bind.bind(Data_Either.bindEither)(Data_Either.note("\"" + (Data_Show.show(Data_Show.showInt)(index) + "\" is not a valid slot."))(Data_Enum.toEnum(SixtySixItems_Domain_EquipmentSlot.boundedEnumEquipmentSlot)(index)))(function (slot) {
                    return Control_Applicative.pure(Data_Either.applicativeEither)({
                        slot: slot,
                        stack: stack
                    });
                });
            };
        })(Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
            return {
                item: v.value0,
                quantity: v.value1
            };
        }))(items)))(function (slotAndStackPairs) {
            return Control_Applicative.pure(Data_Either.applicativeEither)(Data_Foldable.foldl(Data_Foldable.foldableArray)(function (acc) {
                return function (v) {
                    return SixtySixItems_Domain_Equipment.setItemStack(v.slot)(v.stack)(acc);
                };
            })(SixtySixItems_Domain_Equipment.empty)(Data_Array.mapMaybe(function (v) {
                return Data_Functor.map(Data_Maybe.functorMaybe)(function (stack$prime) {
                    return {
                        slot: v.slot,
                        stack: stack$prime
                    };
                })(v.stack);
            })(slotAndStackPairs)));
        });
    };
    return Control_Bind.bind(Data_Either.bindEither)(Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(Effect_Exception.message)(Data_String_Base64.decode(hash)))(function (jsonString) {
        return Control_Bind.bind(Data_Either.bindEither)(Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(Data_Argonaut_Decode_Error.printJsonDecodeError)(Data_Argonaut_Decode_Parser.parseJson(jsonString)))(function (rawJson) {
            return Control_Bind.bind(Data_Either.bindEither)(Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(Data_Argonaut_Decode_Error.printJsonDecodeError)(Data_Argonaut_Decode_Class.decodeJson(Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeArray(Data_Argonaut_Decode_Class.decodeJsonMaybe(Data_Argonaut_Decode_Class.decodeJsonTuple(Data_Argonaut_Decode_Class.decodeJsonInt)(Data_Argonaut_Decode_Class.decodeJsonInt)))))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeArray(Data_Argonaut_Decode_Class.decodeJsonMaybe(Data_Argonaut_Decode_Class.decodeJsonTuple(Data_Argonaut_Decode_Class.decodeJsonInt)(Data_Argonaut_Decode_Class.decodeJsonInt)))))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeArray(Data_Argonaut_Decode_Class.decodeJsonMaybe(Data_Argonaut_Decode_Class.decodeJsonTuple(Data_Argonaut_Decode_Class.decodeJsonInt)(Data_Argonaut_Decode_Class.decodeJsonInt)))))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonInt))(Data_Argonaut_Decode_Class.gDecodeJsonNil)({
                reflectSymbol: function () {
                    return "v";
                }
            })()())({
                reflectSymbol: function () {
                    return "l";
                }
            })()())({
                reflectSymbol: function () {
                    return "i";
                }
            })()())({
                reflectSymbol: function () {
                    return "e";
                }
            })()())())(rawJson)))(function (json) {
                return Control_Bind.bind(Data_Either.bindEither)(decodeEquipment(json.e))(function (equipment) {
                    return Control_Bind.bind(Data_Either.bindEither)(decodeInventory(json.i))(function (inventory) {
                        return Control_Bind.bind(Data_Either.bindEither)(decodeInventory(json.l))(function (lootingBag) {
                            return Control_Applicative.pure(Data_Either.applicativeEither)({
                                equipment: equipment,
                                inventory: inventory,
                                lootingBag: lootingBag
                            });
                        });
                    });
                });
            });
        });
    });
};
var read = function __do() {
    var hash = Routing_Hash.getHash();
    var $43 = Data_String_CodePoints.length(hash) > 0;
    if ($43) {
        return Data_Functor.map(Data_Either.functorEither)(Data_Maybe.Just.create)(decode(hash));
    };
    return Control_Applicative.pure(Data_Either.applicativeEither)(Data_Maybe.Nothing.value);
};
module.exports = {
    encode: encode,
    decode: decode,
    read: read,
    write: write
};
